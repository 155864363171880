import { Observable } from "rxjs";
import { ApiResponse, PaginablePayloadApiResponse } from "./types.domain";
import { IndividualDirector } from "./component/director-component.domain";
import { Address2ComponentValue } from "./component/address2-component.domain";
import { Application, GetApplicationOwnerFn } from "./application-service.domain";
import { GetUserFn } from "./auth-service.domain";

export type CreditOverviewSelection = 'entity' | 'director' | 'guarantor' | 'summary' | 'result';

export const CreditWebServiceOptions = [{
    name: 'CreditorWatch',
    id: 1
  }, {
    name: 'EquifaxConsumer',
    id: 2
  },{
    name: 'EquifaxFraudCheck',
    id: 3
  }]

export interface CreditFlow {
    id: number;
    name: string;
    startNode: number;
    isDeleted: boolean;
    lastUpdatedBy: number;
    lastUpdatedTime: string;
    AssetFinance: boolean;
    BusinessLoans: boolean;
    BusinessOverdraft: boolean;
    InsurancePremium: boolean;
    CorporateLoans: boolean;
    Consumer: boolean;
    GivenName?: string;
    FamilyName?: string;
    published: boolean;
}

export interface UpdateCreditFlowBody extends Partial<CreditFlow> {
    force?: boolean;
}
// use dfs/bfs algorithm to display the graph

export interface CreditFlowNode {
    id: number;
    creditFlowId: number; // foreign key
    name: string;
    isDeleted: boolean;
    lastUpdatedBy: number;
    lastUpdatedTime: string;
    type: CreditFlowNodeType,
    webService: WebServiceType | null; // applied only if this is a web service node
    nextNode: number | null; // applied only if this is a web service node
    webServiceLevel: CreditRuleMappingLevel | null;
    conditionVariable: number | null;
}

export interface CreditRuleMapping {
    id: number;
    creditFlowNodeId: number; // foreign key
    name: string;
    isDeleted: boolean;
    lastUpdatedBy: number;
    lastUpdatedTime: string;
    level: CreditRuleMappingLevel;
    creditRule: string; // rule formula
    alertId: number; //CreditAlert;
    conditionVariable: number | null;
    action: CreditRuleMappingAction | null;
    //outcome: string;
    //isEnd: boolean;
    nextNode: number | null; // router; null means that's the end of the route
    FamilyName?: string;
    GivenName?: string;
    // queue/status??
}

export type CreditRuleMappingLevel = 'Individual' | 'Entity' | 'Application';

export interface CreditRuleMappingBody {
    name: string;
    creditFlowNodeId: number;
    level: CreditRuleMappingLevel;
    creditRule: string;
    alertId: number;
    nextNode: number | null;
    conditionVariable: number | null;
    action: CreditRuleMappingAction | null;
}

export interface CreditFlowNodeBody {
    name: string;
    type: CreditFlowNodeType,
    // isStart: boolean;
    webService: WebServiceType | null; // applied only if this is a web service node
    creditFlowId: number;
    nextNode: number | null;
    webServiceLevel: CreditRuleMappingLevel | null;
    conditionVariable: number | null;
}

export type CreditFlowNodeType = 'web service' | 'rule set' | 'route' | 'end';
export type CreditAlertStatus = 'Decline' | 'Refer' | 'Validate';

export const CreditAlertOptions = ['Decline', 'Refer', 'Validate'];

export interface CreditAlert {
    id: number;
    name: string;
    isDeleted: boolean;
    lastUpdatedBy: number;
    lastUpdatedTime: string;
    status: CreditAlertStatus;
    description: string;
    FamilyName?: string;
    GivenName?: string;
    code: string;
}

// export interface CreditRule {
//     // id: number;
//     name: string;
//     // isDeleted: boolean;
//     // lastUpdatedBy: number;
//     // lastUpdatedTime: string;
//     formula: string; // TBC
// }

export type DuplicatedApplication = Application & {
    duplicatedReason: string;
}

export interface ApplicationCreditFlow {
    id: number;
    applicationId: number;
    creditFlowId: number;
    flow: ApplicationCreditFlowNodeDetail[];
    status: string;
    lastUpdatedTime: string;
    completeFlow: boolean;
    creditFlowNodeName?: string;
}

export interface ApplicationCreditFlowNodeDetail {
    creditFlowId: number;
    creditFlowNode: number;
    creditFlowNodeName: string;
    calculation: string; // audit the calculation history
    ruleMappingId: number | null;
    ruleMappingName: string;
    alertId: number | null;
    alert: string;
    alertCode: string;
    alertStatus: CreditAlertStatus | null;
    abn: string | null;
    acn: string | null;
    individualId: string | null;
}

export interface ApplicationCreditFlowNodeDetailTableData extends ApplicationCreditFlowNodeDetail {
    lastUpdatedTime: string;
    runId: number;
}

export interface ApplicationCreditFlowBody {
    applicationId: number;
    creditFlowId: number;
    flow: string;
    status: string;
    completeFlow: boolean;
}

export interface NewCreditFlowBody {
    name: string;
    AssetFinance?: boolean;
    BusinessLoans?: boolean;
    BusinessOverdraft?: boolean;
    InsurancePremium?: boolean;
    CorporateLoans?: boolean;
    Consumer?: boolean;
}

export type AddCreditFlowFn = (data: NewCreditFlowBody) => Observable<number>;
export type GetCreditFlowsFn = () => Observable<CreditFlow[]>;
export type UpdateCreditFlowFn = (data: Partial<CreditFlow>, id: number) => Observable<ApiResponse>;
export type CopyCreditFlowFn = (data: CopyCreditFlowBody) => Observable<ApiResponse>;
export type GetCreditWebServicesFn = (applicationId: number) => Observable<ApplicationWebService[]>;
export type GetApplicationCreditFlowResultFn = (applicationId: number) => Observable<ApplicationCreditFlow[]>;
export type GetWebServiceReportFn = (webServiceId: number) => Observable<GetWebServiceReportResponse>;
export type GetDuplicatedApplicationsFn = (applicationId: number) => Observable<DuplicatedApplication[]>;

export type GetWebServiceReportResponse = GetWebServiceReportSuccessResponse | null;

export interface GetWebServiceReportSuccessResponse {
    webService: ApplicationWebService,
    data: string | null
}

export interface EditCreditFlowDialogData {
    name: string;
}

export interface EditCreditFlowDialogResult {
    name: string;
    readyForSubmission: boolean;
}
export interface PublishCreditFlowConfirmationDialogData {
    conflictedFlows: ConflictedFlowDetail[]
}

export interface PublishCreditFlowConfirmationDialogResult {
    force: boolean;
}

export interface EditCreditRuleMappingDialogData {
    creditRuleMapping: CreditRuleMapping | null;
    creditFlowNodeId: number;
    nextNodeList: CreditFlowNode[];
    creditAlerts: CreditAlert[];
    nodeType: CreditFlowNodeType;
    getCreditRuleItemsFn: GetCreditRuleItemsFn;
}

export interface EditCreditRuleMappingDialogResult {
    readyForSubmission: boolean;
    creditRuleMapping: CreditRuleMappingBody | null;
}

export interface EditCreditFlowNodeDialogData {
    creditFlowNode: CreditFlowNode | null;
    creditFlowId: number;
    nextNodeList: CreditFlowNode[];
    getCreditRuleItemsFn: GetCreditRuleItemsFn;
}

export interface EditCreditFlowNodeDialogResult {
    readyForSubmission: boolean;
    creditFlowNode: CreditFlowNodeBody | null;
}

export interface CreditFlowDetails extends CreditFlow {
    nodes: CreditFlowNodeDetails[];
}

export interface CreditFlowNodeDetails extends CreditFlowNode {
    creditRuleMappings: CreditRuleMapping[];
}

export interface CreditFlowNodeWithLevel extends CreditFlowNodeDetails {
    level: number;
}

export interface NewCreditAlertBody {
    name: string;
    description: string;
    status: CreditAlertStatus;
    code: string;
}

export type RunCreditFlowFn = (applicationId: number, startFromLastRun: boolean) => Observable<ApiResponse>;

export type AddCreditAlertFn = (data: NewCreditAlertBody) => Observable<number>;
export type GetCreditAlertsFn = () => Observable<CreditAlert[]>;
export type UpdateCreditAlertFn = (data: Partial<CreditAlert>, id: number) => Observable<ApiResponse>;

export interface EditCreditAlertDialogData {
    name: string;
    description: string;
    code: string;
    status: CreditAlertStatus | null;
    getCreditAlertByStatusFn: GetCreditAlertByStatusFn;
}

export interface EditCreditAlertDialogResult {
    name: string;
    description: string;
    code: string;
    status: CreditAlertStatus;
    readyForSubmission: boolean;
}

export interface CopyCreditFlowBody {
    flowId: number;
}

export interface DeleteWebServicesBody {
    webServiceIds: number[];
}

export type DeleteWebServicesFn = (webServiceIds: number[]) => Observable<ApiResponse>

export type CreditRuleComponentValue = string | null;

export type CreditRuleOperator = "(" | ")" | "!=" | "==" | ">=" | "<=" | ">" | "<" | "+" | "-" | "*" | "/" | "DOT" |"IN" | "NOT IN" | "AND" | "OR";

export interface CreditRuleObject {
    type: 'variable' | 'operator' | 'value',
    value: string | number | boolean | (string | number | boolean)[];
}

export type CreditRuleItemType = 'Database' | 'JSON' | 'Formula' | 'Inteflow Database';

export type CreditRuleItemFunction = 
    'None' |  
    'Count' | 
    'First' | 
    'Last' |  
    'Age' |
    'Number' |  
    'Round' |  
    'Round up' |  
    'Round down' |  
    'Sum' |  
    'Maximum' |  
    'Minimum' |  
    'Average' | 
    'DB Value' |
    'Contains Deregistered';
// json only
export type CreditRuleItemLevel = 'Application' | 'Entity' | 'Individual';

export type CreditRuleMappingAction = 'Send Bank Statements Request';


export type CreditRuleItemOutputType = 'Date' | 'Date Time' | 'Int' | 'Float' | 'Text' | 'Boolean' | 'Array' | 'Object';

export const CreditRuleItemTypeOptions = ['JSON', 'Formula', 'Database', 'Inteflow Database'];
export const CreditRuleItemFunctionOptions = [
    'None',  
    'Count', 
    'First', 
    'Last', 
    'Age',
    'Number', 
    'Round', 
    'Round up', 
    'Round down', 
    'Sum', 
    'Maximum',
    'Minimum',
    'Average',
    'DB Value',
    'Contains Deregistered',
];
export const CreditRuleItemLevelOptions = ['Application', 'Entity', 'Individual'];
export const CreditRuleItemOutputTypeOptions = ['Date', 'Date Time', 'Int', 'Float', 'Text', 'Boolean', 'Array', 'Object'];
export const CreditRuleMappingActionOptions = ['Send Bank Statements Request'];
export interface CreditRuleItem extends NewCreditRuleItemBody {
    id: number;
    isDeleted: boolean;
    lastUpdatedBy: number;
    lastUpdatedTime: string;
}

export interface NewCreditRuleItemBody {
    name: string;
    description: string;
    type: CreditRuleItemType;
    useDefaultValue: boolean;
    defaultValue: string;
    itemFunction: CreditRuleItemFunction;
    level: CreditRuleItemLevel;
    outputType: CreditRuleItemOutputType;
    value: string;
}

export interface EditCreditRuleItemDialogData {
    creditRuleItem: CreditRuleItem | null;
    getCreditRuleItemsFn: GetCreditRuleItemsFn;
}

export interface EditCreditRuleItemDialogResult {
    readyForSubmission: boolean;
    creditRuleItem: NewCreditRuleItemBody | null;
}
export interface ConflictedFlowDetail { conflictingId: number; conflictingFlowName: string; product: (keyof CreditFlow)[]; };

export interface PublishCreditFlowBody {
    force: boolean;
}
export type AddCreditRuleItemFn = (data: NewCreditRuleItemBody) => Observable<number>;
export type GetCreditRuleItemsFn = () => Observable<CreditRuleItem[]>;
export type UpdateCreditRuleItemFn = (data: Partial<CreditRuleItem>, id: number) => Observable<ApiResponse>;
export type PublishCreditFlowFn = (data: Partial<PublishCreditFlowBody>, id: number) => Observable<ApiResponse>;
export type DownloadCreditorWatchReportFn = (pdfId: number) => Observable<any>;
export type UpdateCreditStatusFn = (data: UpdateCreditStatusBody) => Observable<ApiResponse>;
export type GetCreditAuditLogFn = (applicationId: number, opts: {
    page: { limit: number, offset: number },
    filter: string,
  }) => Observable<PaginablePayloadApiResponse<CreditAuditLog>>;
export type GetCreditAlertByStatusFn = (status: CreditAlertStatus) => Observable<number>;


export interface CreditRuleItemSelections {
    [propertyName: string]: CreditRuleItem[]
}

export type WebServiceType = 'CreditorWatch' | 'EquifaxConsumer' | 'EquifaxFraudCheck';

export interface ApplicationWebService {
    id: number,
    applicationId: number,
    level: 'entity' | 'individual',
    ABN: string | null,
    ACN: string | null,
    individualId: string | null,
    type: WebServiceType,
    json: Object,
    lastUpdatedTime: string,
    isDeleted: boolean,
}

export interface NewApplicationWebService {
    applicationId: number,
    level: 'entity' | 'individual',
    ABN: string | null,
    ACN: string | null,
    individualId: string | null,
    type: WebServiceType,
    json: string,
}

export interface CreditorWatchLoginResponse {
    token: string;
    refresh_token: string;
}

export interface SelectWebServicesDialogData {
    webServices: ApplicationWebService[];
    application: Application;
}

export interface SelectWebServicesDialogResult {
    webServiceIds: number[];
}

export interface CreditDecisioningReasonDialogData {
    status: CreditOfficerStatus;
}

export interface CreditDecisioningReasonDialogResult {
    readyForSubmission: boolean;
    reasonSelection: string;
    reason: string;
}

export interface CreditApprovedDialogData {
    application: Application;
}

export interface CreditApprovedDialogResult {
    creditApprovalDate: string,
    exceptionToPolicy: 'Yes' | 'No',
    creditReference: CreditReference,
    dealStatus: DealStatus,
    financials: 'Yes' | 'No',
    valuationSource: ValuationSource,
    approvalConditions: string,
    lvr: number,
}

export const creditDeclineApprovalReasonOptions: string[] = [
    "Adverse Credit – Borrower, Guarantor, Related",
    "Character",
    "Credit Score Below Minimal",
    "Criminal activity",
    "Equipment Restricted",
    "Fraudulent",
    "Highly Geared",
    "Missing Information",
    "No Property Ownership",
    "Poor Cash flow",
    "Restricted Industry",
    "Serviceability",
    "System",
    "Trading Time Below Minimal	"
]

export const creditDeclineReasonOptions: string[] = [
    "Adverse Credit – Borrower, Guarantor, Related",
    "Character",
    "Credit Score Below Minimal",
    "Criminal activity",
    "Equipment Restricted",
    "Fraudulent",
    "Highly Geared",
    "Missing Information",
    "No Property Ownership",
    "Poor Cash flow",
    "Restricted Industry",
    "Serviceability",
    "System",
    "Trading Time Below Minimal	"
];

export type CreditOfficerStatus = 'Approved' | 'Declined' | 'More Information' | 'Request Bank Statements';

export type SystemCreditStatus = 'Approved' | 'Declined' | 'Refer' | 'Validate';

export interface UpdateCreditStatusBody {
    applicationId: number;
    status: CreditOfficerStatus;
    declineDetails?: {
        reasonSelection: string;
        reason: string;
    },
    approveDetails?: {
        creditApprovalDate: string,
        exceptionToPolicy: 'Yes' | 'No',
        creditReference: CreditReference,
        dealStatus: DealStatus,
        financials: 'Yes' | 'No',
        valuationSource: ValuationSource,
        approvalConditions: string,
        lvr: number,
    }
}

export type CreditReference = 'No Dishonors' | 'Deferrals' | 'NA';

export type DealStatus = 
    '1. Full Compliance' |
    '2. Full Compliance - Large Institution, Hospital, Club or Charity' | 
    '3. Full Compliance - Business Continuity' |
    '4. Exception - ABN Age' |
    '5. Exception - DSCR' |
    '6. Exception - Credit Score' |
    '7. Exception - Asset/EOT Age' |
    '8. Exception - Other'

export type ValuationSource = 'Internal Desktop' | 'Redbook' | 'Valuation' | 'Not required';

export const creditReferenceOptions = ['No Dishonors', 'Deferrals', 'NA'];
export const dealStatusOptions = [
    '1. Full Compliance',
    '2. Full Compliance - Large Institution, Hospital, Club or Charity',
    '3. Full Compliance - Business Continuity',
    '4. Exception - ABN Age',
    '5. Exception - DSCR',
    '6. Exception - Credit Score',
    '7. Exception - Asset/EOT Age',
    '8. Exception - Other'
]
export const valuationSourceOptions = ['Internal Desktop', 'Redbook', 'Valuation', 'Not required'];

export interface SelectCreditRuleItemDialogData {
    getCreditRuleItemsFn: GetCreditRuleItemsFn;
}

export interface SelectCreditRuleItemDialogResult {
    readyForSubmission: boolean;
    creditRuleItem: CreditRuleItem;
}

export interface RequestMoreInformationBody {
    additionalInformation: string,
    emailList: string[],
    applicationId: number,
}

export type RequestMoreInformationFn = (body: RequestMoreInformationBody) => Observable<ApiResponse>;

export type CreditAuditLogContext = 'updateCreditStatus' | 'updateAlertStatus' | 'runCreditFlow';

export interface CreditAuditLog {
    id: number,
    applicationId: number,
    dateGenerated: string,
    context: CreditAuditLogContext,
    message: string,
    user: {
        userId: number,
        email: string,
        name: string,
    }
}

export interface PrefillDirectorsDialogData {
    directors: IndividualDirector[];
}

export interface PrefillDirectorsDialogResult {
    readyForSubmission: boolean;
    selectedDirectors: IndividualDirector[];
}

export interface RunCreditorWatchBody {
    applicationId: number;
    abn: string | null;
    acn: string | null;
}

export interface CreditorWatchPrefillResponse {
    directors: IndividualDirector[];
    companyAddress: Address2ComponentValue;
}

export interface MoreInformationDialogData {
    getApplicationOwnerFn: GetApplicationOwnerFn;
    getUserFn: GetUserFn;
    application: Application;
}

export interface MoreInformationDialogResult {
    readyForSubmission: boolean,
    additionalInformation: string,
    emailList: string[],
}

export interface WebServiceJsonDialogData {
    webService: ApplicationWebService
}

export interface WebServiceJsonDialogResult {
    
}

export interface CreditorWatchReportData {
    title: string;
    description: string;
    creditReport: {
        atoTaxDefault: any;
        insolvencyNotices: {
            id: string;
            lastmod: string;
            title: string;
            date: string;
            reportedBy: string;
            ruling: string;
            publisher: string;
        }[];
        paymentDefaults: any[];
        mercantileEnquiries: any[];
        courtJudgements: any[];
        loans: any[];
        creditEnquiries: number;
        anzsic: {
            organisationNumber: string;
            divisionCode: string;
            divisionDescription: string;
            anzsicCode: string;
            anzsicDescription: string;
            subdivisionCode: string;
            subdivisionDescription: string;
            groupCode: string;
            groupDescription: string;
        }
    };
    organisationExtract: {
        additionalDetails: string[];
        billing: {
            billingChargeCode: string;
            billingType: string;
        };
        companyAddresses: {
            addressStartDate: string;
            addressEndDate: string | null,
            addressType: string;
            address2: string;
            careOf: string;
            city: string;
            country: string | null,
            detailsType: string;
            documentNumber: string;
            documentNumberQualifier: string | null,
            postcode: string;
            state: string;
            street: string;
        }[];
        companyOfficeHolders: {
            address2: string | null,
            appointmentDate: string;
            ceasedDate: string | null,
            australianBusinessNumber: string | null,
            birthLocation: {
                countryName: string | null,
                iSO3166CountryCode: string | null,
                line1: string | null,
                line2: string | null,
                line3: string | null,
                locality: string;
                postcode: string | null,
                state: string;
                suburb: string | null
            },
            address: {
                countryName: string | null,
                iSO3166CountryCode: string | null,
                line1: string | null,
                line2: string;
                line3: string | null,
                locality: string | null,
                postcode: string;
                state: string;
                suburb: string;
            },
            careOf: string | null,
            detailsType: string;
            documentNumber: string;
            familyName: string;
            givenName1: string;
            givenName2: string | null,
            givenName3: string | null,
            officerBirthDate: string;
            officerName: string | null,
            officerOrganisationNumber: string | null,
            organisationNumberHeading: string | null,
            roleType: string;
            knownInformation: any[];
            isBankruptcy: boolean;
            bankruptcyLastfetched: number;
        }[];
        contactAddresses: {
            addressStartDate: string;
            addressType: string;
            address2: string;
            careOf: string | null,
            city: string;
            country: string | null,
            detailsType: string;
            documentNumber: string | null,
            postcode: string;
            state: string;
            street: string | null,
            addressEndDate: string | null
        }[];
        financialReports: any[];
        header: {
            clientId: string;
            clientReference: string;
            jurisdiction: string;
            messageCreationDate: string;
            messageCreationTime: string;
            messageTraceNumber: string;
            messageType: string;
            version: string;
        };
        organisation: {
            acnc: string | null,
            australianBusinessNumber: string;
            governanceType: string | null,
            organisationName: string;
            organisationNumber: string;
            organisationNumberHeading: string;
            organisationState: string;
            previousStateNumber: string | null,
            registrationDate: string;
            reviewDate: string;
            historicalChanges: {
                dateDeregistered: string | null,
                detailsType: string;
                disclosingEntity: string;
                documentNumber: string;
                documentNumberQualifier: string | null,
                organisationClass: string;
                organisationName: string;
                organisationNameStartDate: string;
                organisationStatus: string;
                organisationSubClass: string;
                organisationType: string;
                reasonDeregistered: string | null,
                schemeCategory1: string | null,
                schemeCategory2: string | null,
                schemeCategory3: string | null,
                schemeCategory4: string | null,
                startDate: string;
                endDate: string | null
            }[]
        };
        shareCapitalGroup: {
            classCode: string;
            classTitle: string;
            detailsType: string;
            documentNumber: string;
            documentNumberQualifier: string | null,
            numberOfShares: number,
            totalAmountDueAndPayable: number,
            totalAmountPaid: number,
        }[];
        shareInterests: {
            beneficiallyOwned: false,
            classCode: string;
            detailsType: string;
            documentNumber: string;
            documentNumberQualifier: string | null,
            fullyPaid: true,
            members: [
                {
                    memberOrganisationNumber: string;
                    organisationNumberHeading: string;
                    memberName: string;
                    address2: string | null,
                    careOf: string | null,
                    city: string;
                    country: string | null,
                    familyName: string | null,
                    givenName1: string | null,
                    givenName2: string | null,
                    jointHolding: false,
                    postcode: string;
                    state: string;
                    street: string;
                    australianBusinessNumber: string | null
                }
            ],
            numberHeld: string;
        }[];
        documentList: any[],
        misc: any[],
        issuedCapital: any[],
        companyDetails: any[],
        
        
    };
    paymentPredictor: {
        paymentPredictorStats: any[],
        paymentPredictorHistory: any[],
        industryPaymentPredictor: {
            atbData: {
                accountAbnHistory: any[],
                othersAbnHistory: {
                    tradeHistory: any[],
                    getSingleHighestCreditExtended: number,
                }
            },
            paymentPredictorPurchased: true,
            paymentPredictorNoEnoughData: false,
            othersAbnHistory: {
                tradeHistory: any[],
                getSingleHighestCreditExtended: number,
            },
            tableOfStats: {
                averageBalance: number,
                averageOverdue: number,
                highestCreditExposureSingleSupplier: number,
                highestCreditExposureCombinedSuppliers: number,
                highestOverdueCreditExposure: number,
                numberOfTradeLines: number,
            },
            overdueDays: number[]
            averageOverdue: [
                {
                    name: string;
                    data: string[],
                    color: string;
                }
            ],
            noGoodData: false,
            displayStateAlert: false
        }
    },
    paymentRating: any;
    industryPaymentRating: {
        currentPaymentRating: {
            asOfDate: string;
            daysOverdue: number,
            rating: string,
            stats: {
                averageBalance: number,
                averageOverdue: number,
                numberOfTradelines: number,
                highestCreditExposureSingleSupplier: number,
                highestCreditExposureCombinedSupplier: number,
                highestCreditExposureOverdue: number,
            }
        },
        paymentRatingHistory: {
            asOfDate: string;
            daysOverdue: number,
            rating: string,
            stats: {
                averageBalance: number,
                averageOverdue: number,
                numberOfTradelines: number,
                highestCreditExposureSingleSupplier: number,
                highestCreditExposureCombinedSupplier: number,
                highestCreditExposureOverdue: number,
            }
        }[]
    }
    currentDirectors: {
        documentNumber: string,
        detailsType: string,
        roleType: string,
        appointmentDate: string,
        familyName: string,
        givenName1: string,
        officerBirthDate: string,
        birthState: string,
        street: string,
        city: string,
        state: string,
        postcode: string,
        acns: string[],
        givenName2: string | null,
        birthLocality: string,
        officerName: string | null,
        officerOrganisationNumber: string | null,
        givenName3: string | null,
        organisationNumberHeading: string | null,
        address2: string | null,
        australianBusinessNumber: string | null,
        currentDirectorships: {
            company: {
                name: string,
                acn: string | null,
                abn: string | null,
                type: string,
                class: string,
                subClass: string,
                status: string,
                registeredDate: string,
            },
            isHighRisk: boolean,
            acn: string,
        }[],
        formerDirectorships: {
            company: {
                name: string,
                acn: string | null,
                abn: string | null,
                type: string,
                class: string,
                subClass: string,
                status: string,
                registeredDate: string,
            },
            isHighRisk: boolean,
            acn: string,
        }[],
    }[];
    companyDetails: any[];
    creditScore: {
        currentCreditScore: number,
        creditScoreHistory: {
            date: string,
            score: number
        }[]
    };
    creditRating: {
        currentCreditRating: string;
        creditRatingHistory: {
            date: string,
            rating: string,
        }[]
    },
    pdfRequestId: string;
    abrData: {
        name: string;
        abn: string;
        acn: string;
        registeredDate: string;
        status: string;
        type: string;
        typeCode: string;
        updatedDate: string;
        description: string;
        lastUpdated: string;
        statusEffectiveFrom: string | null,
        gst: {
            effectiveTo: string | null,
            effectiveFrom: string;
        }[];
        addresses: {
            effectiveTo: string | null,
            effectiveFrom: string;
            postcode: string;
            stateCode: string;
        }[];
        businessNames: {
            effectiveFrom: string;
            name: string;
            type: string;
        }[],
        charityTypes: any[],
        charityEndorsements: any[],
        dgrEndorsements: any[],
        historicalChanges: {
            entityStatus: any[],
            goodsAndServicesTax: any[],
            mainName: any[],
            businessName: string[],
            mainBusinessPhysicalAddress: any[],
            mainTradingName: any[],
            date: string;
        }[]
    },
    statusChange: {
        abrChanges: {
            entityStatus: string;
            date: string;
        }[];
        asicChanges: any[],
        gstChanges: {
            entityStatus: string;
            date: string;
        }[]
        
    },
    asicData: {
        organisation: {
            australianBusinessNumber: string;
            name: string;
            number: string;
            numberType: string;
            type: string;
            status: string;
            address: {
                countryName: string | null,
                iSO3166CountryCode: string | null,
                line1: string | null,
                line2: string | null,
                line3: string | null,
                locality: string | null,
                postcode: string;
                state: string;
                suburb: string;
            },
            controllingJurisdictionExpanded: string;
            controllingJurisdiction: string;
            numberHeading: string;
            registrationDate: string;
            deregistrationDate: string | null,
            reviewDate: string;
            satisfiedChargesIndicator: string;
            class: string;
            subclass:string;
            typeExpanded: string;
            statusExpanded: string;
            classExpanded: string;
            subclassExpanded: string;
            formattedNumber: string;
            acnc: false
        },
        documents: {
            documentQualifier: string | null,
            additionalDescriptions: {
                additionalDescription: string;
                subFormCode: string;
            }[];
            dateProcessed: string;
            dateReceived: string;
            documentNumber: string;
            effectiveDate: string;
            formCode: string;
            numberOfPages: string;
            documentUnderRequisition: string | null
        }[];
        endOfMessage: {
            endOfMessage: string;
            segmentCount: 42
        },
        organisationFormerName: any[];
        ppsr: {
            searchByAbn: {
                nevdisVehicles: any[],
                registrations: any[],
                searchNumber: string | null,
                searchType: string | null
            },
            searchByAcn: {
                nevdisVehicles: any[],
                registrations: any[],
                searchNumber: string | null,
                searchType: string | null
            },
            searchByName: {
                nevdisVehicles: any[],
                registrations: any[],
                searchNumber: string | null,
                searchType: string | null
            }
        }
    }
}

export interface EquifaxConsumerReportData {
    "soapenv:Envelope": {
        "soapenv:Body": {
            "vs:response": {
                "vs:product-header": {
                    "vs:enquiry-id": string;
                    "vs:datetime-requested": string;
                    "vs:datetime-generated": string;
                    "vs:product-name": string;
                    "vs:permission-type": string;
                    "vs:product-data-level": string;
                    "vs:product-version": number;
                };
                "vs:product-data": {
                    "vs:enquiry-report": {
                        "vs:primary-match": {
                            "vs:bureau-reference": number;
                            "vs:individual-name": {
                                "vs:family-name": string;
                                "vs:first-given-name": string;
                            };
                            "vs:gender": string;
                            "vs:date-of-birth": string;
                            "vs:drivers-licence": {
                                "vs:number": string;
                            };
                            "vs:is-deceased": boolean;
                        };
                        "vs:addresses": {
                            "vs:address": {
                                "vs:id-references": {
                                    "vs:id-reference": number;
                                };
                                "vs:street-number": number;
                                "vs:street-name": string;
                                "vs:street-type": string;
                                "vs:suburb": string;
                                "vs:state": string;
                                "vs:postcode": number;
                                "vs:country-code": string;
                            };
                        };
                        "vs:consumer-credit-file": {
                            "vs:credit-enquiries": {
                                "vs:credit-enquiry": {
                                    "vs:id-references": {
                                        "vs:id-reference": number;
                                    };
                                    "vs:account-type": string;
                                    "vs:enquiry-amount": number;
                                    "vs:relationship": string;
                                    "vs:credit-enquirer": {
                                        "vs:name": string;
                                        "vs:classification"?: string;
                                    };
                                }[];
                            };
                            "vs:accounts": {
                                "vs:account": {
                                    "vs:id-references": {
                                        "vs:id-reference": number;
                                    };
                                    "vs:account-id": string;
                                    "vs:original-credit-provider": {
                                        "vs:name": string;
                                        "vs:classification"?: string;
                                    };
                                    "vs:latest-credit-provider": {
                                        "vs:name": string;
                                    };
                                    "vs:account-type": string;
                                    "vs:relationship": string;
                                    "vs:association-information": {
                                        "vs:is-serious-credit-infringement": boolean;
                                    };
                                    "vs:defaults": {
                                        "vs:default": {
                                            "vs:status": string;
                                            "vs:original-default": {
                                                "vs:date-recorded": string;
                                                "vs:default-amount": number;
                                            };
                                            "vs:current-default": {
                                                "vs:date-recorded": string;
                                                "vs:default-amount": number;
                                            };
                                        };
                                    };
                                }[];
                            };
                        };
                        "vs:commercial-credit-file": {
                            "vs:credit-enquiries": {
                                "vs:credit-enquiry": {
                                    "vs:id-references": {
                                        "vs:id-reference": number;
                                    };
                                    "vs:account-type": string;
                                    "vs:enquiry-amount": number;
                                    "vs:relationship": string;
                                    "vs:credit-enquirer": {
                                        "vs:name": string;
                                        "vs:classification"?: string;
                                    };
                                    "vs:enquiry-client-reference"?: string;
                                }[];
                            };
                        };
                        "vs:public-record-or-consumer-credit-information": {
                            "vs:business-names": {
                                "vs:business-name": {
                                    "vs:id-references": {
                                        "vs:id-reference": number;
                                    };
                                    "vs:start-date": string;
                                    "vs:business": {
                                        "vs:bureau-reference": number;
                                        "vs:name": string;
                                        "vs:previous-state-or-territory-registration": {
                                            "vs:state": string;
                                            "vs:number": number;
                                        };
                                        "vs:ABN": number;
                                    };
                                };
                            };
                        };
                    };
                    "vs:summary-data": {
                        "vs:data-block": {
                            "vs:characteristic": {
                                "vs:variable-name": string;
                                "vs:description": string;
                                "vs:value": string | number;
                                "vs:data-level": string;
                            }[];
                        }[];
                    };
                    "vs:score-data": {
                        "vs:score": {
                            "vs:scorecard": {
                                "vs:name": string;
                                "vs:version": number;
                                "vs:type": string;
                                "vs:data-level": string;
                            };
                            "vs:risk-odds": number;
                            "vs:key-contributing-factors": {
                                "vs:contributing-factor": {
                                    "vs:name": string;
                                    "vs:description": string;
                                    "vs:score-impactor": string;
                                }[];
                            };
                            "vs:score-masterscale": number;
                        };
                    };
                    "vs:possible-matches": {
                        "vs:possible-match": {
                            "vs:identity": {
                                "vs:bureau-reference": number;
                                "vs:individual-name": {
                                    "vs:family-name": string;
                                    "vs:first-given-name": string;
                                };
                                "vs:gender": string;
                                "vs:date-of-birth": string;
                                "vs:drivers-licence": {
                                    "vs:number": string;
                                };
                            };
                            "vs:addresses": {
                                "vs:address": {
                                    "vs:street-number": number;
                                    "vs:street-name": string;
                                    "vs:street-type": string;
                                    "vs:suburb": string;
                                    "vs:state": string;
                                    "vs:postcode": number;
                                    "vs:country-code": string;
                                };
                            };
                            "vs:employment": {
                                "vs:employer": {
                                    "vs:name": string;
                                };
                            };
                        };
                    };
                };
            }
        }
    }
}

export interface EquifaxPreviousEnquiryPdfResponse {
    "soapenv:Envelope": {
        "soapenv:Header": {
            "wsa:MessageID": string;
            "wsa:To": string;
            "wsa:From": {
                "wsa:Address": string;
            };
            "wsa:Action": string;
        },
        "soapenv:Body": {
            "pe:response": {
                "pe:enquiryId": string;
                "pe:enquiryTime": string;
                "pe:binaryData": string;
            }
        }
    }
}

export interface EquifaxFraudCheckAccessToken {
    access_token: string,
    token_type: 'Bearer',
    expires_in: number,
    scope: string
}

export interface EquifaxFraudCheckReportData {
    metadata: {
        apiVersion: string;
        documentVersion: string;
        status: string;
        productRequestID: string;
        correlationId: string;
        productCode: string;
        productName: string;
        statusDescription: string;
        datetimeRequested: string;
        datetimeGenerated: string;
        fulfillmentId: string;
    };
    requestHeader: {
        clientReference: string;
        operatorId: string;
        operatorName: string;
        entityName: string;
    };
    requestParameters: {
        applicantChannel: string;
        productType: string;
        customerProductName: string;
        productSKU: string;
    };
    requestData: {
        applicant: {
            individualName: {
                surname: string;
                firstName: string;
                middleName: string;
            };
            birthDate: string;
            addresses: Array<{
                addressType: string;
                unitNumber: string;
                streetNumber: string;
                property: string;
                streetName: string;
                streetType: string;
                suburb: string;
                city: string;
                state: string;
                postcode: string;
                countryCode: string;
            }>;
            unformattedAddresses: Array<{
                addressType: string;
                unformattedAddress: string;
            }>;
            documents: Array<{
                documentType: string;
                documentNumber: string;
                countryCode: string | null;
                additionalCardNumber: string;
                state: string | null;
            }>;
            phoneNumbers: Array<{
                phoneType: string;
                isdCountryCode: string;
                areaCode: string;
                phoneNumber: string;
            }>;
            employment: {
                employerName: string;
                countryCode: string;
                employerStatus: string;
            };
            email: string;
        };
    };
    responseData: {
        productData: {
            fraudScore: number;
            applicationAssessment: string;
            applicationMatchedRules: Array<{
                [key: string]: string;
            }>;
            fraudCheckReport: {
                fraudCheckResults: Array<{
                    fraudCheckResultSummary: string;
                    fraudCheckResultDetails: {
                        fraudOccurredDate: string | null;
                        applicationDate: string;
                        reportingSubscriber: string | null;
                        fraudType: string | null;
                        fraudReasonCode: string | null;
                        fraudSubject: string;
                        applicantChannel: string;
                        matchAssessment: string | null;
                        productType: string;
                        customerProductName: string;
                        individualName: {
                            surname: string;
                            firstName: string;
                            middleName: string;
                        };
                        birthDate: string;
                        address: Array<{
                            addressType: string;
                            streetName: string;
                            streetNumber: string;
                            state: string;
                            city: string | null;
                            postcode: string;
                            unitNumber: string;
                            suburb: string;
                            streetType: string;
                            property: string;
                            countryCode: string;
                        }>;
                        documents: Array<{
                            documentType: string;
                            documentNumber: string;
                            countryCode: string | null;
                            additionalCardNumber: string;
                            state: string | null;
                        }>;
                        phoneDetails: Array<{
                            phoneType: string;
                            isdCountryCode: string;
                            areaCode: string;
                            phoneNumber: string;
                        }>;
                        employment: {
                            employerName: string;
                            countryCode: string;
                            employerStatus: string;
                        };
                        email: string;
                        victimFlag: boolean | null;
                        lossAmount: number | null;
                        savingValue: number | null;
                    };
                }>;
            };
            errors: string | null;
        };
    };
}

export interface CreditApprovedDetails {
    creditApprovalDate: string,
    exceptionToPolicy: string,
    creditReference: string,
    dealStatus: string,
    financials: string,
    valuationSource: string,
    approvalConditions: string,
    propertyOwnership: string,
    creditOfficer: string,
    bankStatements: string,
    companyVeda: number,
    maxDirectorEquifax: number,
    minDirectorEquifax: number,
    dscr: number,
    creditorWatchScore: number,
    abnAge: number,
    gstAge: number,
    abnAgeMoreThan24Months: string,
    adverse: string,
    lvr: number,
}

export interface CreditDeclineDetails {
    lostReason: string, 
    comment: string, 
    creditOfficer: string, 
    lostCategory: "Withdrawn" | "Credit Declined",
    withdrawUser?: number,
}