import {
    yesNoToBoolean
} from '../utils/utils';

import {
    getDeposit,
    getInvoiceAmount,
    getOrganisationType,
    getPrivateSales,
    getTransactionType,
} from '../utils/application-details-utils';
import {Application, ApplicationTypes, } from '../domain/application-service.domain';
import {
    constants,
    isAssetCaravans,
    isAssetCars,
    isAssetConsumer,
    isAssetConsumerCars,
    isAssetTrucksOrTrailers,
}  from '../const';
import {AzureStorageDocument, DocumentTag, Metadata,} from '../domain/types.domain';
import {DocumentNotificationData, GroupedDocument, NotificationGroupedDocuments} from '../domain/component/document-details-component.domain';

export const getStandardConditionByApplication = (application: Application): DocumentTag[] => {
    const isPrivateSale = yesNoToBoolean(getPrivateSales(application));
    // const isPropertyOwner = yesNoToBoolean(getPropertyOwner(application));
    const organisationType = getOrganisationType(application);
    const invoicePrice = getInvoiceAmount(application);
    const deposit = Number(getDeposit(application) ?? 0);
    const transactionType = getTransactionType(application);
    const assetCategory = (application.AppInfo as any).AssetCategory ?? "";
    const assetType = (application.AppInfo as any).AssetType ?? "";
    const isAssetFinance = application.ApplicationType === 'Consumer' || application.ApplicationType === 'AssetFinance';

    const standardCondition = [
        constants.documentTypes.contract,
        constants.documentTypes.driversLicence,
    ];

    if (isAssetFinance) {
        // did not change anything for private sale and dealer sale
        if (transactionType === 'Private Sale' || transactionType === 'Dealer Sale') {
            standardCondition.push(constants.documentTypes.taxInvoice);
            if (!(invoicePrice - deposit < 100000)) {
                // required noting asset identifiers and A.C.N. 603 273 365 PL (Non-VIN assets <$100k excluded)
                standardCondition.push(constants.documentTypes.certificateOfCurrency);
            }
            if (isPrivateSale) {
                if (deposit) {
                    standardCondition.push(constants.documentTypes.depositReceipt);
                }
                standardCondition.push(constants.documentTypes.proofOfOwnership);
                standardCondition.push(constants.documentTypes.deedOfRelease);
                standardCondition.push(constants.documentTypes.specificCharges);
                standardCondition.push(constants.documentTypes.proofOfSupplierBankDetails);
            } else {
                if (deposit) {
                    standardCondition.push({
                        ...constants.documentTypes.depositReceipt,
                        description: constants.documentTypes.depositReceipt.subDescription,
                    });
                }
            }

            if (isPrivateSale && invoicePrice <= 150000) {
                standardCondition.push(constants.documentTypes.assetInspection);
            }

            if (isPrivateSale && invoicePrice > 150000) {
                standardCondition.push(constants.documentTypes.externalDesktopValuation);
            }
        } else if (transactionType === 'Sales and Lease Back') {
            standardCondition.push(constants.documentTypes.specificCharges);
            standardCondition.push(constants.documentTypes.proofOfOwnership);
            standardCondition.push(constants.documentTypes.confirmationOfAssetAcquisition);
            standardCondition.push(constants.documentTypes.deedOfRelease);
            standardCondition.push(constants.documentTypes.assetInspection);
            if (!(invoicePrice - deposit < 100000)) {
                // required noting asset identifiers and A.C.N. 603 273 365 PL (Non-VIN assets <$100k excluded)
                standardCondition.push(constants.documentTypes.certificateOfCurrency);
            }
        } else if (transactionType === 'Capital Raise') {
            standardCondition.push(constants.documentTypes.specificCharges);
            standardCondition.push(constants.documentTypes.proofOfOwnership);
            standardCondition.push(constants.documentTypes.deedOfRelease);
            standardCondition.push(constants.documentTypes.assetInspectionOnSite);
            standardCondition.push(constants.documentTypes.notExceedForcedLiquidation);
            if (!(invoicePrice - deposit < 100000)) {
                // required noting asset identifiers and A.C.N. 603 273 365 PL (Non-VIN assets <$100k excluded)
                standardCondition.push(constants.documentTypes.certificateOfCurrency);
            }
        } else if (transactionType === 'Refinance') {
            standardCondition.push(constants.documentTypes.proofOfOwnership);
            standardCondition.push(constants.documentTypes.deedOfRelease);
            standardCondition.push(constants.documentTypes.assetInspectionOnSite);
            standardCondition.push(constants.documentTypes.notExceedForcedLiquidation);
            standardCondition.push(constants.documentTypes.disburseFundsToIncumbentFinancier);
            if (!(invoicePrice - deposit < 100000)) {
                // required noting asset identifiers and A.C.N. 603 273 365 PL (Non-VIN assets <$100k excluded)
                standardCondition.push(constants.documentTypes.certificateOfCurrency);
            }
        } else if (transactionType === 'Balloon Refinance') {
            standardCondition.push(constants.documentTypes.proofOfOwnership);
            standardCondition.push(constants.documentTypes.deedOfRelease);
            standardCondition.push(constants.documentTypes.assetInspectionOnSite);
            standardCondition.push(constants.documentTypes.notExceedForcedLiquidation);
            standardCondition.push(constants.documentTypes.disburseFundsToIncumbentFinancier);
            if (!(invoicePrice - deposit < 100000)) {
                // required noting asset identifiers and A.C.N. 603 273 365 PL (Non-VIN assets <$100k excluded)
                standardCondition.push(constants.documentTypes.certificateOfCurrency);
            }
        }
    }

    if (organisationType === 'Trust') {
        standardCondition.push(constants.documentTypes.trustDeed);
    }

    if (organisationType === 'Partner') {
        standardCondition.push(constants.documentTypes.certifiedPartnershipAgreement);
    }

    return standardCondition;
}

export const groupStandardCondition = (groups: GroupedDocument[], application: Application): GroupedDocument[] => {
    const standardCondition = getStandardConditionByApplication(application);
    // console.log('=-=================standardCondition: ', standardCondition)
    console.log('==groups: ', groups);
    const standardConditionValues = standardCondition.map(t => t.value);
    const groupWithStandardCondition: GroupedDocument[] = [];
    for (const group of groups) {
        const index = standardConditionValues.indexOf(group.value);
        if (index > -1) {
            groupWithStandardCondition.push({
                ...group,
                ...standardCondition[index],
                required: true,
            })
        }
        else {
            groupWithStandardCondition.push({
                ...group,
                standardCondition: false,
            })
        }
    }

    for (const condition of standardCondition) {
        const groupValues = groupWithStandardCondition.map(g => g.value);
        if (!groupValues.includes(condition.value)) {
            groupWithStandardCondition.push({
                groupName: condition.type,
                description: condition?.description ?? '',
                value: condition.value,
                status: 'Pending',
                required: true,
                disableDocumentUpload: condition?.disableDocumentUpload ?? false,
                standardCondition: true,
                standardConditionStatus: 'Pending',
                docs: []
            })
        }
    }
    return groupWithStandardCondition.sort((a, b) => {
        if (a.required !== b.required) {
            return a.required ? -1 : 1;
        } else {
            return a.groupName.localeCompare(b.groupName);
        }
    });
}

export const displayDocumentTag = (name: string): string => {
    const allTags: DocumentTag[] = Object.values(constants.documentTypes);
    const tagObj = allTags.find(t => t.value === name);
    if (tagObj) {
        return tagObj.type
    }
    return name;
}

export const unclassifiedDocuments = (): DocumentTag => {
    return constants.unclassifiedDocuments;
}

export const defaultDocuments = (
    applicationType: ApplicationTypes,
    guarantors: string[] = [],
    customerName: string,
    assetCategory: string,
    assetType: string,
    loanAmount: number | undefined,
    deposit: number | undefined,
    isPrivateSale: boolean = false,
    isTrust: boolean = false,
    isPartnership: boolean = false,
    includeOptionalTypes: boolean = false,
): DocumentTag[] => {
    const guarantorNames = guarantors.join(', ');

    const docs: DocumentTag[] = [
        {
            ...constants.documentTypes.privacyConsent,
            description: constants.documentTypes.privacyConsent.description +
                (applicationType == 'Consumer' ? customerName : guarantorNames) + ' ' +
                constants.documentTypes.privacyConsent.subDescription
        },
        constants.documentTypes.driversLicence,
        constants.documentTypes.bankStatements,
        constants.documentTypes.financials,
        constants.documentTypes.atoActivityStatements,
        constants.documentTypes.assetLiabilitiesStatement,
        constants.documentTypes.ppsr,
        constants.documentTypes.brokerInvoices,
        constants.documentTypes.internalDocuments,
        constants.documentTypes.creditorWatch,
        constants.documentTypes.ProofOfPropertyOwnership,
        constants.documentTypes.MortgageStatements,
        constants.documentTypes.ComparableCreditStatements,
        constants.documentTypes.BusinessUseConfirmation,
        constants.documentTypes.MedicareCard,
        constants.documentTypes.ReviewOfFinalInvoice,
        constants.documentTypes.ExternalOnsiteValuation,
        constants.documentTypes.approvalNotice,
        constants.documentTypes.assetInspection,
        constants.documentTypes.disburseFundsToIncumbentFinancier,
        constants.documentTypes.confirmationOfAssetAcquisition,
        constants.documentTypes.assetInspectionOnSite,
        constants.documentTypes.notExceedForcedLiquidation
    ];

    //--- conditions  ---//
    if (!['BusinessLoans', 'BusinessOverdraft'].includes(applicationType)) {
        docs.push(constants.documentTypes.taxInvoice);
        if (deposit) {
            docs.push(constants.documentTypes.depositReceipt);
        }
    }
    if(!['Consumer'].includes(applicationType)){
        docs.push(constants.unclassifiedDocuments);
    }
    if(applicationType == 'Consumer'){
        docs.push(constants.documentTypes.PaySlips);
    }

    if (['BusinessLoans', 'BusinessOverdraft'].includes(applicationType)) {
        docs.push(constants.documentTypes.payoutLetter);
    }

    if (isTrust) {
        docs.push(constants.documentTypes.trustDeed);
    }

    // apply to amount > 100K or vehicles
    // if ( (loanAmount && loanAmount > 100000) || (["132", "137"].includes(assetCategory))
    //   || (assetCategory === "139" && ["1", "2", "3"].includes(assetType))) {
    //   docs.push(constants.documentTypes.certificateOfCurrency);
    // }

    if (isPrivateSale) {
        if (loanAmount && loanAmount > 150000) {
            docs.push({
                ...constants.documentTypes.externalDesktopValuation,
                required: true
            });
        } else {
            docs.push(constants.documentTypes.assetInspection);
        }

        docs.push({
            ...constants.documentTypes.proofOfSupplierBankDetails,
            required: true
        });
        docs.push({
            ...constants.documentTypes.proofOfOwnership,
            required: true
        })
    } else {
        docs.push(constants.documentTypes.proofOfSupplierBankDetails);
        docs.push(constants.documentTypes.assetInspection);
    }

    if (isPartnership) {
        docs.push(constants.documentTypes.certifiedPartnershipAgreement);
    }

    if (includeOptionalTypes) {
        docs.push(constants.documentTypes.guarantorStatutoryDeclaration);
        docs.push(constants.documentTypes.deedOfRelease);
        if (!docs.find(doc => doc.value === constants.documentTypes.proofOfOwnership.value)) {
            docs.push(constants.documentTypes.proofOfOwnership);
        }
        docs.push(constants.documentTypes.directionToPay);
        docs.push(constants.documentTypes.certifiedIds);
        docs.push(constants.documentTypes.ppsrDischargeCertificate);
        docs.push(constants.documentTypes.externalDesktopValuation);
        docs.push(constants.documentTypes.certificateOfCurrency);
        docs.push(constants.documentTypes.applicationForm);
        docs.push(constants.documentTypes.contract);
        docs.push(constants.documentTypes.specificCharges);
    }

    return docs;
}

export const getAllDefaultTags = (): DocumentTag[] => {
    return [
        constants.documentTypes.driversLicence,
        constants.documentTypes.privacyConsent,
        constants.documentTypes.bankStatements,
        constants.documentTypes.financials,
        constants.documentTypes.atoActivityStatements,
        constants.documentTypes.assetLiabilitiesStatement,
        constants.documentTypes.taxInvoice,
        constants.documentTypes.ppsr,
        constants.documentTypes.brokerInvoices,
        constants.documentTypes.payoutLetter,
        constants.documentTypes.trustDeed,
        constants.documentTypes.certificateOfCurrency,
        constants.documentTypes.proofOfSupplierBankDetails,
        constants.documentTypes.certifiedPartnershipAgreement,
        constants.documentTypes.depositReceipt,
        constants.documentTypes.guarantorStatutoryDeclaration,
        constants.documentTypes.deedOfRelease,
        constants.documentTypes.proofOfOwnership,
        constants.documentTypes.directionToPay,
        constants.documentTypes.certifiedIds,
        constants.documentTypes.ppsrDischargeCertificate,
        constants.documentTypes.specificCharges,
        constants.documentTypes.externalDesktopValuation,
        constants.documentTypes.internalDocuments,
        constants.documentTypes.creditorWatch,
        constants.documentTypes.applicationForm,
        constants.documentTypes.contract,
        constants.documentTypes.specificCharges,
        constants.documentTypes.approvalNotice,
        constants.documentTypes.assetInspection,
        constants.documentTypes.disburseFundsToIncumbentFinancier,
        constants.documentTypes.confirmationOfAssetAcquisition,
        constants.documentTypes.assetInspectionOnSite,
        constants.documentTypes.notExceedForcedLiquidation
    ];
}

export const accreditationDocumentTags = (): DocumentTag[] => {
    return [
        constants.unclassifiedDocuments,
        constants.documentTypes.driversLicence,
        constants.documentTypes.professionalIndemnityInsurance,
        constants.documentTypes.industryMemberships,
        constants.documentTypes.executedBrokerAgreement,
        constants.documentTypes.bankDepositSlip,
        constants.documentTypes.introducerReferralAgreement,
    ]
}

export const getNotificationDocuments = (documents: GroupedDocument[]): {
    pendingDocs: NotificationGroupedDocuments[],
    inProgressDocs: NotificationGroupedDocuments[],
    acceptedDocs: NotificationGroupedDocuments[],
    rejectedDocs: NotificationGroupedDocuments[],
} => {
    const pendingDocs: NotificationGroupedDocuments[] = [];
    const inProgressDocs: NotificationGroupedDocuments[] = [];
    const rejectedDocs: NotificationGroupedDocuments[] = [];
    const acceptedDocs: NotificationGroupedDocuments[] = [];

    for (const g of documents) {
        if (!g.docs?.length) {
            if ((!g.standardCondition && !g.nonStandardCondition && g.status.toLowerCase() === 'pending') ||
                (g.standardCondition && g?.standardConditionStatus?.toLowerCase() === 'pending') ||
                (g.nonStandardCondition && g?.nonStandardConditionStatus?.toLowerCase() === 'pending')
            ) {
                pendingDocs.push({
                    ...g,
                    wholeGroup: true
                });
            } else if ((!g.standardCondition && !g.nonStandardCondition && g.status.toLowerCase() === 'rejected') ||
                (g.standardCondition && g?.standardConditionStatus?.toLowerCase() === 'rejected') ||
                (g.nonStandardCondition && g?.nonStandardConditionStatus?.toLowerCase() === 'rejected')) {
                rejectedDocs.push({
                    ...g,
                    wholeGroup: true
                });
            } else if ((!g.standardCondition && !g.nonStandardCondition && g.status.toLowerCase() === 'accepted') ||
                (g.standardCondition && g?.standardConditionStatus?.toLowerCase() === 'accepted') ||
                (g.nonStandardCondition && g?.nonStandardConditionStatus?.toLowerCase() === 'accepted')) {
                acceptedDocs.push({
                    ...g,
                    wholeGroup: true
                });
            }
        } else {
            const rejectedDoc = g.docs.filter(d => (d.metadata as Metadata)[g.value].toLowerCase() === 'rejected');
            const acceptedDoc = g.docs.filter(d => (d.metadata as Metadata)[g.value].toLowerCase() === 'accepted');
            const inProgressDoc = g.docs.filter(d => (d.metadata as Metadata)[g.value].toLowerCase() === 'in progress');

            if (rejectedDoc && rejectedDoc.length) {
                rejectedDocs.push({
                    ...g,
                    docs: rejectedDoc,
                    wholeGroup: false
                });
            }

            if (acceptedDoc && acceptedDoc.length) {
                acceptedDocs.push({
                    ...g,
                    docs: acceptedDoc,
                    wholeGroup: false
                })
            }

            if (inProgressDoc && inProgressDoc.length) {
                inProgressDocs.push({
                    ...g,
                    docs: inProgressDoc,
                    wholeGroup: false
                })
            }
        }
    }

    acceptedDocs.sort((a, b) => {
        if (a.wholeGroup !== b.wholeGroup) {
            return a.wholeGroup ? -1 : 1;
        }
        return 0;
    })

    return {
        pendingDocs,
        inProgressDocs,
        acceptedDocs,
        rejectedDocs
    }
}

export const getDocumentNotificationStrings = (group: GroupedDocument, maxLength: number = 48): DocumentNotificationData[] => {
    const results: DocumentNotificationData[] = [];
    if (maxLength < 19) { // too short
        return results;
    }
    if (group && group?.docs) {
        let groupName = group?.groupName ?? '';
        groupName = groupName;

        if (group?.docs && group?.docs?.length) {
            for (const doc of group?.docs) {
                let name = '';
                if (doc && (doc?.metadata as any)?.name) {
                    name = (doc?.metadata as any).name ?? '';
                    name = name;   
                    groupName = groupName;
                }
                results.push({
                    groupName: groupName,
                    space: maxLength - groupName.length - name.length,
                    docName: name
                });
            }
        } 
        else {
            results.push({
                groupName: groupName,
                space: 0,
                docName: ''
            });
        }
    }

    return results;
}

export const getUniqueDocumentTags = (data: AzureStorageDocument[]): DocumentTag[] => {
    let allTagValues: string[] = [];
    const allDefaultTags: DocumentTag[] = getAllDefaultTags();
    for (const doc of data) {
        allTagValues = [...allTagValues, ...Object.values(doc?.tags ?? {})];
    }
    allTagValues = [...new Set(allTagValues)];
    return allTagValues.map(value => {
        const existingTagObj = allDefaultTags.find(tag => tag.value === value);
        return {
            type: existingTagObj ? existingTagObj.type : value,
            value: value
        }
    }) as DocumentTag[];
}

export const unionExistingTagsWithDefaultTags = (data: AzureStorageDocument[]): DocumentTag[] => {
    let allTagValues: string[] = [];
    const allDefaultTags: DocumentTag[] = getAllDefaultTags();
    for (const doc of data) {
        allTagValues = [...allTagValues, ...Object.values(doc?.tags ?? {})];
    }
    allTagValues = [...new Set(allTagValues), ...allDefaultTags.map(t => t.value)];
    return allTagValues.map(value => {
        const existingTagObj = allDefaultTags.find(tag => tag.value === value);
        return {
            type: existingTagObj ? existingTagObj.type : value,
            value: value
        }
    }) as DocumentTag[];
}


export const formGroupedDocumentData = (rawData: AzureStorageDocument[], documentTypes: DocumentTag[] = [], customerName: string = ""): GroupedDocument[] => {
    let docs: GroupedDocument[] = [];
    if (documentTypes && documentTypes.length) {
        docs = documentTypes.map((docType: DocumentTag) => {
            let group: GroupedDocument = {
                groupName: docType.type,
                description: docType?.description ?? '',
                value: docType.value,
                status: '',
                required: docType?.required ?? false,
                disableDocumentUpload: docType?.disableDocumentUpload ?? false,
                docs: []
            };
            if (docType?.nonStandardCondition) {
                group = {
                    ...group,
                    nonStandardCondition: !!docType?.nonStandardCondition,
                    nonStandardConditionStatus: docType?.nonStandardConditionStatus ?? "Pending",
                }
            }
            if (docType?.standardCondition) {
                group = {
                    ...group,
                    standardCondition: !!docType?.standardCondition,
                    standardConditionStatus: docType?.standardConditionStatus ?? "Pending",
                }
            }
            return group;
        });
    } else {
        // get all unique tags
        docs = getUniqueDocumentTags(rawData).map(t => {
            let group: GroupedDocument = {
                groupName: t.value,
                description: '',
                value: t.value,
                status: '',
                required: false,
                docs: []
            }

            if (t?.nonStandardCondition) {
                group = {
                    ...group,
                    nonStandardCondition: !!t?.nonStandardCondition,
                    nonStandardConditionStatus: t?.nonStandardConditionStatus ?? "Pending",
                }
            }
            if (t?.standardCondition) {
                group = {
                    ...group,
                    standardCondition: !!t?.standardCondition,
                    standardConditionStatus: t?.standardConditionStatus ?? "Pending",
                }
            }
            return group;
        })
    }

    const foundContractObj = rawData.find(data => Object.values(data?.tags ?? {}).includes(constants.documentTypes.contract.value));
    if (foundContractObj && !docs.find(g => g.value == constants.documentTypes.contract.value)) {
        docs.push({
            groupName: constants.documentTypes.contract.type,
            description: (constants.documentTypes.contract.description + customerName),
            value: constants.documentTypes.contract.value,
            status: '',
            required: false,
            docs: []
        })
    }

    const foundApprovalNoticeObj = rawData.find(data => Object.values(data?.tags ?? {}).includes(constants.documentTypes.approvalNotice.value));
    if (foundApprovalNoticeObj && !docs.find(g => g.value == constants.documentTypes.approvalNotice.value)) {
        docs.push({
            groupName: constants.documentTypes.approvalNotice.type,
            description: constants.documentTypes.approvalNotice.description,
            value: constants.documentTypes.approvalNotice.value,
            status: '',
            required: false,
            docs: []
        })
    }

    const foundTaxInvoiceObj = rawData.find(data => Object.values(data?.tags ?? {}).includes(constants.documentTypes.taxInvoice.value));
    if (foundTaxInvoiceObj && !docs.find(g => g.value == constants.documentTypes.taxInvoice.value)) {
        docs.push({
            groupName: constants.documentTypes.taxInvoice.type,
            description: constants.documentTypes.taxInvoice.description,
            value: constants.documentTypes.taxInvoice.value,
            status: '',
            required: false,
            docs: []
        })
    }

    const foundPrivacyConsentObj = rawData.find(data => Object.values(data?.tags ?? {}).includes(constants.documentTypes.privacyConsent.value));
    if (foundPrivacyConsentObj && !docs.find(g => g.value == constants.documentTypes.privacyConsent.value)) {
        docs.push({
            groupName: constants.documentTypes.privacyConsent.type,
            description: constants.documentTypes.privacyConsent.description,
            value: constants.documentTypes.privacyConsent.value,
            status: '',
            required: false,
            docs: []
        })
    }

    for (const data of rawData) {
        if (data?.tags) {
            const tagValues = Object.values(data.tags);

            let pushed = false;
            for (let i = 0; i < docs.length; i++) {
                if (tagValues.includes(docs[i].value)) {
                    docs[i].docs.push(data);
                    pushed = true;
                }
            }
        }
    }

    // remove others if no documents
    const othersObj = docs.find(doc => doc.value === 'others');
    if (othersObj) {
        if (!othersObj.docs || !othersObj.docs.length) {
            docs = docs.filter(doc => doc.value !== 'others');
        }
    }

    // review status
    for (let i = 0; i < docs.length; i++) {
        if (docs[i]?.docs && docs[i].docs.length) {
            const statusArr = docs[i].docs.map(m => (m.metadata as Metadata)[docs[i].value]);
            const uniqueStatus = [...new Set(statusArr)];
            if (uniqueStatus?.length === 1) {
                docs[i].status = uniqueStatus[0];
                if (!!docs[i].nonStandardCondition) {
                    docs[i].nonStandardConditionStatus = uniqueStatus[0] as "Pending" | "Accepted" | "Rejected" | "In Progress";
                }
                if (!!docs[i].standardCondition) {
                    docs[i].standardConditionStatus = uniqueStatus[0] as "Pending" | "Accepted" | "Rejected" | "In Progress";
                }
            } else {
                docs[i].status = 'In Progress';
                if (!!docs[i].nonStandardCondition) {
                    docs[i].nonStandardConditionStatus = 'In Progress';
                }
                if (!!docs[i].standardCondition) {
                    docs[i].standardConditionStatus = 'In Progress';
                }
            }
        } else {
            // no documents
            docs[i].status = docs[i].required ? 'Pending' : '';
        }
    }

    return docs.sort((a, b) => {
        if (a.required !== b.required) {
            return a.required ? -1 : 1;
        } else {
            return a.groupName.localeCompare(b.groupName);
        }
    });
}
