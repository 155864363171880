import {CustomerAccessLevel, Priviledges, UserType} from './domain/types.domain';
import {RoleAccess} from "./domain/auth-service.domain";
import { IdTypes } from './domain/component/identification-component.domain';

export const DEFAULT_RATECARD_ID = 1;

export const DEFAULT_LIMIT = 20;
export const GST = 11;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_FILTER = '';
export const DEFAULT_MIN_FILE_UPLOAD_SIZE = 0;
export const DEFAULT_USER_TYPE: UserType = 'broker-user' as const;
export const DEFAULT_APPLICATION_TYPE_FILTER = { type: 'All', name: 'All Applications'} as const;
export const DEFAULT_PUBLISHED_STATUS_FILTER = { type: 'All', name: 'All'} as const;
export const DEFAULT_SORTS = undefined;
export const DEFAULT_BUSINESS_TYPE_FILTER = { type: 'All', name: 'All Businesses'} as const;
// export const INTERNAL_EMAIL_DOMAINS = ['growfinance.com.au', 'grow.com.au'];
export const INTERNAL_EMAIL_DOMAINS = ['growfinance.com.au', 'grow.com.au', 'dynamoney.com'];
export const SALES_EMAIL_DOMAIN = 'sales@dynamoney.com';
export const CONTRACT_EMAIL_DOMAIN = 'contract@dynamoney.com';
export const CREDIT_EMAIL_DOMAIN = 'credit@dynamoney.com';
export const INTERNAL_ACCESS_LEVEL: Priviledges = ['admin', 'analyst', 'internalbroker','operations', 'operations24', 'credit', 'settlement','salesBDM' , 'salesAM'];
export const BROKER_ACCESS_LEVEL: Priviledges = ['internalbroker','externalbroker','companyoperator','admin', 'companyadmin', 'analyst', 'operations','operations24' , 'credit', 'settlement', 'salesBDM', 'salesAM'];
export const SIDE_MENU_ALL_ACCESS_LEVEL: Priviledges = ['admin' , 'analyst' , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' , 'companyadmin', 'companyoperator'  , 'externalbroker', 'internalbroker' ,'lg'];
export const DEALER_ACCESS_LEVELS: Priviledges = ['dealer'];
export const ALL_BROKER_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst'  , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' , 'companyadmin', 'companyoperator' , 'customer' , 'broker', 'vendor' , 'externalbroker', 'internalbroker' ,'salesBDM', 'salesAM'] ;
export const INTERNAL_LEVELS: Priviledges = [ 'admin' , 'internalbroker','salesBDM', 'salesAM', 'analyst', 'operations' , 'credit', 'settlement' ];
export const ADMIN_TAB_ACCESS_LEVELS: Priviledges = [ 'admin', 'companyadmin', 'analyst', 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' ];
export const ADMIN_AND_ANALYST_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst' , 'operations' , 'credit', 'settlement', 'salesBDM', 'salesAM' ];
export const NOT_SALESAM_ACCESS_LEVELS: Priviledges = [ 'admin' , 'analyst' , 'operations' , 'credit', 'settlement' ];
export const ADMIN_ACCESS_LEVELS: Priviledges = ['admin'];
export const OPERATIONS_TAB_ACCESS_LEVELS: Priviledges = ['operations'];
export const PISMO_ACCOUNTS_ACCESS_LEVELS: Priviledges = ['admin', 'operations', 'analyst'];
export const ADMIN_CREDIT_ACCESS_LEVELS: Priviledges = ['admin', 'credit'];
export const PENDING_APPROVAL_UNVERIFIED_USER_ACCESS_LEVEL: Priviledges = [ 'admin' , 'operations', 'salesBDM', 'salesAM' ];

export const DASHBOARD_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;
export const OVERDRAFT_PRODUCT_ACCESS_LEVEL: Priviledges = INTERNAL_ACCESS_LEVEL;
export const APPLICATIONS_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;
export const NEW_APPLICATION_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;
export const CALCULATORS_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;
export const ASSET_FINANCE_CALCULATOR_ACCESS_LEVEL: Priviledges = [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS];
export const BUSINESS_TERM_LOAN_CALCULATOR_ACCESS_LEVEL: Priviledges = [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS];
export const BUSINESS_OVERDRAFT_CALCULATOR_ACCESS_LEVEL: Priviledges = [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS];
export const BUSINESS_AND_OVERDRAFT_BORROWING_ESTIMATOR_CALCULATOR_ACCESS_LEVEL: Priviledges = [...DEALER_ACCESS_LEVELS, ...ALL_BROKER_ACCESS_LEVELS];
export const PISMO_ACCOUNTS_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const BANK_STATEMENTS_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const BANK_STATEMENT_ANALYSIS_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const BANK_STATEMENT_STATUS_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const DOCUMENTS_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const DOCUMENT_WORKLIST_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const REDBOOK_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const ACCREDITATION_ACCESS_LEVEL: Priviledges = INTERNAL_LEVELS;
export const ADMIN_ACCESS_LEVEL: Priviledges = ADMIN_TAB_ACCESS_LEVELS;
export const USERS_ACCESS_LEVEL: Priviledges = ADMIN_TAB_ACCESS_LEVELS;
export const PENDING_APPROVALS_ACCESS_LEVEL: Priviledges = PENDING_APPROVAL_UNVERIFIED_USER_ACCESS_LEVEL;
export const UNVERIFIED_USERS_ACCESS_LEVEL: Priviledges = PENDING_APPROVAL_UNVERIFIED_USER_ACCESS_LEVEL;
export const RATE_CARDS_ACCESS_LEVEL: Priviledges = NOT_SALESAM_ACCESS_LEVELS;
export const CUSTOMERS_ACCESS_LEVEL: Priviledges = ADMIN_AND_ANALYST_ACCESS_LEVELS;
export const COMPANIES_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const REPORTING_ACCESS_LEVEL: Priviledges = NOT_SALESAM_ACCESS_LEVELS;
export const TRANSACTIONS_ACCESS_LEVEL: Priviledges = [...ADMIN_ACCESS_LEVELS, ...OPERATIONS_TAB_ACCESS_LEVELS];
export const PENDING_APPROVAL_TRANSACTIONS_ACCESS_LEVEL: Priviledges = PISMO_ACCOUNTS_ACCESS_LEVELS;
export const AUDIT_LOGS_ACCESS_LEVEL: Priviledges = NOT_SALESAM_ACCESS_LEVELS;
export const NOTIFICATIONS_MANAGEMENT_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const NOTIFICATIONS_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;
export const SET_MAINTENANCE_MANAGEMENT_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const OAUTH_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const PROMOTION_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const BANK_STATEMENT_CONFIGURATION_ACCESS_LEVEL: Priviledges = ADMIN_CREDIT_ACCESS_LEVELS;
export const WHITELABEL_SETTINGS_ACCESS_LEVEL: Priviledges = ADMIN_ACCESS_LEVELS;
export const SEND_PRIVACY_CONSENT_ACCESS_LEVEL: Priviledges = ALL_BROKER_ACCESS_LEVELS;
export const CREDIT_MANAGEMENT_ACCESS_LEVEL: Priviledges = ADMIN_CREDIT_ACCESS_LEVELS;
export const FAQ_ACCESS_LEVEL: Priviledges = SIDE_MENU_ALL_ACCESS_LEVEL;


export const INVALID_EXPO_APP_ID = 'invalid-expo-app-id';
export const STANDARD_INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const STANDARD_INPUT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const TIER1_ASSET_CATS = ['137'];
export const TIER2_ASSET_CATS = ['132', '130', '140'];
export const TIER3_ASSET_CATS = ['136', '133', '106'];
export const TIER4_ASSET_CATS = ['134', '135', '142'];

export const CARD_ADMIN_ROLE_ACCESS: RoleAccess = {isAllTransaction: true, isThirdPartyPayment: true, isBpay: true};
export const CARD_MEMBER_ROLE_ACCESS: RoleAccess = {isAllTransaction: false, isThirdPartyPayment: false, isBpay: false};
export const DEFAULT_CUSTOMER_ACCESS_LEVEL: CustomerAccessLevel = 'card-admin';
export const DEFAULT_CUSTOMER_ROLE_ACCESS: RoleAccess = CARD_ADMIN_ROLE_ACCESS;

export const STANDARD_MOMENT_DATE_FORMAT = `DD/MM/YYYY`;

export type DscrIndustry = {
  name: 'Construction' | 'Transport' | 'Professional Services' | 'Administrative and Support Service' | 'Retail Trade' | 'Manufacturing' | 'Agriculture, Forestry and Fishing' | 'Accomodation and Food Services' | 'Other Services' | 'Not Available',
  ebitda: DscrIndustryEbitda[]
}

export type DscrIndustryEbitda = {
  name: string, percentage: number,
}


export const DSCR_Industries: DscrIndustry[] = [
  {
    name: 'Construction',
    ebitda: [
      { name: 'House Construction in Australia', percentage: 10},
      { name: 'Commercial and Industrial Building Construction in Australia', percentage: 10},
      { name: 'Road and Bridge Construction in Australia', percentage: 12},
      { name: 'Site Preparation Services in Australia', percentage: 20},
      { name: 'Concreting Services in Australia', percentage: 15},
      { name: 'Bricklaying Services in Australia', percentage: 20},
      { name: 'Roofing Services in Australia', percentage: 20},
      { name: 'Structural Steel Erection Services in Australia', percentage: 15},
      { name: 'Plumbing Services in Australia', percentage: 15},
      { name: 'Electrical Services in Australia', percentage: 15},
      { name: 'Air Conditioning and Heating Services in Australia', percentage: 15},
      { name: 'Carpentry Services in Australia', percentage: 15},
      { name: 'Construction Machinery and Operator Hire in Australia', percentage: 20},
    ]
  },
  {
    name: 'Transport',
    ebitda: [
      { name: 'Road Freight Transport in Australia', percentage: 15 },
      { name: 'Courier Pick-up and Delivery Services in Australia', percentage: 10 },
      { name: 'Taxi and Limousine Transport in Australia', percentage: 10 },
      { name: 'Interurban and Rural Bus Transport in Australia', percentage: 15 },
    ],
  },
  {
    name: 'Professional Services',
    ebitda: [
      { name: 'Architectural Services in Australia', percentage: 25 },
      { name: 'Surveying and Mapping Services in Australia', percentage: 25 },
      { name: 'Engineering Consulting in Australia', percentage: 20 },
      { name: 'Legal Services in Australia', percentage: 30 },
      { name: 'Accounting Services in Australia', percentage: 30 },
    ],
  },
  {
     name: 'Administrative and Support Service',
     ebitda: [
       { name: 'Employment Placement and Recruitment Services in Australia', percentage:5 },
       { name: 'Temporary Staff Services in Australia', percentage:	5 },
       { name: 'Facilities Management and Other Office Administrative Services in Australia', percentage:	20 },
       { name: 'Commercial Cleaning Services in Australia', percentage:	20 },
       { name: 'Rental, Hiring and Real Estate Services', percentage: 17 },
       { name: 'Machinery and Scaffolding Rental in Australia', percentage:	30 },
       { name: 'Real Estate Services in Australia', percentage:	20 },
     ],
  },
  {
     name: 'Retail Trade',
     ebitda: [
       { name: 'Motor Vehicle Dealers in Australia', percentage: 5 },
       { name: 'Trailer and Caravan Dealers in Australia', percentage: 5 },
       { name: 'Fuel Retailing in Australia', percentage: 3 },
       { name: 'Supermarkets and Grocery Stores in Australia', percentage: 10 },
       { name: 'Fresh Meat, Fish and Poultry Retailing in Australia', percentage:	10 },
       { name: 'Fruit and Vegetable Retailing in Australia', percentage: 10 },
       { name: 'Liquor Retailing in Australia', percentage: 10 },
       { name: 'Hardware and Building Supplies Retailing in Australia', percentage:	10 },
       { name: 'Clothing Retailing in Australia', percentage:	10 },
       { name: 'Pharmacies in Australia', percentage: 10 },
     ],
  },
  {
     name: 'Manufacturing',
     ebitda: [
       { name: 'Wooden Structural Component Manufacturing in Australia', percentage: 15 },
       { name: 'Fabricated Metal Product Manufacturing in Australia', percentage:	15 },
       { name: 'Aluminium Door and Window Manufacturing in Australia', percentage: 15 },
       { name: 'Wine Production in Australia', percentage: 15 },
       { name: 'Beer Manufacturing in Australia', percentage:	20 },
       { name: 'Spirit Manufacturing in Australia', percentage: 15 },
       { name: 'Soft Drink Manufacturing in Australia', percentage: 15 },
     ],
  },
  {
     name: 'Agriculture, Forestry and Fishing',
     ebitda: [
       { name: 'Apple, Pear and Stone Fruit Growing', percentage:	20 },
       { name: 'Grape Growing in Australia', percentage: 	20 },
       { name: 'Citrus Fruit, Nut and Other Fruit Growing in Australia', percentage: 20 },
       { name: 'Beef and Sheep Farming in Australia', percentage:	30 },
       { name: 'Grain-Sheep or Grain-Beef Cattle Farming in Australia', percentage:	30 },
       { name: 'Grain Growing in Australia', percentage: 35 },
       { name: 'Rice Growing in Australia', percentage: 30 },
       { name: 'Dairy Cattle Farming in Australia', percentage: 30 },
       { name: 'Poultry Meat Farming in Australia', percentage: 10 },
       { name: 'Egg Farming in Australia', percentage: 10 },
       { name: 'Forestry and Logging in Australia', percentage: 15 },
       { name: 'Shearing, Cropping and Agricultural Support Services in Australia', percentage: 20 },
     ],
  },
  {
      name: 'Accomodation and Food Services',
      ebitda: [
        { name: 'Motels in Australia', percentage:10 },
        { name: 'Caravan Parks, Holiday Houses and Other Accommodation in Australia', percentage:	10 },
        { name: 'Cafes, Restaurants and Takeaway Food Services', percentage: 10 },
        { name: 'Pubs in Australia', percentage:10 },
        { name: 'Catering Services in Australia', percentage: 10 },
      ],
  },
  {
      name: 'Other Services',
      ebitda: [
        { name: 'Automotive Repair and Maintenance', percentage:15 },
        { name: 'Machinery and Equipment Repair and Maintenance', percentage:	15 },
        { name: 'Other Repair and Maintenance', percentage: 15 },
        { name: 'Personal and Other Services', percentage:	20 },
      ],
  },
  {
    name: 'Not Available',
    ebitda: [
      { name: 'Not Available', percentage: 0 },
    ],
}
]


export const constants = {
  DSCR_Industries,
  products: {
    business: {
      minBorrowing: 5000,
      maxBorrowing: 500000,
    },
    overdraft: {
      minBorrowing: 5000,
      maxBorrowing: 500000,
    },
    asset:{
      minBorrowing: 5000,
      maxBorrowing: 1000000,
    },
    invoice:{
      minBorrowing: 5000,
      maxBorrowing: 2500000,
    },
    insurance:{
      minBorrowing: 5000,
      maxBorrowing: 5000000
    },
    consumer:{
      minBorrowing: 5000,
      maxBorrowing: 500000
    },
    corporate:{
      minBorrowing: 200000,
      maxBorrowing: 2000000
    }
  },
  // https://meteor.aihw.gov.au/content/index.phtml/itemId/429840
  "streetTypes": {
    "access": "ACCS",
    "alley": "ALLY",
    "alleyway": "ALWY",
    "amble": "AMBL",
    "approach": "APP",
    "arcade": "ARC",
    "arterial": "ARTL",
    "artery": "ARTY",
    "avenue": "AVE",
    "boulevard": "BVD",
    "banan": "BA",
    "bend": "BEND",
    "boardwalk": "BWLK",
    "brace": "BR",
    "brae": "BRAE",
    "break": "BRK",
    "brow": "BROW",
    "byway": "BYWY",
    "causeway": "CSWY",
    "centre": "CTR",
    "chase": "CH",
    "circle": "CIR",
    "bypass": "BYPA",
    "circuit": "CCT",
    "close": "CL",
    "corner": "CRN",
    "court": "CT",
    "crescent": "CRES",
    "cul-de-sac": "CDS",
    "circus": "CRCS",
    "concourse": "CON",
    "copse": "CPS",
    "courtyard": "CTYD",
    "cove": "COVE",
    "cross": "CRSS",
    "culdesac": "CSAC",
    "cutting": "CUTT",
    "drive": "DR",
    "dale": "DALE",
    "dip": "DIP",
    "driveway": "DVWY",
    "edge": "EDGE",
    "elbow": "ELB",
    "end": "END",
    "entrace": "ENT",
    "expressway": "EXP",
    "esplanade": "ESP",
    "estate": "EST",
    "fairway": "FAWY",
    "follow": "FOLW",
    "footway": "FTWY",
    "formation": "FORM",
    "freeway": "FWY",
    "frontage": "FRTG",
    "green": "GRN",
    "grove": "GR",
    "gap": "GAP",
    "gardens": "GDNS",
    "gate": "GTE",
    "glade": "GLDE",
    "glen": "GLEN",
    "grange": "GRA",
    "highway": "HWY",
    "heights": "HTS",
    "highroad": "HIRD",
    "hill": "HILL",
    "interchange": "INTG",
    "junction": "JNC",
    "key": "KEY",
    "lane": "LANE",
    "link": "LINK",
    "laneway": "LNWY",
    "line": "LINE",
    "lookout": "LKT",
    "loop": "LOOP",
    "mews": "MEWS",
    "mall": "MALL",
    "meander": "MNDR",
    "motorway": "MTWY",
    "nook": "NOOK",
    "outlook": "OTLK",
    "parade": "PDE",
    "place": "PL",
    "parkway": "PWY",
    "pass": "PASS",
    "passage": "PSGE",
    "path": "PATH",
    "pathway": "PWAY",
    "piazza": "PIAZ",
    "plaza": "PLZA",
    "pocket": "PKT",
    "point": "PNT",
    "port": "PORT",
    "promenade": "PROM",
    "quadrant": "QDRT",
    "quays": "QYS",
    "ridge": "RDGE",
    "ramble": "RMBL",
    "rest": "REST",
    "retreat": "RTT",
    "road": "RD",
    "rotary": "RTY",
    "route": "RTE",
    "row": "ROW",
    "rue": "RUE",
    "square": "SQ",
    "street": "ST",
    "serviceway": "SVWY",
    "shunt": "SHUN",
    "spur": "SPUR",
    "subway": "SBWY",
    "terrace": "TCE",
    "tarn": "TARN",
    "thoroughfare": "THFR",
    "throughway": "THRU",
    "tollway": "TLWY",
    "tolway": "TLW",
    "top": "TOP",
    "tor": "TOR",
    "track": "TRK",
    "trail": "TRL",
    "turn": "TURN",
    "underpass": "UPAS",
    "vale": "VALE",
    "viaduct": "VIAD",
    "view": "VIEW",
    "vista": "VISTA",
    "walk": "WALK",
    "walkway": "WKWY",
    "wharf": "WHRF",
    "wynd": "WYND",
    "wyd": "WYD",
    "way": "WAY",
    "wood": "WD",
    "waters": "WTRS",
    "waterway": "WTWY",

  },
  // "tradeCustomerRate": {
  //   "fxMargin": 1,
  //   "managementCharge": 1,
  //   "reducedManagementCharge": 0.5,
  //   "termCharge": 1.5,
  //   "reducedTermCharge": 1
  // },
  // replaced with environment.GrowFinanceGroupSalesforceId and environment.GrowFinanceGroupName
  // "growFinanceGroupSalesforceId": "0017F00002bIaTKQA0",
  // "growFinanceGroupName": "Dynamoney",
  "VehicleAssetCatIndex": '137',
  "CarsAssetTypeIndex": '11',
  "NewCarsAssetTypeIndex": '1',
  "OldCarsAssetTypeIndex": '2',
  'TruckAssetTypeIndex': '9',
  "LightTruckAssetTypeIndex": '7',
  "HeavyTruckAssetTypeIndex": '8',
  'ConsumerCarAssetTypeIndex': '1',
  'ConsumerCaravansAssetTypeIndex': '2',
  "growCompanyId": 1,
  "documentType": [ "AssetDocument", "FinancialDocument", "PersonalId", "BankStatementJSON", "BankStatementHTML" ],
  "dealStatus": [
    "incomplete",
    "submitted",
    "processing",
    "approved",
    "declined",
    "closed",
    "testing"
  ],
  "dealClassType": [
    "Mortgage",
    "Trade",
    "Asset"
  ],
  "documentTypeContainers": {
    "AssetDocument": "assets",
    "FinancialDocument": "financials",
    "PersonalId": "personal-id",
    "BankStatementJSON": "bs-json",
    "BankStatementHTML": "bs-html"
  },
  "accesslevel": {
    'admin': "Admin",
    'analyst': "Analyst",
    'internalbroker': "InternalBroker",
    'externalbroker': "ExternalBroker",
    'customer': "Customer",
    'companyadmin': "CompanyAdmin",
    'it': "IT",
    'credit' : "Credit",
    'operations' : "Operations",
    'operations24' : "Operations24",
    'settlement': "Settlement",
    'salesBDM' : 'SalesBDM',
    'salesAM' : 'SalesAM',
  },
  "loanType":[
    "Business",
    "Personal",
    "Asset Finance",
    "Sale Leaseback",
    "Equity Funding",
    "Other",
    "Rental",
    "Chattel",
    "Fast Track",
    "Fitout"
  ],
  "entityType": [
    "Unknown",
    "Proprietary",
    "Proprietary Limited",
    "No Liability",
    "Incorporated",
    "Limited",
    "Registered Body",
    "Corporation",
    "Business",
    "Sole Trader",
    "Partnership",
    "Trust"
  ],
  "assetType": ["Car", "Ute", "Van", "Truck", "Tractor", "Trailer", "Other"],
  "assetCategory": ["Passenger vehicles", "Trucks", "Trailers", "Yellow Goods", "Specialized"],
  "addressOwnership": ["Own", "Renting", "Boarding", "Family Residence"],
  "documentProcessType": [
    "Pre-disbursement",
    "Prelim approval"
  ],
  "documentRequestType": [
    "Certificate of currency",
    "Receipt of security deposit",
    "Registration(roadworthy)",
    "PPSR search",
    "PPSR registration",
    "DDR to be matching with provided bank statement",
    "Brokerage invoice",
    "Photo of compliance plate",
    "Photo of vehicle (RHS,LHS,front,back)",
    "IDV/AML check - 100 Point ID – Passport,  Drivers License and Medicare card (Signed and witnessed by JP or Lawyer)",
    "Executed rental agreement",
    "Vendor, Invoice & Asset verification",
    "Security check"
  ],
  "driverLicenceState": {
    "ACT": "Australian Capital Teritory",
    "NSW": "New South Wales",
    "NT": "Northern Territory",
    "QLD": "Queensland",
    "SA": "South Australia",
    "TSA": "Tasmania",
    "VIC": "Victoria",
    "WA": "Western Australia"
  },
  "maritalStatus": [
    "Single",
    "Married",
    "Defacto",
    "Divorced",
    "Other"
  ],
  "residentStatus": [
    "Unknown",
    "Permanent Resident",
    "Australian Citizen",
    "Working Visa",
    "Foreign Visa",
    "Other"
  ],
  "associationTypes": [
    "AdditionalApplicant",
    "Guarantor",
    "Trust",
    "Trustee",
    "MortgageManager",
    "Solicitor",
    "Valuer",
    "Other"
  ],
  "contractTemplateType": {
    "Rental - Company" : "Rental Agreement - Company",
    "Rental - Partnership" : "Rental Agreement - Partnership",
    "Rental - Trust" : "Rental Agreement - Trust",
    "Rental - Sole Trader": "Rental Agreement - Sole Trader",
    "Secured - Company" : "Rental Agreement - Company",
    "Secured - Partnership": "Rental Agreement - Company",
    "Secured - Trust": "Rental Agreement - Company",
    "Secured - Sole Trader": "Rental Agreement - Company"
  },
  'loanTerms': [
    12,24,36,48,60
  ],
  'businessLoanTerms': [
    6, 12, 18, 24, 30, 36
  ],
  'lgLoanTerms': [12,24,36,48,60,72,84],
  'revenueOptions': {
    'Below 75K': 75000,
    '75K to 150K': 150000,
    '150K to 250K': 250000,
    '250K to 2M': 2000000,
    '2M to 5M': 5000000,
    '5M to 50M': 50000000,
    'Above 50M': 75000000
  },
  'tradeRevenueOptions': {
    '$0 to $1M': 1000000,
    '$1M to $2M': 2000000,
    '$2M to $5M': 5000000,
    '$5M to $15M': 15000000,
    'Over $15M': 22000000
  },
  // "floorplanAssetTypes": [
  //   {"index": "1", "value": "Cars/Vans/Utes"},
  //   {"index": "2", "value": "Trucks"},
  //   {"index": "3", "value": "Trailers"},
  //   {"index": "4", "value": "Yellow Goods"},
  //   {"index": "5", "value": "Agricultural"},
  //   {"index": "6", "value": "Marine"},
  //   {"index": "7", "value": "Caravans/RVs"},
  //   {"index": "8", "value": "Motorcycles"},
  //   {"index": "9", "value": "Others"}
  // ],
  'assetCat':[
    {
      "cat": {
        "index": "137",
        "value": "Passenger Vehicles & Light Commercial"
      },
      "type": [
        {
          "index": "1",
          "value": "Passenger or light commercial <= 20 years"
        },
        {
          "index": "2",
          "value": "Passenger or light commercial > 20 years"
        }
      ]
    },
    {
      "cat": {
        "index": "132",
        "value": "Trucks, Trailers and Buses"
      },
      "type": [
        {
          "index": "1",
          "value": "Trailer <=4.5T GVM"
        },
        {
          "index": "2",
          "value": "Trailer > 4.5T GVM"
        },
        {
          "index": "3",
          "value": "Bus"
        },
        {
          "index": "4",
          "value": "Garbage Compactor"
        },
        {
          "index": "5",
          "value": "Trucks >4.5T Long Haul"
        },
        {
          "index": "6",
          "value": "Trucks >4.5T Short Haul"
        },
        {
          "index": "7",
          "value": "Trucks <= 4.5T GVM"
        },
        {
          "index": "8",
          "value": "Trucks > 4.5T GVM"
        },
        {
          "index": "9",
          "value": "Trucks"
        },
      ]
    },
    {
      'cat': {
        "index": "130",
        "value": "Yellow Goods and material handling"
      },
      'type': [
        {
          "value": "Dozer/Front End loader",
          "index": "1"
        },
        {
          "value": "Forklift",
          "index": "2"
        },
        {
          "value": "Skid steer loader",
          "index": "3"
        },
        {
          "value": "Crane",
          "index": "4"
        },
        {
          "value": "Tractor",
          "index": "5"
        },
        {
          "value": "Scissor Lift ",
          "index": "6"
        },
        {
          "value": "Excavator",
          "index": "7"
        }]
    },
    {
      "cat": {
        "index": "140",
        "value": "Caravans"
      },
      "type": [
        {
          "index": "1",
          "value": "Caravan"
        }
      ]
    },
    {
      "cat": {
        "index": "136",
        "value": "Medical"
      },
      "type": [
        {
          "index": "1",
          "value": "Medical - Other Equipment"
        },
        {
          "index": "2",
          "value": "Medical - X-Ray (portable)"
        },
        {
          "index": "3",
          "value": "Medical - X-Ray"
        },
        {
          "index": "4",
          "value": "Medical - CT Scan"
        },
        {
          "index": "5",
          "value": "Medical - Ultrasound"
        }
      ]
    },
    {
      "cat": {
        "index": "133",
        "value": "Plant & Equipment"
      },
      "type": [
        {
          "index": "1",
          "value": "Workshop"
        },
        {
          "index": "2",
          "value": "Manufacturing"
        },
        {
          "index": "3",
          "value": "Construction "
        },
        {
          "index": "4",
          "value": "Miling Machine "
        },
        {
          "index": "5",
          "value": "Road Rollers "
        },
        {
          "index": "6",
          "value": "Profilers "
        },
        {
          "index": "7",
          "value": "Recyclers "
        },
        {
          "index": "8",
          "value": "Pavers"
        },
        {
          "index": "11",
          "value": "Truck Mounted "
        },
        {
          "index": "12",
          "value": "Traffic Signals "
        },
        {
          "index": "13",
          "value": "Boom lift "
        },
        {
          "index": "14",
          "value": "Sweepers "
        },
        {
          "index": "15",
          "value": "Generators "
        },
        {
          "index": "16",
          "value": "Other Mobile Equipment"
        },
        {
          "index": "17",
          "value": "Other"
        }
      ]
    },
    {
      "cat": {
        "index": "106",
        "value": "Solar System"
      },
      "type": [
        {
          "index": "1",
          "value": "Buildings - Solar Panels"
        },
        {
          "index": "2",
          "value": "LG NeON panels"
        },
        {
          "index": "3",
          "value": "LG Mono X panels"
        },
        {
          "index": "4",
          "value": "Non-LG panels"
        }
      ]
    },
    {
      "cat": {
        "index": "134",
        "value": "IT/Office Technology"
      },
      "type": [
        {
          "index": "1",
          "value": "IT - Software"
        },
        {
          "index": "2",
          "value": "IT - Desktop Computers"
        },
        {
          "index": "3",
          "value": "IT - Laptop Computers"
        },
        {
          "index": "4",
          "value": "IT - Projectors"
        },
        {
          "index": "5",
          "value": "IT - Servers"
        },
        {
          "index": "6",
          "value": "IT - Server Room fitout"
        },
        {
          "index": "7",
          "value": "IT - Server Room raised floor"
        },
        {
          "index": "8",
          "value": "IT - Mobile Phone"
        },
        {
          "index": "9",
          "value": "IT - Accessories"
        },
        {
          "index": "10",
          "value": "IT - Networking (switch, router, etc)"
        },
        {
          "index": "11",
          "value": "IT - Printer/MFP/Photocopier"
        },
        {
          "index": "12",
          "value": "IT - Telephone hand sets"
        },
        {
          "index": "13",
          "value": "IT - Point of sale"
        },
        {
          "index": "14",
          "value": "IT - Other"
        }
      ]
    },
    {
      "cat": {
        "index": "135",
        "value": "Fitout"
      },
      "type": [
        {
          "index": "1",
          "value": "Fitout - Chairs"
        },
        {
          "index": "2",
          "value": "Fitout - Desks"
        },
        {
          "index": "3",
          "value": "Fitout - Partitions"
        },
        {
          "index": "4",
          "value": "Fitout - Table"
        },
        {
          "index": "5",
          "value": "Fitout - Other"
        },
        {
          "index": "6",
          "value": "Fitout - Lighting"
        },
        {
          "index": "7",
          "value": "Fitout - Carpets"
        },
        {
          "index": "8",
          "value": "Fitout - Signage"
        },
        {
          "index": "9",
          "value": "Fitout - Security Systems"
        },
        {
          "index": "10",
          "value": "Fitout - Display Racks / Shelving"
        },

      ]
    },
    {
      "cat": {
        "index": "142",
        "value": "Other Tertiary",
      },
      "type": [
        {
          "index": "1",
          "value": "Other Assets"
        }
      ]
    },
    {
      "cat": {
        "index": "139",
        "value": "Consumer Asset"
      },
      "type": [
        {
          "index": "1",
          "value": "Cars"
        },
        {
          "index": "2",
          "value": "Caravans"
        },
        {
          "index": "3",
          "value": "Motorbikes"
        },
        {
          "index": "4",
          "value": "Solar Systems"
        }
      ]
    },
    {
      "cat": {
        "index": "141",
        "value": "Payout"
      },
      "type": [
        {
          "index": "1",
          "value": "Payout"
        }
      ]
    }
  ],

  'oldassetCat': [
    {
      'cat' : {
        "index": "128",
        "value": 'Vehicle'
      },
      'type': [
        {
          "index": "11-1", //new cars vehicle
          "value": "Cars - Passenger or Light Commercial (<=20 years)",
          "manualEntry": false
        },
        {
          "index": "11-2", //old cars vehicle
          "value": "Cars - Passenger or Light Commercial (>20 years)",
          "manualEntry": true
        },
        {
          "index": "1",
          "value": "Trailer"
        },
        {
          "index": "2",
          "value": "Trailer > 4.5T GVM"
        },
        {
          "index": "3",
          "value": "Forklift"
        },
        {
          "index": "4",
          "value": "Skid steer loader"
        },
        {
          "index": "5",
          "value": "Crane"
        },
        {
          "index": "6",
          "value": "Bus"
        },
        {
          "index": "7",
          "value": "Trucks"
        },
        {
          "index": "8",
          "value": "Scooter"
        },
        {
          "index": "9",
          "value": "Other vehicle types"
        },
        {
          "index": "10",
          "value": "Dozer/Front End loader"
        },
        {
          "index": "12",
          "value": "Taxi"
        },
        {
          "index": "13",
          "value": "Tractor"
        },
        {
          "index": "14",
          "value": "Ride on mower"
        }
      ]
    },
    {
      'cat': {
        "index": "106",
        "value": 'Solar System'
      },
      'type': [
        {
          "index": "1",
          "value": "Buildings - Solar Panels"
        }
      ]
    },
    {
      'cat': {
        "index": "104",
        "value": 'Medical equipment'
      },
      'type': [
        {
          "index": "1",
          "value" : "Other Equipment"
        },
        {
          "index": "2",
          "value": "X-Ray (portable)"
        },
        {
          "index": "3",
          "value": "X-Ray"
        },
        {
          "index": "4",
          "value": "CT Scan"
        },
        {
          "index": "5",
          "value": "Ultrasound"
        }
      ]
    },
    {
      'cat': {
        "index": "103",
        "value": 'IT equipment'
      },
      'type': [
        {
          "index": "1",
          "value": "Equipment - Cameras, Lenses, Accessories"
        },
        {
          "index": "2",
          "value": "IT - Desktop Computers"
        },
        {
          "index": "3",
          "value": "IT - Laptop Computers"
        },
        {
          "index": "4",
          "value": "IT - Projectors"
        },
        {
          "index": "5",
          "value": "IT - Servers"
        },
        {
          "index": "6",
          "value": "IT - Server Room fitout"
        },
        {
          "index": "7",
          "value": "IT - Server Room raised floor"
        },
        {
          "index": "8",
          "value": "IT - Mobile Phone"
        },
        {
          "index": "9",
          "value": "IT - Accessories"
        },
        {
          "index": "10",
          "value": "IT - Other"
        },
        {
          "index": "11",
          "value": "IT - Networking (switch, router, etc)"
        },
        {
          "index": "12",
          "value": "Fitout - CCTV Camera / Monitor / Recorder"
        },
        {
          "index": "13",
          "value": "IT - GPS/Navigation"
        },
        {
          "index": "14",
          "value": "IT - Printer/MFP/Photocopier"
        },
        {
          "index": "15",
          "value": "IT - Telephony/PABX"
        },
        {
          "index": "16",
          "value": "IT - Telephone hand sets"
        },
        {
          "index": "17",
          "value": "IT - Point of sale"
        },
      ]
    },
    {
      "cat": {
        "index": "100",
        "value": "Catering equipment"
      },
      "type": [
        {
          "index": "1",
          "value" : "Fitout - Refrigerator"
        },
        {
          "index": "2",
          "value": "Buildings - Cool room",
        },
        {
          "index": "3",
          "value": "Manufacturing - Oven"
        }
      ]
    },
    {
      "cat": {
        "index": "101",
        "value": "Fitness equipment"
      },
      "type": [
        {
          "index": "1",
          "value": "Fitness Treadmill/Bicycle"
        },
        {
          "index": "2",
          "value": "Fitness Weights/Benches"
        },
        {
          "index": "3",
          "value": "Other Fitness/gym equipment"
        }
      ]
    },
    {
      "cat": {
        "index": "102",
        "value": "Fit-out"
      },
      "type": [
        {
          "index": "1",
          "value": "Fitout - Chairs"
        },
        {
          "index": "2",
          "value": "Fitout - Desks"
        },
        {
          "index": "3",
          "value": "Fitout - Garbage Bins"
        },
        {
          "index": "4",
          "value": "Buildings - Escalators"
        },
        {
          "index": "5",
          "value": "Fitout - Curtains and Draps"
        },
        {
          "index": "6",
          "value": "Fitout - Vinyl Floor Covering"
        },
        {
          "index": "7",
          "value": "Fitout - Partitions"
        },
        {
          "index": "8",
          "value": "Fitout - Ventilation Fans"
        },
        {
          "index": "9",
          "value": "Fitout - Installation costs"
        },
        {
          "index": "10",
          "value": "Fitout - Table"
        },
        {
          "index": "11",
          "value": "Fitout - Other"
        },
        {
          "index": "12",
          "value": "Buildings - Brick, Concrete"
        },
        {
          "index": "13",
          "value": "Buildings - Portable (off-site)"
        },
        {
          "index": "14",
          "value": "Buildings - Wooden structure"
        },
        {
          "index": "15",
          "value": "Buildings - Heating (electric, solar, gas)"
        },
        {
          "index": "16",
          "value": "Buildings - Heating (gas ducted)"
        },
        {
          "index": "17",
          "value": "Fitout - Lighting"
        },
        {
          "index": "18",
          "value": "Fitout - Carpets"
        },
        {
          "index": "19",
          "value": "Fitout - Drink dispensing machines"
        },
        {
          "index": "20",
          "value": "Fitout - Extinguishers"
        },
        {
          "index": "21",
          "value": "Fitout - Signage"
        },
        {
          "index": "22",
          "value": "Fitout - Vending machine"
        },
        {
          "index": "23",
          "value": "Fitout - Automatic Doors"
        },
        {
          "index": "24",
          "value": "Fitout - Security Systems"
        },
        {
          "index": "25",
          "value": "Fitout - Display Racks / Shelving"
        },
        {
          "index": "26",
          "value": "Fitout - Boardroom Table"
        },
        {
          "index": "27",
          "value": "Fitout - Warehouse racking"
        }
      ]
    },
    {
      "cat": {
        "index": "99",
        "value": "Software",
      },
      "type": [
        {
          "index": "1",
          "value": "IT - Software"
        }
      ]
    },
    {
      'cat': {
        "index": "127",
        "value": 'Other Equipment'
      },
      'type': [
        {
          "index": "1",
          "value": "Equipment - Air Compressors"
        },
        {
          "index": "2",
          "value": "Equipment - Automatic Teller Machine"
        },
        {
          "index": "3",
          "value": "Equipment - Waste Compactors"
        },
        {
          "index": "4",
          "value": "Equipment - Floor Scrubbers (ride-on)"
        },
        {
          "index": "5",
          "value": "Equipment - Shredders"
        },
        {
          "index": "6",
          "value": "Equipment - Sewing Machines"
        },
        {
          "index": "7",
          "value": "Equipment - Vacuum cleaners"
        },
        {
          "index": "8",
          "value": "Equipment - Musical Instrument"
        },
        {
          "index": "9",
          "value": "Equipment - Enveloping machine"
        },
        {
          "index": "10",
          "value": "Equipment - Whiteboards"
        },
        {
          "index": "11",
          "value": "Equipment - Packaging Machines"
        },
        {
          "index": "12",
          "value": "Equipment - Floor Scrubbers"
        },
        {
          "index": "13",
          "value": "Equipment - Laminators"
        },
        {
          "index": "14",
          "value": "Equipment - PA Speakers, Amplifiers, etc"
        },
        {
          "index": "15",
          "value": "Equipment - Scales/Cash Registers"
        },
        {
          "index": "16",
          "value": "Equipment - Heater"
        },
        {
          "index": "17",
          "value": "Equipment - Letter folding/Franking"
        },
        {
          "index": "18",
          "value": "Equipment - Other"
        },
        {
          "index": "19",
          "value": "Equipment - Two Way Radio"
        },
        {
          "index": "20",
          "value": "Buildings - Power Management Units"
        },
        {
          "index": "21",
          "value": "Manufacturing - Vacuum packing"
        },
        {
          "index": "22",
          "value": "Manufacturing - Labelling machine"
        },
        {
          "index": "23",
          "value": "Manufacturing - Shirnk Wrappers"
        },
        {
          "index": "24",
          "value": "Manufacturing - Logging Plant"
        },
        {
          "index": "25",
          "value": "Equipment - Vacuum cleaners (ride-on)"
        },
        {
          "index": "26",
          "value": "Equipment - Audio/PA"
        },
        {
          "index": "27",
          "value": "Equipment - Other hardware machinery"
        }
      ]
    }
  ],
  'industry': {
    'divisions': {
      "A": {
        'name': "Agriculture, Forestry and Fishing",
        'subdivisions': {
          '011': 'Nursery and Floriculture Production',
          '012': 'Mushroom and Vegetable Growing',
          '013': 'Fruit and Tree Nut Growing',
          '014': 'Sheep, Beef Cattle and Grain Farming',
          '015': 'Other Crop Growing',
          '016': 'Dairy Cattle Farming',
          '017': 'Poultry Farming',
          '018': 'Deer Farming',
          '019': 'Other Livestock Farming',
          '020': 'Aquaculture',
          '030': 'Forestry and Logging',
          '041': 'Fishing',
          '042': 'Hunting and Trapping',
          '051': 'Forestry Support Services',
          '052': 'Agriculture and Fishing Support Services'
        }
      },
      "B": {
        'name': "Mining",
        'subdivisions': {
          '060':'Coal Mining',
          '070':'Oil and Gas Extraction',
          '080':'Metal Ore Mining',
          '091':'Construction Material Mining',
          '099':'Other Non-Metallic Mineral Mining and Quarrying',
          '101':'Exploration',
          '109':'Other Mining Support Services'
        }
      },
      "C": {
        'name': "Manufacturing",
        'subdivisions': {
          '111':'Meat and Meat Product Manufacturing',
          '112':'Seafood Processing',
          '113':'Dairy Product Manufacturing',
          '114':'Fruit and Vegetable Processing',
          '115':'Oil and Fat Manufacturing',
          '116':'Grain Mill and Cereal Product Manufacturing',
          '117':'Bakery Product Manufacturing',
          '118':'Sugar and Confectionery Manufacturing',
          '119':'Other Food Product Manufacturing',
          '121':'Beverage Manufacturing',
          '122':'Cigarette and Tobacco Product Manufacturing',
          '131':'Textile Manufacturing',
          '132':'Leather Tanning, Fur Dressing and Leather Product Manufacturing',
          '133':'Textile Product Manufacturing',
          '134':'Knitted Product Manufacturing',
          '135':'Clothing and Footwear Manufacturing',
          '141':'Log Sawmilling and Timber Dressing',
          '149':'Other Wood Product Manufacturing',
          '151':'Pulp, Paper and Paperboard Manufacturing',
          '152':'Converted Paper Product Manufacturing',
          '161':'Printing and Printing Support Services',
          '162':'Reproduction of Recorded Media',
          '170':'Petroleum and Coal Product Manufacturing',
          '181':'Basic Chemical Manufacturing',
          '182':'Basic Polymer Manufacturing',
          '183':'Fertiliser and Pesticide Manufacturing',
          '184':'Pharmaceutical and Medicinal Product Manufacturing',
          '185':'Cleaning Compound and Toiletry Preparation Manufacturing',
          '189':'Other Basic Chemical Product Manufacturing',
          '191':'Polymer Product Manufacturing',
          '192':'Natural Rubber Product Manufacturing',
          '201':'Glass and Glass Product Manufacturing',
          '202':'Ceramic Product Manufacturing',
          '203':'Cement, Lime, Plaster and Concrete Product Manufacturing',
          '209':'Other Non-Metallic Mineral Product Manufacturing',
          '211':'Basic Ferrous Metal Manufacturing',
          '212':'Basic Ferrous Metal Product Manufacturing',
          '213':'Basic Non-Ferrous Metal Manufacturing',
          '214':'Basic Non-Ferrous Metal Product Manufacturing',
          '221':'Iron and Steel Forging',
          '222':'Structural Metal Product Manufacturing',
          '223':'Metal Container Manufacturing',
          '224':'Sheet Metal Product Manufacturing (except Metal Structural and Container Products)',
          '229':'Other Fabricated Metal Product Manufacturing',
          '231':'Motor Vehicle and Motor Vehicle Part Manufacturing',
          '239':'Other Transport Equipment Manufacturing',
          '241':'Professional and Scientific Equipment Manufacturing',
          '242':'Computer and Electronic Equipment Manufacturing',
          '243':'Electrical Equipment Manufacturing',
          '244':'Domestic Appliance Manufacturing',
          '245':'Pump, Compressor, Heating and Ventilation Equipment Manufacturing',
          '246':'Specialised Machinery and Equipment Manufacturing',
          '249':'Other Machinery and Equipment Manufacturing',
          '251':'Furniture Manufacturing',
          '259':'Other Manufacturing'
        }
      },
      "D": {
        'name': "Electricity, Gas, Water and Waste Services",
        'subdivisions': {
          '261':'Electricity Generation',
          '262':'Electricity Transmission',
          '263':'Electricity Distribution',
          '264':'On Selling Electricity and Electricity Market Operation',
          '270':'Gas Supply',
          '281':'Water Supply, Sewerage and Drainage Services',
          '291':'Waste Collection Services',
          '292':'Waste Treatment, Disposal and Remediation Services'
        }
      },
      "E": {
        "name": "Construction",
        "subdivisions":{
          '301':'Residential Building Construction',
          '302':'Non-Residential Building Construction',
          '310':'Heavy and Civil Engineering Construction',
          '321':'Land Development and Site Preparation Services',
          '322':'Building Structure Services',
          '323':'Building Installation Services',
          '324':'Building Completion Services',
          '329':'Other Construction Services'
        }
      },
      "F": {
        "name": "Wholesale Trade",
        "subdivisions": {
          '331':'Agricultural Product Wholesaling',
          '332':'Mineral, Metal and Chemical Wholesaling',
          '333':'Timber and Hardware Goods Wholesaling',
          '341':'Specialised Industrial Machinery and Equipment Wholesaling',
          '349':'Other Machinery and Equipment Wholesaling',
          '350':'Motor Vehicle and Motor Vehicle Parts Wholesaling',
          '360':'Grocery, Liquor and Tobacco Product Wholesaling',
          '371':'Textile, Clothing and Footwear Wholesaling',
          '372':'Pharmaceutical and Toiletry Goods Wholesaling',
          '373':'Furniture, Floor Covering and Other Goods Wholesaling',
          '380':'Commission-Based Wholesaling'
        }
      },
      "G": {
        "name": "Retail Trade",
        "subdivisions": {
          '391':'Motor Vehicle Retailing',
          '392':'Motor Vehicle Parts and Tyre Retailing',
          '400':'Fuel Retailing',
          '411':'Supermarket and Grocery Stores',
          '412':'Specialised Food Retailing',
          '421':'Furniture, Floor Coverings, Houseware and Textile Goods Retailing',
          '422':'Electrical and Electronic Goods Retailing',
          '423':'Hardware, Building and Garden Supplies Retailing',
          '424':'Recreational Goods Retailing',
          '425':'Clothing, Footwear and Personal Accessory Retailing',
          '426':'Department Stores',
          '427':'Pharmaceutical and Other Store-Based Retailing',
          '431':'Non-Store Retailing',
          '432':'Retail Commission-Based Buying and/or Selling'
        }
      },
      "H": {
        "name": "Accommodation and Food Services",
        "subdivisions": {
          '440':'Accommodation',
          '451':'Cafes, Restaurants and Takeaway Food Services',
          '452':'Pubs, Taverns and Bars',
          '453':'Clubs (Hospitality)'
        }
      },
      "I": {
        "name": "Transport, Postal and Warehousing",
        "subdivisions": {
          '461':'Road Freight Transport',
          '462':'Road Passenger Transport',
          '471':'Rail Freight Transport',
          '472':'Rail Passenger Transport',
          '481':'Water Freight Transport',
          '482':'Water Passenger Transport',
          '490':'Air and Space Transport',
          '501':'Scenic and Sightseeing Transport',
          '502':'Pipeline and Other Transport',
          '510':'Postal and Courier Pick-up and Delivery Services',
          '521':'Water Transport Support Services',
          '522':'Airport Operations and Other Air Transport Support Services',
          '529':'Other Transport Support Services',
          '530':'Warehousing and Storage Services'
        }
      },
      "J": {
        "name": "Information Media and Telecommunications",
        "subdivisions": {
          '541':'Newspaper, Periodical, Book and Directory Publishing',
          '542':'Software Publishing',
          '551':'Motion Picture and Video Activities',
          '552':'Sound Recording and Music Publishing',
          '561':'Radio Broadcasting',
          '562':'Television Broadcasting',
          '570':'Internet Publishing and Broadcasting',
          '580':'Telecommunications Services',
          '591':'Internet Service Providers and Web Search Portals',
          '592':'Data Processing, Web Hosting and Electronic Information Storage Services',
          '601':'Libraries and Archives',
          '602':'Other Information Services'
        }
      },
      "K": {
        "name": "Financial and Insurance Services",
        "subdivisions": {
          '621':'Central Banking',
          '622':'Depository Financial Intermediation',
          '623':'Non-Depository Financing',
          '624':'Financial Asset Investing',
          '631':'Life Insurance',
          '632':'Health and General Insurance',
          '633':'Superannuation Funds',
          '641':'Auxiliary Finance and Investment Services',
          '642':'Auxiliary Insurance Services'
        }
      },
      "L": {
        "name": "Rental, Hiring and Real Estate Services",
        "subdivisions" : {
          '661': 'Motor Vehicle and Transport Equipment Rental and Hiring',
          '662': 'Farm Animal and Bloodstock Leasing',
          '663': 'Other Goods and Equipment Rental and Hiring',
          '664': 'Non-Financial Intangible Assets (Except Copyrights) Leasing',
          '671': 'Property Operators',
          '672': 'Real Estate Services'
        }
      },
      "M": {
        "name": "Professional, Scientific and Technical Services",
        "subdivisions":{
          '691':'Scientific Research Services',
          '692':'Architectural, Engineering and Technical Services',
          '693':'Legal and Accounting Services',
          '694':'Advertising Services',
          '695':'Market Research and Statistical Services',
          '696':'Management and Related Consulting Services',
          '697':'Veterinary Services',
          '699':'Other Professional, Scientific and Technical Services',
          '700':'Computer System Design and Related Services'
        }
      },
      "N": {
        "name": "Administrative and Support Services",
        "subdivisions": {
          '721':'Employment Services',
          '722':'Travel Agency and Tour Arrangement Services',
          '729':'Other Administrative Services',
          '731':'Building Cleaning, Pest Control and Gardening Services',
          '732':'Packaging Services'
        }
      },
      "O": {
        "name": "Public Administration and Safety",
        "subdivisions": {
          '751':'Central Government Administration',
          '752':'State Government Administration',
          '753':'Local Government Administration',
          '754':'Justice',
          '755':'Government Representation',
          '760':'Defence',
          '771':'Public Order and Safety Services',
          '772':'Regulatory Services'
        }
      },
      "P": {
        "name": "Education and Training",
        "subdivisions": {
          '801':'Preschool Education',
          '802':'School Education',
          '810':'Tertiary Education',
          '821':'Adult, Community and Other Education',
          '822':'Educational Support Services'
        }
      },
      "Q": {
        "name": "Health Care and Social Assistance",
        "subdivisions": {
          '840':'Hospitals',
          '851':'Medical Services',
          '852':'Pathology and Diagnostic Imaging Services',
          '853':'Allied Health Services',
          '859':'Other Health Care Services',
          '860':'Residential Care Services',
          '871':'Child Care Services',
          '879':'Other Social Assistance Services'
        }
      },
      "R": {
        "name": "Arts and Recreation Services",
        "subdivisions": {
          '891':'Museum Operation',
          '892':'Parks and Gardens Operations',
          '900':'Creative and Performing Arts Activities',
          '911':'Sports and Physical Recreation Activities',
          '912':'Horse and Dog Racing Activities',
          '913':'Amusement and Other Recreation Activities',
          '920':'Gambling Activities'
        }
      },
      "S": {
        "name": "Other Services",
        "subdivisions": {
          '941':'Automotive Repair and Maintenance',
          '942':'Machinery and Equipment Repair and Maintenance',
          '949':'Other Repair and Maintenance',
          '951':'Personal Care Services',
          '952':'Funeral, Crematorium and Cemetery Services',
          '953':'Other Personal Services',
          '954':'Religious Services',
          '955':'Civic, Professional and Other Interest Group Services',
          '960':'Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities of Households for Own Use'

        }
      }
    }
  },
  'stateList': {
    'NSW': 'New South Wales',
    'QLD': 'Queensland',
    'SA': 'South Australia',
    'TAS': 'Tasmania',
    'VIC': 'Victoria',
    'WA': 'Western Australia',
    'NT': 'Northern Territory',
    'ACT': 'Australian Capital Territory'
  },
  'streetTypeList': {
    "Access": "ACC",
    "Alley": "Al",
    "Alleyway": "Awy",
    "Amble": "Amb",
    "Anchorage": "Anc",
    "Approach": "App",
    "Arcade": "Arc",
    "Artery": "Art",
    "Avenue": "Ave",
    "Bank": "Bk",
    "Basin": "Bas",
    "Bay": "By",
    "Beach": "Bch",
    "Belt": "Bt",
    "Bend": "Bnd",
    "Block": "Blk",
    "Boulevard": "Blv",
    "Bowl": "Bwl",
    "Brace": "Br",
    "Brae": "Bra",
    "Break": "Brk",
    "Briars": "Bs",
    "Bridge": "Brg",
    "Broadway": "Bwy",
    "Brow": "Brw",
    "Bypass": "Bps",
    "Byway": "Byw",
    "Causeway": "Cwy",
    "Centre": "Ctr",
    "Centreway": "Cnwy",
    "Chase": "Ch",
    "Circle": "Cir",
    "Circlet": "Clt",
    "Circuit": "Cct",
    "Circus": "Crc",
    "Close": "Cl",
    "Colonnade": "Cld",
    "Common": "Cmn",
    "Concourse": "Cnc",
    "Copse": "Cps",
    "Corner": "Cnr",
    "Corso": "Cso",
    "Course": "Crse",
    "Court": "Ct",
    "Courts": "Cts",
    "Courtyard": "Cyd",
    "Cove": "Cve",
    "Crescent": "Crs",
    "Crest": "Cs",
    "Croft": "Crft",
    "Cross": "Crss",
    "Crossing": "Csg",
    "Crossroad": "Crd",
    "Crossway": "Crw",
    "Cruiseway": "Csw",
    "Cul-de-sac": "Cds",
    "Cutting": "Cut",
    "Dale": "Dal",
    "Dell": "Dl",
    "Deviation": "Dvn",
    "Dip": "Dip",
    "Distributor": "Dtr",
    "Downs": "Dn",
    "Drive": "Dr",
    "Driveway": "Dry",
    "East": "Ea",
    "Edge": "Ege",
    "Elbow": "Elb",
    "Elm": "Elm",
    "End": "Ed",
    "Entrance": "Ent",
    "Esplanade": "Esp",
    "Estate": "Est",
    "Expressway": "Exp",
    "Extension": "Extn",
    "Fairway": "Fay",
    "Fire Track": "Ftk",
    "Firetrail": "Ftl",
    "Flat": "Flt",
    "Flats": "Flats",
    "Follow": "Flw",
    "Footway": "Ftw",
    "Foreshore": "Fsr",
    "Formation": "Fmn",
    "Freeway": "Fwy",
    "Front": "Frt",
    "Frontage": "Fr",
    "Gap": "Gap",
    "Garden": "Gdn",
    "Gardens": "Gds",
    "Gate": "Gte",
    "Gates": "Gts",
    "Glade": "Gl",
    "Glen": "Gln",
    "Grange": "Gra",
    "Great": "Ge",
    "Green": "Grn",
    "Ground": "Grd",
    "Grove": "Gve",
    "Gully": "Gly",
    "Haven": "Havn",
    "Head": "Head",
    "Heath": "Hth",
    "Heights": "Hts",
    "Highroad": "Hrd",
    "Highway": "Hwy",
    "Hill": "Hl",
    "Hub": "Hub",
    "Interchange": "Itc",
    "Intersection": "Its",
    "Island": "Is",
    "Junction": "Jn",
    "Key": "Key",
    "Landing": "Ldg",
    "Lane": "La",
    "Laneway": "Lwy",
    "Largo": "Largo",
    "Lees": "Lee",
    "Line": "Li",
    "Link": "Lk",
    "Little": "Lt",
    "Loch": "Loch",
    "Lookout": "Lkt",
    "Loop": "Lp",
    "Lower": "Lwr",
    "Mall": "Ma",
    "Mead": "Md",
    "Meadows": "Mw",
    "Meander": "Mdr",
    "Mew": "Mew",
    "Mews": "Mws",
    "Mile": "Ml",
    "Motorway": "Mwy",
    "Mount": "Mt",
    "Nook": "Nk",
    "Outlook": "Out",
    "Parade": "Pde",
    "Park": "Pk",
    "Parklands": "Pkld",
    "Parkway": "Pwy",
    "Part": "Prt",
    "Pass": "Pss",
    "Path": "Pth",
    "Pathway": "Phwy",
    "Piazza": "Pza",
    "Place": "Pl",
    "Plateau": "Plat",
    "Plaza": "Plz",
    "Pocket": "Pkt",
    "Point": "Pnt",
    "Points": "Pts",
    "Port": "Pt",
    "Promenade": "Prm",
    "Pursuit": "Purs",
    "Quad": "Qud",
    "Quadrangle": "Qdg",
    "Quadrant": "Qd",
    "Quay": "Qy",
    "Quays": "Qys",
    "Ramble": "Ra",
    "Ramp": "Rmp",
    "Range": "Rge",
    "Reach": "Rch",
    "Reserve": "Res",
    "Rest": "Rst",
    "Ret": "Ret",
    "Retreat": "Rt",
    "Return": "Retn",
    "Ride": "Rid",
    "Ridge": "Rdg",
    "Ridgeway": "Rdw",
    "Right of Way": "Rtw",
    "Ring": "Rng",
    "Rise": "Ri",
    "River": "Rvr",
    "Riverway": "Rvw",
    "Riviera": "Rva",
    "Road": "Rd",
    "Roads": "Rds",
    "Roadside": "Rsd",
    "Roadway": "Rdy",
    "Ronde": "Rde",
    "Rosebowl": "Rbl",
    "Rotary": "Rty",
    "Round": "Rnd",
    "Route": "Rte",
    "Row": "Row",
    "Rue": "Rue",
    "Run": "Run",
    "Service Way": "Swy",
    "Shopping Centre": "Shop",
    "Siding": "Sdg",
    "Slope": "Slp",
    "Sound": "Snd",
    "Spur": "Spr",
    "Square": "Sq",
    "Stairs": "Sta",
    "State Highway": "Shw",
    "Steps": "Stp",
    "Strait": "Strait",
    "Strand": "Stra",
    "Street": "St",
    "Strip": "Sp",
    "Subway": "Sy",
    "Summit": "Smmt",
    "Tailings": "Ta",
    "Tarn": "Tarn",
    "Terrace": "Tce",
    "Thoroughfare": "Thf",
    "Tollway": "Twy",
    "Top": "Top",
    "Tor": "Tor",
    "Towers": "Twr",
    "Track": "Tr",
    "Trail": "Trl",
    "Trailer": "Trr",
    "Tramway": "Ty",
    "Triangle": "Tri",
    "Trunkway": "Tkw",
    "Tunnel": "Tn",
    "Turn": "Tur",
    "Underpass": "Unp",
    "Upper": "Upr",
    "Vale": "Val",
    "Valley": "Vy",
    "Viaduct": "Vdt",
    "View": "Vw",
    "Village": "Ve",
    "Villas": "Vlls",
    "Vista": "Vst",
    "Wade": "Wd",
    "Walk": "Wk",
    "Walkway": "Wky",
    "Waters": "Waters",
    "Way": "Way",
    "West": "Wt",
    "Wharf": "Wf",
    "Wood": "Wood",
    "Wynd": "Wnd",
    "Yard": "Yrd"
  },
  'insurer':[
    "360 Accident & Health Pty Ltd",
    "360 Commercial Pty Ltd",
    "360 Construction and Engineering Pty Ltd",
    "360 Cyber Pty Ltd",
    "Achmea Farm Insurance",
    "AFA Insurance Pty Ltd",
    "Agile Underwriting Services Pty Ltd",
    "AHI",
    "AIA Australia Limited",
    "AIB Insurance Brokers",
    "AIG Australia Limited",
    "Allianz Australia Insurance Limited",
    "Allied World Assurance Company Ltd",
    "Ansvar Insurance Limited",
    "Aon Risk Services Australia Limited",
    "Arch Underwriting",
    "Arthur J. Gallagher & Co (Aust) Limited",
    "ATL Insurance Group Pty Ltd",
    "Atradius Credit Insurance",
    "AUB Hospitality Pty Ltd",
    "Avant Insurance Limited",
    "Aviva PLC",
    "Berkley Insurance Australia",
    "Berkshire Hathaway Specialty Insurance",
    "BHSI",
    "Blue Zebra Insurance Pty Ltd",
    "Broker Administration Fee",
    "Brooklyn Underwriting Pty Ltd",
    "Catholic Church Insurance Limited",
    "Certain Underwriters at Lloyds of London",
    "CFC Underwriting Ltd",
    "CGU Insurance Limited",
    "CGU Workers Compensation",
    "Chase Underwriting Pty Ltd",
    "CHU Underwriting Agencies Pty Ltd",
    "Chubb Insurance Australia Limited",
    "Club Marine Limited",
    "Coast Insurance Pty Ltd",
    "Co-insurance",
    "Community Underwriting Agency Pty Limited",
    "Dawes Underwriting Australia Pty Ltd",
    "Dual Australia Pty Ltd",
    "Eclipse Business Insurance Pty Ltd",
    "Emergence Insurance Pty Ltd",
    "Emjay Insurance Brokers Pty Ltd",
    "Endurance Worldwide",
    "Ensurance Australia Pty Ltd",
    "Ensurance Limited (prev.TK Specialty Risks Pty )",
    "Epsilon Underwriting Agencies Pty Ltd",
    "Everest Risk Group",
    "Factory Mutual Insurance Company",
    "FM GLOBAL",
    "FTA Insurance Pty Ltd",
    "GARD Insurance",
    "GIO",
    "Global Transport & Automotive Insurance",
    "HDI Global SE, Australia",
    "HDI GLOBAL SPECIALTY SE - AUS",
    "Heavy Motor Insurance Australia",
    "Hollards Commercial Insurance Pty Ltd",
    "icare",
    "Insurance Australia Limited (T/as CGU)",
    "Insurance Australia Limited (T/as Lumley)",
    "Insurance Commission of WA",
    "Integrity Life",
    "Keystone Underwriting Australia",
    "LAWCOVER NSW",
    "Liberty Specialty Markets",
    "LION UNDERWRITING PTY LTD",
    "LLoyds Of London",
    "Lockton Companies LLP",
    "Mansions Of Australia Pty Ltd",
    "Marsh Pty Ltd",
    "MB Insurance Group Pty Ltd",
    "MECON Insurance Pty Ltd",
    "MetLife Insurance Limited",
    "Miramar Underwriting Agency Pty Ltd",
    "Mitsui Sumitomo Insurance Company Ltd",
    "Mutua de Seguros de Armadores de Buques de Pesca d",
    "National Credit Insurance (Brokers) Pty Ltd",
    "National Transport Insurance Ltd",
    "Nautilus Marine Insurance Agency Pty Ltd",
    "Newline Australia Insurance Pty. Ltd.",
    "NM Insurance Pty Ltd",
    "Nova Underwriting Pty Ltd",
    "Our Ark Mutual Ltd",
    "OzPrize & Weather Insurance Specialists Pty Ltd",
    "PACIFIC INDEMNITY",
    "Pen Underwriting Pty Ltd",
    "ProCover Underwriting Agency",
    "Professional Risk Underwriting Pty Ltd",
    "Protecsure Pty Ltd",
    "Proteus Marine Insurance",
    "QBE Australia - Surety",
    "QBE Commercial",
    "QBE Insurance (Australia) Ltd",
    "QBE Trade Credit",
    "Quanta Insurance Group Pty Ltd",
    "Queensland Government",
    "Relm Insurance Ltd",
    "Return to Work SA",
    "Richard Oliver Underwriting Managers",
    "Rising Edge Ltd",
    "RM Brokers Limited - UK",
    "Shipowners Asia Pte Ltd",
    "Solution Underwriting Agency Pty Ltd",
    "Sompo Japan Insurance Inc",
    "Specialist Underwriting Agencies",
    "Sportscover Australia Pty Ltd",
    "St George Underwriting Agency",
    "Starr Insurance Companies",
    "Starr Underwriting Agents (Asia) Ltd",
    "Strata Community Insurance",
    "Strata Unit Underwriters",
    "SURA MARINE",
    "SURA TECHNOLOGY RISKS PTY LTD",
    "Swiss Re Asia Pte. Ltd",
    "Swiss Re International SE, Australia Branch",
    "The Barn Underwriting Agency Pty Ltd",
    "The Hollard Insurance Company Pty Ltd",
    "TK Specialty Risks Pty Ltd",
    "Tokio Marine & Nichido Fire Insurance Co Ltd",
    "Tokio Marine HCC International",
    "Underwriting Agencies of Australia Pty Ltd",
    "Vero Insurance Limited",
    "Volante Global",
    "Wellington Underwriting Agency Pty Ltd",
    "Woodina Underwriting Agency Pty Ltd",
    "Work Cover Queensland",
    "WORKSAFE VICTORIA",
   " XL Insurance Company SE",
    "YOUI",
    "Zurich Australian Insurance Ltd"
  ],
  'coverTypes': [
      {
        "InsuranceProductId": 1,
        "ProductName": "Aviation"
      },
      {
        "InsuranceProductId": 2,
        "ProductName": "Bond Insurance"
      },
      {
        "InsuranceProductId": 3,
        "ProductName": "Broker Fees"
      },
      {
        "InsuranceProductId": 4,
        "ProductName": "Burglary and/or Theft"
      },
      {
        "InsuranceProductId": 5,
        "ProductName": "Business Interruption"
      },
      {
        "InsuranceProductId": 6,
        "ProductName": "Business Pack"
      },
      {
        "InsuranceProductId": 7,
        "ProductName": "Commercial Package"
      },
      {
        "InsuranceProductId": 8,
        "ProductName": "Compulsory 3rd Party"
      },
      {
        "InsuranceProductId": 9,
        "ProductName": "Computer / Cyber"
      },
      {
        "InsuranceProductId": 10,
        "ProductName": "Container Liability"
      },
      {
        "InsuranceProductId": 11,
        "ProductName": "Contract Works / Risks"
      },
      {
        "InsuranceProductId": 12,
        "ProductName": "Copyright Infringement"
      },
      {
        "InsuranceProductId": 13,
        "ProductName": "Credit Insurance - Cancellable"
      },
      {
        "InsuranceProductId": 14,
        "ProductName": "Credit Insurance - Non-Cancellable"
      },
      {
        "InsuranceProductId": 15,
        "ProductName": "Customs & Payment Bonds"
      },
      {
        "InsuranceProductId": 16,
        "ProductName": "Directors & Officers"
      },
      {
        "InsuranceProductId": 17,
        "ProductName": "Employment Practices Liability"
      },
      {
        "InsuranceProductId": 18,
        "ProductName": "Environmental Impairment"
      },
      {
        "InsuranceProductId": 19,
        "ProductName": "Export credit / Political risk"
      },
      {
        "InsuranceProductId": 20,
        "ProductName": "Fidelity Guarantee"
      },
      {
        "InsuranceProductId": 21,
        "ProductName": "Fine Arts"
      },
      {
        "InsuranceProductId": 22,
        "ProductName": "Fire"
      },
      {
        "InsuranceProductId": 23,
        "ProductName": "General Property"
      },
      {
        "InsuranceProductId": 24,
        "ProductName": "Glass"
      },
      {
        "InsuranceProductId": 25,
        "ProductName": "House Holders"
      },
      {
        "InsuranceProductId": 26,
        "ProductName": "Industrial Special Risks"
      },
      {
        "InsuranceProductId": 27,
        "ProductName": "Jewellers Block"
      },
      {
        "InsuranceProductId": 28,
        "ProductName": "Keyman Insurance"
      },
      {
        "InsuranceProductId": 29,
        "ProductName": "Kidnap & Workplace Violence"
      },
      {
        "InsuranceProductId": 30,
        "ProductName": "Life Science"
      },
      {
        "InsuranceProductId": 31,
        "ProductName": "Machinery / Plant / Engineering"
      },
      {
        "InsuranceProductId": 32,
        "ProductName": "Marine"
      },
      {
        "InsuranceProductId": 33,
        "ProductName": "Marine - Pleasure Craft"
      },
      {
        "InsuranceProductId": 34,
        "ProductName": "Marine Hull - Commercial"
      },
      {
        "InsuranceProductId": 35,
        "ProductName": "Marine Liability"
      },
      {
        "InsuranceProductId": 36,
        "ProductName": "Money"
      },
      {
        "InsuranceProductId": 37,
        "ProductName": "Motor Vehicle - Burner"
      },
      {
        "InsuranceProductId": 38,
        "ProductName": "Motor Vehicle - Cancellable"
      },
      {
        "InsuranceProductId": 39,
        "ProductName": "Other - Cancellable"
      },
      {
        "InsuranceProductId": 40,
        "ProductName": "Other – Non-Cancellable"
      },
      {
        "InsuranceProductId": 41,
        "ProductName": "Patent Infringement"
      },
      {
        "InsuranceProductId": 42,
        "ProductName": "Personal Accident"
      },
      {
        "InsuranceProductId": 43,
        "ProductName": "Product Recall"
      },
      {
        "InsuranceProductId": 44,
        "ProductName": "Products Liability"
      },
      {
        "InsuranceProductId": 45,
        "ProductName": "Professional Fee"
      },
      {
        "InsuranceProductId": 46,
        "ProductName": "Professional Indemnity"
      },
      {
        "InsuranceProductId": 47,
        "ProductName": "Professional Indemnity – Non-Cancellable"
      },
      {
        "InsuranceProductId": 48,
        "ProductName": "Public Liability"
      },
      {
        "InsuranceProductId": 49,
        "ProductName": "Rural / Farm"
      },
      {
        "InsuranceProductId": 50,
        "ProductName": "Special Contingency"
      },
      {
        "InsuranceProductId": 51,
        "ProductName": "Special Risks"
      },
      {
        "InsuranceProductId": 52,
        "ProductName": "Statutory Liability"
      },
      {
        "InsuranceProductId": 53,
        "ProductName": "Taxation Audit"
      },
      {
        "InsuranceProductId": 54,
        "ProductName": "Taxi Insurance"
      },
      {
        "InsuranceProductId": 55,
        "ProductName": "Trade Credit Insurance"
      },
      {
        "InsuranceProductId": 56,
        "ProductName": "Travel Insurance"
      },
      {
        "InsuranceProductId": 57,
        "ProductName": "Umbrella Liability"
      },
      {
        "InsuranceProductId": 58,
        "ProductName": "Valuables"
      },
      {
        "InsuranceProductId": 59,
        "ProductName": "Warranty"
      },
      {
        "InsuranceProductId": 60,
        "ProductName": "Workers Compensation"
      },
      {
        "InsuranceProductId": 61,
        "ProductName": "Workers Compensation (Ex-Territorial)"
      }
  ],
  "privacyConsentText": "By ticking this box, you confirm you have a signed privacy in your possession and have attached it to this application prior to submission",
  'serialNumberType': [
    {
      "key": "VIN",
      "value": "VIN"
    },
    {
      "key": "Chassis Number",
      "value": "ChassisNumber"
    },
    {
      "key": "Motor Vehicle Manufacturers Number",
      "value": "MotorVehicleManufacturersNumber"
    },
  ],
  "documentTypes": {
    "accreditationDocs": {
      type: "Accreditation Documents",
      value: "accreditationdocs",
      description: "Please upload valid Accreditation docs",
      required: false,
    },
    "tradeInvoice": {
      type: "Trade Invoice",
      value: "tradeinvoice",
      description: "Please upload valid Trade Invoice docs",
      required: false,
    },
    "driversLicence": {
      type: "Drivers Licence",
      value: "driverslicence",
      description: "Driver's licence of all signatories (front and back). ***If needed, please ensure you meet our requirements for certifying customer identification***",
      standardCondition: true,
      required: true
    },
    "privacyConsent": {
      type: "Privacy Consent",
      value: "privacyconsent",
      description: "Please upload Signed Privacy for ",
      subDescription: "ensuring Dynamoney is noted as the credit provider",
      required: true,
    },
    "bankStatements": {
      type: "Bank Statements",
      value: "bankstatements",
      description: "Please complete the bank statement connection request or upload the csv / pdf statement files",
      required: false
    },
    "financials": {
      type: "Financials",
      value: "financials",
      description: "Please upload 2 years accountant prepared or audited financials",
      required: false
    },
    "atoActivityStatements": {
      type: "ATO Activity Statements",
      value: "atoactivitystatements",
      description: "Please upload ATO portal Income and activity statements",
      required: false
    },
    "assetLiabilitiesStatement": {
      type: "Asset & Liability Statements",
      value: "assetliabilitystatements",
      description: "Please provide for each guaranteeing director",
      required: false
    },
    "trustDeed": {
      type: "Trust Deed",
      value: "trustdeed",
      description: "Certified Trust Deed(s) and driver’s licence for all beneficial owners including appointor/s (Dynamoney to advise)",//"Please upload Trust Deed (certified copy to be provided at settlement)",
      standardCondition: true,
      required: true
    },
    "taxInvoice": {
      type: "Tax Invoice",
      value: "taxinvoice",
      description: "Tax Invoice/s",//"Please upload Tax invoice with asset details/identifiers",
      standardCondition: true,
      required: true
    },
    "floorplanInvoice": {
      type: "Floorplan Invoice",
      value: "floorplaninvoice",
      description: "Floorplan invoices storage",
      required: false,
    },
    "payoutLetter": {
      type: "Payout Letter",
      value: "payoutletter",
      description: "Please upload current payout letter if applicable",
      required: false
    },
    "ppsr": {
      type: "PPSR Documents",
      value: "ppsr",
      description: "PPSR related documents",
      required: false
    },
    "certificateOfCurrency": {
      type: "Certificate of Currency",
      value: "certificateofcurrency",
      description: "Certificate of Currency noting relevant serial/VIN numbers and A.C.N. 603 273 365 Pty Ltd as the interested party (not required for NAF < 100k)",
      standardCondition: true,
      required: false
    },
    "brokerInvoices": {
      type: "Broker Invoices",
      value: "brokerinvoices",
      description: "Broker invoicse noting all fees with GST that Dynamoney is required to pay",
      required: false
    },
    "proofOfSupplierBankDetails": {
      type: "Proof of Supplier Bank Details",
      value: "proofofsupplierbankdetails",
      description: "To upload a supplier bank slip or bank statements to confirm the BSB and account number captured on the supplier tax invoice",
      required: false,
      standardCondition: true,
    },
    "proofOfBrokerBankDetails": {
      type: "Proof of Broker Bank Details",
      value: "proofofbrokerbankdetails",
      description: "To upload a broker bank slip or bank statements to confirm the BSB and account number captured on the supplier tax invoice",
      required: false
    },
    "certifiedPartnershipAgreement": {
      type: "Certified Partnership Agreement",
      value: "certifiedpartnershipagreement",
      description: "Please provide either the certified partnership agreement (JP/Account, etc) or a confirmation letter from the accountant confirm that there is no formal partnership",
      standardCondition: true,
      required: false
    },
    "deedOfRelease": {
      type: "Deed of Release",
      value: "deedofrelease",
      description: "Deed of release is required from AllPaap holder over the Vendor to be released prior to settlement (Dynamoney to advise if applicable)",
      standardCondition: true,
      required: false
    },
    "depositReceipt": {
      type: "Deposit Receipt",
      value: 'depsitreceipt',
      description: "Deposit to be paid to A.C.N. 603 273 365 Pty Ltd BSB: 033-001 Account Number: 166054", // private sale
      subDescription: "Deposit to be paid to the Dealer or A.C.N. 603 273 365 Pty Ltd BSB: 033-001 Account Number: 166054", // non private sale
      standardCondition: true,
      required: true
    },
    "specificCharges": {
      type: "Specific Charges",
      value: 'specificcharges',
      description: "All specific charges over the equipment to be released prior to settlement (Dynamoney to advise if applicable)",
      standardCondition: true,
      required: false
    },
    "disburseFundsToIncumbentFinancier": {
      type: "Disburse the Funds to Incumbent Financier",
      value: 'disbursefundstoincumbentfinancier',
      description: "Dynamoney to disburse the funds directly to incumbent financier in-line with the valid payout letter",
      standardCondition: true,
      required: false
    },
    "proofOfOwnership": {
      type: "Proof of Asset Ownership",
      value: "proofofownership",
      description: "Confirmation of asset ownership by the vendor in form of registration certificates",
      standardCondition: true,
      required: false
    },
    "confirmationOfAssetAcquisition": {
      type: "Confirmation of Asset Acquisition",
      value: "confirmationofassetacquisition",
      description: "Confirmation of asset acquisition within last 90 days (evidence of payments and copy of original tax invoice)",
      standardCondition: true,
      required: false
    },
    "guarantorStatutoryDeclaration": {
      type: "3rd Party Guarantor Statutory Declaration",
      value: "guarantorstatutorydeclaration",
      description: "Please upload a statutory declaration confirming guarantor’s willingness to guarantee the application",
      required: false
    },
    "directionToPay": {
      type: "Direction to Pay",
      value: "directiontopay",
      description: "Please upload signed Direction to pay",
      required: false
    },
    "certifiedIds": {
      type: "Certified IDs",
      value: "certifiedids",
      description: "Certified ID via DL/Passort + green medicare card",
      required: false
    },
    "ppsrDischargeCertificate": {
      type: "PPSR Discharge Certificate",
      value: "ppsrdischargecertificate",
      description: "Please upload a PPSR discharge certificate",
      required: false
    },
    "externalDesktopValuation": {
      type: "External Onsite Valuation & Inspection",
      value: "externaldesktopvaluation",
      description: "Assets to be inspected and valued by an accredited valuer (contact Dynamoney to arrange)",
      standardCondition: true,
      required: false
    },
    "applicationForm": {
      type: "Application Form",
      value: "applicationform",
      description: "Please upload an application form",
      required: false
    },
    "assetInspection": {
      type: "Asset Inspection",
      value: "assetinspection",
      description: "Redbook or Verimoto asset inspection supported by desktop valuation undertaken by Lender",
      standardCondition: true,
      required: false
    },
    "assetInspectionOnSite": {
      type: "Asset Inspection On Site",
      value: "assetinspectiononsite",
      description: "Redbook or Verimoto asset inspection supported by desktop valuation undertaken by Lender OR for applications > $150k we will require an onsite valuation",
      standardCondition: true,
      required: false
    },
    "notExceedForcedLiquidation": {
      type: "Loan Amount not to Exceed Forced Liquidation Value",
      value: "notexceedforcedliquidation",
      description: "Proposed Dynamoney loan amount not to exceed Forced Liquidation Value of the Equipment",
      standardCondition: true,
      required: false
    },
    "internalDocuments": {
      type: "Internal Documents",
      value: "internaldocuments",
      description: "Documents that are visible to Dynamoney only",
      required: false
    },
    "creditorWatch": {
      type: "Creditor Watch",
      value: "creditorwatch",
      description: "Documents that are visible to Dynamoney only",
      required: false
    },
    "contract": {
      type: "Signed Contract",
      value: "contract",
      description: "Signed contract including Direct Debit Form",
      standardCondition: true,
      required: true,
    },
    "approvalNotice": {
      type: "Approval Notice",
      value: "approvalnotice",
      description: "Approval notice for the application",
      required: false,
    },
    "proForma":{
      type: "Pro Forma",
      value: "proforma",
      description: "Please upload the Pro-Forma Invoice",
      required: false
    },
    "salesAgreement":{
      type: "Sales Agreement",
      value: "salesagreement",
      description: "Please upload the Sales Agreement document",
      required: false
    },
    "purchaseOrder":{
      type: "Purchase Order",
      value: "purchaseorder",
      description: "Please upload the Purchase Order document",
      required: false
    },
    "freightBooking":{
      type: "Freight Booking",
      value: "freightbooking",
      description: "Please upload the Freight Booking document",
      required: false
    },
    "commercialInvoice":{
      type: "Commercial Invoice",
      value: "commercialinvoice",
      description: "Please upload the Commercial Invoice document",
      required: false
    },
    "TransportDocument":{
      type: "Transport Document",
      value: "transportdocument",
      description: "Please upload the Transport document"
    },
    "professionalIndemnityInsurance": {
      type: "Professional Indemnity Insurance",
      value: "professionalindemnityinsurance",
      description: "Please attach your professional Indemnity Insurance document",
      required: true
    },
    "industryMemberships": {
      type: "Industry Memberships (CAFBA, MFAA, FBAA)",
      value: "industrymemberships",
      description: "Please attach your industry membership documents",
      required: true
    },
    "executedBrokerAgreement": {
      type: "Executed Broker Agreement",
      value: "executedbrokeragreement",
      description: " Please attach executed broker agreement with full name and signature for all directors",
      required: true
    },
    "bankDepositSlip": {
      type: "Bank Deposit Slip",
      value: "bankdepositslip",
      description: "Please attach bank deposit slip showing your bank account details",
      required: true
    },
    "contact": {
      type: 'Contact',
      value: 'contact',
      description: "Please complete contact details for ",
      required: true,
    },
    "introducerReferralAgreement": {
      type: "Introducer Referral Agreement",
      value: "introducerreferralagreement",
      description: "Please attach introducer Referral Agreement",
      required: true
    },
    "ProofOfPropertyOwnership": {
      type: "Proof of Property Ownership",
      value: "proofofpropertyownership",
      description: "Please upload a land title or most recent council rates notice confirming property ownership by a director or guarantor.",
      required: false,
    },
    "MortgageStatements": {
      type: "Mortgage Statements",
      value: "mortgagestatements",
      description: "Satisfactory review of mortgage statements confirming no deferrals, dishonours, or arrears in the past 12 months.",
      required: false,
    },
    "ComparableCreditStatements": {
      type: "Comparable Credit Statements",
      value: "comparablecreditstatements",
      description: "Satisfactory review of loan statements showing comparable monthly repayments with no dishonours, deferrals, or arrears in the past 12 months.",
      required: false,
    },
    "BusinessUseConfirmation": {
      type: "Business Use Confirmation",
      value: "businessuseconfirmation",
      description: "Satisfactory review of a letter from the applicant's accountant confirming that the asset will primarily be used for business purposes. Noting asset details and the specific business use.",
      required: false,
    },
    "MedicareCard": {
      type: "Medicare Card",
      value: "medicarecard",
      description: "Satisfactory review of Medicare Card",
      required: false,
    },
    "ReviewOfFinalInvoice": {
      type: "Review of Final Invoice",
      value: "reviewoffinalinvoice",
      description: "Satisfactory review of the final invoice confirming asset and vendor details with desktop valuation to be conducted by Credit",
      required: false,
      nonStandardCondition: true,
      nonStandardConditionStatus: 'Pending' as ("Pending" | "Accepted" | "Rejected"),
    },
    "ExternalOnsiteValuation": {
      type: "External Onsite Valuation",
      value: "externalonsitevaluation",
      description: "Asset/s to be inspected and valued by an accredited valuer noting current and 5-year projected FMV and FLV",
      required: false,
    },
     "PaySlips": {
      type: "Pay Slips",
      value: "payslips",
      description: "paySlip",
      required: false,
    },
  },
  "unclassifiedDocuments": {
    type: "Others",
    value: "others",
    description: "Unclassified documents",
    required: false
  },
  "standardConditions": {
    "directDebitForm": {
      type: "Signed documentation including Direct Debit Form",
      value: "accreditationdocs",
      description: "",
      required: true,
    }
  },
  "contractTemplates": {
    "default": "AssetLoanAgreement.docx",
    "consumer": "ConsumerCreditContract.docx",
    "businessLoans": "TermLoanAgreement.docx",
    "businessOverdraft": "OverdraftLoanAgreement.docx",
    "corporateLoans": "CorporateLoanAgreement.docx",
    "chattelMortgage": "SecuredLoanAgreement.docx",
    "rental": "RentalAgreement.docx"
  },
  "approvalNoticeTemplates": {
    "default": "AssetFinanceApprovalNotice.docx",
    "consumer": "ConsumerApprovalNotice.docx",
    "businessLoan": "BusinessLoanApprovalNotice.docx",
    "businessOverdraft": "BusinessOverdraftApprovalNotice.docx",
    "corporateLoans": "CorporateLoanApprovalNotice.docx",
    "chattelMortgage": "AssetFinanceApprovalNotice.docx",
    "rental": "RentalApprovalNotice.docx"
  },
  "overdraftStatementTemplate": "OD_Statement.docx",
  "directEntryStatusCode": {
    "G": "WBC Exception Processing released successfully",
    "R": "WBC Exception Processing Error - see description",
    "C": "WBC Exception Processing - Cancelled",
    "D": "WBC Exception Processing - Recalled",
    "1": "Invalid BSB Number",
    "2": "Payment stopped",
    "3": "Account Closed",
    "4": "Customer Deceased",
    "5": "No Account/Incorrect Account\\#",
    "6": "Refer to Customer",
    "7": "No form PDC held",
    "8": "Invalid User ID Number",
    "9": "Other",
  },
  "bpayStatusCode": {
    "B00": "ACCEPTED BPAY TRANSACTION",
    "B06": "BILLER CODE NOT NUMERIC",
    "B07": "BILLER NOT FOUND",
    "B12": "SERVICE CODE NOT NUMERIC",
    "B15": "CRN NOT PRESENT",
    "B16": "CRN CONTAINS OTHER THAN NUMBERS AND SPACES",
    "B17": "CRN INVALID",
    "B22": "ADDITIONAL CRN CONTAINS OTHER THAN NUMBERS AND SPACES",
    "B26": "BPAY PAYMENT METHOD NOT VALID",
    "B41": "BPAY AMOUNT LESS THAN MINIMUM",
    "B42": "BPAY AMOUNT EXCEEDS NORMAL UPPER LIMIT",
    "B43": "PAYMENT AMOUNT NOT NUMERIC OR SPACES",
    "B61": "BPay amount does not match amount on bill",
    "B62": "Date entered is after expiry date of the bill",
    "B87": "NOT SUFFICIENT FUNDS",
    "B88": "PROBLEM IN MEMO POST",
  },
  "oskoStatusCode": {
    "ASC": "Accepted settlement completed	No action required",
    "QVxx": "Bad request",
    "ABxx": "Unable to process payment",
    "AC01": "Invalid Creditor Account",
    "AC02": "Invalid Debtor Account/Invalid Creditor Account",
    "AC03": "Invalid Debtor Account/Invalid Creditor Account",
    "AC06": "Invalid Debtor Account/Invalid Creditor Account",
    "AC07": "Invalid Debtor Account/Invalid Creditor Account",
    "AC14": "Account exists but cannot accept funds",
    "AC18": "Invalid Debtor Account",
    "AC26": "Unable to process payment. Please contact us for further information.",
    "AC27": "Invalid Creditor Account",
    "AC29": "Invalid Creditor Account",
    "AC30": "Creditor Account is closed",
    "AC34": "Unable to process payment. Please contact us for further information.",
    "AG03": "Account is not reachable for an Osko payment",
    "AG04": "Unable to process payment. Please contact us for further information.",
    "AGNT": "Account is not reachable for an Osko payment",
    "AMxx": "Unable to process payment. Please contact us for further information.",
    "AV04": "Insufficient Funds",
    "AV05": "Unable to process payment. Please contact us for further information.",
    "BE06": "Account does not exist or exists but cannot accept funds",
    "BExx":" Unable to process payment. Please contact us for further information.",
    "CH21": "Unable to process payment. Please contact us for further information.",
    "DE32": "Unable to process payment. Please contact us for further information.",
    "DT02": "Unable to process payment. Please contact us for further information.",
    "ED06": "Unable to process payment. Please contact us for further information.",
    "FFxx": "Unable to process payment. Please contact us for further information.",
    "PV25": "Payment amount exceeds interim payment limit",
    "RC05": "Unable to process payment. Please contact us for further information.",
    "RRxx": "Unable to process payment. Please contact us for further information.",
    "Syxx": "Unable to process payment. Please contact us for further information.",
    "TD03": "Unable to process payment. Please contact us for further information.",
    "TExx": "Unable to process payment. Please contact us for further information.",
    "TFxx": "Unable to process payment. Please contact us for further information.",
  },
  "oskoNotReachableStatusCodes": ["AG03", "AGNT"],
  "insufficientFundsStatusCode": "AV04",
  "commonStatusCode": {
    "New":	"Payment processing, awaiting response from external system.",
    "E01":	"INVALID RECORD ID NUMBER",
    "E02":	"INVALID CURRENCY FOR THIS",
    "E04":	"INVALID PAYMENT METHOD",
    "E06":	"INVALID PAYMENT AMOUNT",
    "E07":	"ZERO PAYMENT AMOUNT",
    "E08":	"NEGATIVE PAYMENT AMOUNT",
    "E09":	"EXCESSIVE PAYMENT AMOUNT",
    "E10":	"MISSING PAYEE NAME DETAILS",
    "E11":	"INVALID PAYEE NAME DETAILS",
    "E12":	"MISSING PAYEE ADDRESS",
    "E13":	"INVALID OR MISSING BSB DETAILS",
    "E14":	"INVALID ACCOUNT DETAILS",
    "E15":	"MISSING ACCOUNT DETAILS",
    "E16":	"MISSING PAYEE NAME DETAILS",
    "E17":	"INVALID PAYEE NAME DETAILS",
    "E18":	"FAX NUMBER MISSING",
    "E19":	"PAYEE ADDRESS MISSING",
    "E20":	"CHEQUE NUMBER INVALID",
    "E21":	"MISSING PAYEE NAME DETAILS",
    "E22":	"INVALID OR MISSING BILLER CODE",
    "E23":	"ZERO BILLER CODE",
    "E24":	"MISSING CUSTOMER REFERENCE NUMBER",
    "E25":	"MULTIPLE '03' BPAY RECORD",
    "E26":	"MISSING '03' BPAY RECORD",
    "E27":	"EMAIL ADDRESS ERROR",
    "E28":	"EMAIL ADDRESS MISSING",
    "E29":	"DUPLICATE CHEQUE NUMBER",
    "Q01":	"Supplier does not exist",
    "Q02":	"Invalid buyer bank account provided",
  },
  "verimotoAssets": [
    {
      "name": "Boat No Trailer",
      "guid": "F1044466-8C30-4704-8749-C12BE1EDA83F",
    },
    {
      "name": "Boat with Trailer",
      "guid": "B8A571AD-A790-49C7-BF6E-40B76EF7D706",
    },
    {
      "name": "Campervan/ Motorhome",
      "guid": "C33A81BA-BCCF-4CFF-81FE-F4795A3526AF",
    },
    {
      "name": "Caravan",
      "guid": "85E5BEE2-2BDC-4C60-95C1-6134972D7A38",
    },
    {
      "name": "Lorry/ Truck",
      "guid": "91EAB2DD-CEDE-427B-A9DC-1BE4A815B025",
    },
    {
      "name": "Motor Vehicle",
      "guid": "E7D1C8E7-032F-4B7E-B4D3-A9DA90385026",
    },
    {
      "name": "Motorcycle/ Scooter",
      "guid": "7AC61221-1AF3-42C1-952B-608AD4E6689B",
    },
    {
      "name": "Plant and Equipment - NonRoad",
      "guid": "6D00C35B-44A5-4842-9915-91698E685C3C",
    },
    {
      "name": "Plant and Equipment - Road",
      "guid": "F13DC15E-8BC1-40EE-A734-49B14FE57520",
    },
    {
      "name": "Trailer",
      "guid": "6CEB0FDA-6BDB-4A3F-997B-052BE44612BE",
    }
  ],
  "verimotoStates": [
    {
      "stateCode": "ACT",
      "guid": "ACT"
    },
    {
      "stateCode": "NSW",
      "guid": "NSW"
    },
    {
      "stateCode": "NT",
      "guid": "NT"
    },
    {
      "stateCode": "QLD",
      "guid": "QLD"
    },
    {
      "stateCode": "SA",
      "guid": "SA"
    },
    {
      "stateCode": "TAS",
      "guid": "TAS"
    },
    {
      "stateCode": "VIC",
      "guid": "VIC"
    },
    {
      "stateCode": "WA",
      "guid": "WA"
    },
  ],
  "verimotoInspectionTypesSandbox": [
    {"assetName": "Boat with Trailer", "inspectionType": "New Lending", "guid": "8E895C21-55F9-4559-9240-1FD5C7FFED39"},
    {"assetName": "Boat with Trailer", "inspectionType": "Refinance", "guid": "902068F1-600B-4258-B25B-7A33AC389A4C"},
    {"assetName": "Boat with Trailer", "inspectionType": "Personal Loan", "guid": "2163FCD7-9FEC-4755-9152-F2A849AD7769"},
    {"assetName": "Boat with Trailer", "inspectionType": "Collections", "guid": "9AD3D146-0F3C-4F40-A1F9-747EFDB470A9"},
    {"assetName": "Boat No Trailer", "inspectionType": "New Lending", "guid": "9543E4BE-51E4-47D3-87A9-7879735B7182"},
    {"assetName": "Boat No Trailer", "inspectionType": "Refinance", "guid": "9B2B1BB8-E69B-44D6-8F74-23AFBB0F5C65"},
    {"assetName": "Boat No Trailer", "inspectionType": "Collections", "guid": "DA47AEB5-5178-46EB-BFBC-7B287BC61E99"},
    {"assetName": "Boat No Trailer", "inspectionType": "Personal Loan", "guid": "DA8C3DB3-1925-4816-9587-DC13899471D0"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "New Lending", "guid": "06DA34DA-E44A-4027-ADA1-4864EA70259B"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Refinance", "guid": "22C3D101-00CF-4627-8729-6343839AF37C"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Personal Loan", "guid": "FCCF235A-7B14-4EBB-8FA6-C505A0D0C469"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Collections", "guid": "A26FE924-5A55-4515-B0D7-37D22B00D113"},
    {"assetName": "Caravan", "inspectionType": "New Lending", "guid": "36E90B5C-65A7-48F6-B683-1E19AC5230CA"},
    {"assetName": "Caravan", "inspectionType": "Refinance", "guid": "54068CF5-F52B-461E-85B7-EB60F0E7501F"},
    {"assetName": "Caravan", "inspectionType": "Collections", "guid": "D63F4E18-39F7-4F22-A254-BC40250DE478"},
    {"assetName": "Caravan", "inspectionType": "Personal Loan", "guid": "3FE44097-CD28-40BE-8315-57F97AF56FCA"},
    {"assetName": "Lorry/ Truck", "inspectionType": "New Lending", "guid": "8D1B0A55-31EE-44C7-8E5C-6F0FE56F395E"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Refinance", "guid": "4DD1F4BB-B04C-4152-B484-DCB43BB72BB6"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Personal Loan", "guid": "23C8313F-8FAD-406B-9D80-40207C5AF806"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Collections", "guid": "21816B65-09C6-4991-9759-6FFDBC2F9C77"},
    {"assetName": "Motor Vehicle", "inspectionType": "New Lending", "guid": "E60E30E2-8FDA-46D2-8E37-03158D76F60D"},
    {"assetName": "Motor Vehicle", "inspectionType": "Refinance", "guid": "2F303836-B28A-44AA-8B12-146D716E09CA"},
    {"assetName": "Motor Vehicle", "inspectionType": "Collections", "guid": "A8A31779-441F-4093-BAAD-38881D316423"},
    {"assetName": "Motor Vehicle", "inspectionType": "Personal Loan", "guid": "2645F8DB-ED44-4D5F-8C1B-B166ED4C8B07"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "New Lending", "guid": "16762DE2-5011-4925-96BD-5BEDF585DC6C"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Refinance", "guid": "8B5C047F-46CC-48C6-A99E-6648ACF9A638"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Personal Loan", "guid": "628950C9-3949-49FD-8F73-53E036F9DC15"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Collections", "guid": "603EE748-3D40-4C40-BF07-1C1EB7A3CC6C"},
    {"assetName": "Trailer", "inspectionType": "New Lending", "guid": "EE539534-3833-4402-860E-50F9CA5033AF"},
    {"assetName": "Trailer", "inspectionType": "Refinance", "guid": "B10F088C-CC64-4AA6-AA9A-7449D400DAD6"},
    {"assetName": "Trailer", "inspectionType": "Collections", "guid": "17C877DE-53A6-4CA5-9757-22B4262A166A"},
    {"assetName": "Trailer", "inspectionType": "Personal Loan", "guid": "C7343E6C-54C3-48CD-B2C2-D622BB52B2F0"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "New Lending", "guid": "AF2C1647-70A4-40C5-AA25-D5DAC53C3BBE"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Refinance", "guid": "DC0BCF9A-2202-4279-99D2-D5C032A9AD0D"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Personal Loan", "guid": "2BC27219-498E-4E8D-90FD-BCE423F86F98"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Collections", "guid": "2C2919E6-EB93-439F-856D-14917D9DDDE8"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "New Lending", "guid": "E1C9899B-CC16-4CBB-9FDB-00C693360A3E"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Refinance", "guid": "A14FFD86-7350-4025-AC04-BF21C17A7EE1"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Collections", "guid": "6A0E352C-1DD0-4414-9F46-3C31B1F7799B"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Personal Loan", "guid": "50AB3A5B-68D3-447C-A86C-316CDE82A417"},
    {"assetName": "IE Verification", "inspectionType": "IE Verification with KYC", "guid": "BB7CE953-96B1-4AF1-A377-873B41A7856A"},
    {"assetName": "PSI Get E-Sign", "inspectionType": "PSI Get E-Sign", "guid": "0976DFFB-0C20-4F50-9909-5B1811793BAC"},
    {"assetName": "PSI Get Acknowledgement", "inspectionType": "PSI Get Acknowledgement", "guid": "B10D1700-7855-424B-8667-2F5BA9476261"},
    {"assetName": "Asset Video Verification", "inspectionType": "Asset Video Verification", "guid": "7013ABEC-8671-46B3-9D3E-B06A632C015C"}
  ],
  "verimotoInspectionTypesProd": [
    {"assetName": "Boat with Trailer", "inspectionType": "New Lending", "guid": "8E895C21-55F9-4559-9240-1FD5C7FFED39"},
    {"assetName": "Boat with Trailer", "inspectionType": "Refinance", "guid": "902068F1-600B-4258-B25B-7A33AC389A4C"},
    {"assetName": "Boat with Trailer", "inspectionType": "Personal Loan", "guid": "C83428CD-D8B5-401E-952A-2CD47012E5E9"},
    {"assetName": "Boat with Trailer", "inspectionType": "Collections", "guid": "9AD3D146-0F3C-4F40-A1F9-747EFDB470A9"},
    {"assetName": "Boat No Trailer", "inspectionType": "New Lending", "guid": "9543E4BE-51E4-47D3-87A9-7879735B7182"},
    {"assetName": "Boat No Trailer", "inspectionType": "Refinance", "guid": "9B2B1BB8-E69B-44D6-8F74-23AFBB0F5C65"},
    {"assetName": "Boat No Trailer", "inspectionType": "Collections", "guid": "DA47AEB5-5178-46EB-BFBC-7B287BC61E99"},
    {"assetName": "Boat No Trailer", "inspectionType": "Personal Loan", "guid": "A865497D-2C0D-4758-80BA-3F2CA94522DC"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "New Lending", "guid": "06DA34DA-E44A-4027-ADA1-4864EA70259B"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Refinance", "guid": "22C3D101-00CF-4627-8729-6343839AF37C"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Personal Loan", "guid": "21EA27CB-438E-47D2-B5F4-E442E69DB1CD"},
    {"assetName": "Campervan/ Motorhome", "inspectionType": "Collections", "guid": "A26FE924-5A55-4515-B0D7-37D22B00D113"},
    {"assetName": "Caravan", "inspectionType": "New Lending", "guid": "36E90B5C-65A7-48F6-B683-1E19AC5230CA"},
    {"assetName": "Caravan", "inspectionType": "Refinance", "guid": "54068CF5-F52B-461E-85B7-EB60F0E7501F"},
    {"assetName": "Caravan", "inspectionType": "Collections", "guid": "D63F4E18-39F7-4F22-A254-BC40250DE478"},
    {"assetName": "Caravan", "inspectionType": "Personal Loan", "guid": "693F1A0B-E259-46F1-A2CA-FDE65B5C9948"},
    {"assetName": "Lorry/ Truck", "inspectionType": "New Lending", "guid": "8D1B0A55-31EE-44C7-8E5C-6F0FE56F395E"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Refinance", "guid": "4DD1F4BB-B04C-4152-B484-DCB43BB72BB6"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Personal Loan", "guid": "5E013183-0E14-4BB5-BF18-C8BAE7FE1C09"},
    {"assetName": "Lorry/ Truck", "inspectionType": "Collections", "guid": "21816B65-09C6-4991-9759-6FFDBC2F9C77"},
    {"assetName": "Motor Vehicle", "inspectionType": "New Lending", "guid": "E60E30E2-8FDA-46D2-8E37-03158D76F60D"},
    {"assetName": "Motor Vehicle", "inspectionType": "Refinance", "guid": "2F303836-B28A-44AA-8B12-146D716E09CA"},
    {"assetName": "Motor Vehicle", "inspectionType": "Collections", "guid": "A8A31779-441F-4093-BAAD-38881D316423"},
    {"assetName": "Motor Vehicle", "inspectionType": "Personal Loan", "guid": "E2F2D44E-51D1-4A38-9AFF-8BF618DC3314"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "New Lending", "guid": "16762DE2-5011-4925-96BD-5BEDF585DC6C"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Refinance", "guid": "8B5C047F-46CC-48C6-A99E-6648ACF9A638"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Personal Loan", "guid": "86301BBB-E583-4E67-94DE-F3F9F734A248"},
    {"assetName": "Motorcycle/ Scooter", "inspectionType": "Collections", "guid": "603EE748-3D40-4C40-BF07-1C1EB7A3CC6C"},
    {"assetName": "Trailer", "inspectionType": "New Lending", "guid": "EE539534-3833-4402-860E-50F9CA5033AF"},
    {"assetName": "Trailer", "inspectionType": "Refinance", "guid": "B10F088C-CC64-4AA6-AA9A-7449D400DAD6"},
    {"assetName": "Trailer", "inspectionType": "Collections", "guid": "17C877DE-53A6-4CA5-9757-22B4262A166A"},
    {"assetName": "Trailer", "inspectionType": "Personal Loan", "guid": "907AB1BE-5652-4067-9DA8-9FACAC3B9D61"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "New Lending", "guid": "AF2C1647-70A4-40C5-AA25-D5DAC53C3BBE"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Refinance", "guid": "DC0BCF9A-2202-4279-99D2-D5C032A9AD0D"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Personal Loan", "guid": "479281BA-28C1-4342-985C-4393E9AC4DA3"},
    {"assetName": "Plant and Equipment - NonRoad", "inspectionType": "Collections", "guid": "2C2919E6-EB93-439F-856D-14917D9DDDE8"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "New Lending", "guid": "E1C9899B-CC16-4CBB-9FDB-00C693360A3E"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Refinance", "guid": "A14FFD86-7350-4025-AC04-BF21C17A7EE1"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Collections", "guid": "6A0E352C-1DD0-4414-9F46-3C31B1F7799B"},
    {"assetName": "Plant and Equipment - Road", "inspectionType": "Personal Loan", "guid": "3BCF4EC6-813E-4255-9A10-9224AB4317BD"},
    {"assetName": "IE Verification", "inspectionType": "IE Verification with KYC", "guid": "BB7CE953-96B1-4AF1-A377-873B41A7856A"},
    {"assetName": "PSI Get E-Sign", "inspectionType": "PSI Get E-Sign", "guid": "0976DFFB-0C20-4F50-9909-5B1811793BAC"},
    {"assetName": "PSI Get Acknowledgement", "inspectionType": "PSI Get Acknowledgement", "guid": "B10D1700-7855-424B-8667-2F5BA9476261"}
  ],
  "verimotoLenderEmail": "api@dynamoney.com",
  "docusignStatus": {
    "autoresponded": {
      "title": "autoresponded",
      "description": "The recipient’s email system auto-responded to the email from Docusign. This status is used in the Docusign web app (also known as the Docusign console) to inform senders about the auto-responded email."
    },
    "completed": {
      "title": "completed",
      "description": "The recipient has completed their actions (signing or other required actions if not a signer) for an envelope."
    },
    "created": {
      "title": "created",
      "description": "The recipient's envelope is in a draft state (the envelope status is created)."
    },
    "declined": {
      "title": "declined",
      "description": "The recipient declined to sign the document(s) in the envelope."
    },
    "delivered": {
      "title": "delivered",
      "description": "The recipient has viewed the document(s) in an envelope through the Docusign signing website. This does not indicate an email delivery of the documents in an envelope."
    },
    "faxpending": {
      "title": "faxpending",
      "description": "The recipient has finished signing, and the system is awaiting a fax attachment by the recipient before completing their signing step."
    },
    "sent": {
      "title": "sent",
      "description": "The recipient has been sent an email notification that it is their turn to sign an envelope."
    },
    "signed": {
      "title": "signed",
      "description": "The recipient has completed (performed all required interactions, such as signing or entering data) all required tags in an envelope. This is a temporary state during processing, after which the recipient status is automatically updated to completed."
    },
  },
  idTypes : [
    { value: 'drivers_licence', label: 'Driver\'s Licence' },
    { value: 'passport', label: 'Passport' }
  ],
}

export interface VehicleSerialNumberType {
  key: string, value: string
}

export interface PrimaryIndustry {
  division: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' ;
  name: string;
}

export interface SecondaryIndustry {
  code: string;
  name: string;
}

export const getVehicleSerialNumberType: ()=> VehicleSerialNumberType[] = ()=> {
  return [...constants.serialNumberType];
}

export const floorplanSerialNumberType: () => VehicleSerialNumberType[] = () => {
  let data = constants.serialNumberType
  return data.filter(x => x.key == 'VIN');
}

export const getDscrIndustryNames: () => DscrIndustry[] = () => {
  return DSCR_Industries.map((industry) => industry);
}

export const getDscrIndustryEbitda = (selectedName: string): DscrIndustryEbitda[] => {
  const selectedIndustry = DSCR_Industries.find((industry) => industry.name === selectedName);
  return selectedIndustry ? selectedIndustry.ebitda : [];
}

export const getPrimaryIndustries:()=>PrimaryIndustry[] = () => {
  return Object.keys(constants.industry.divisions).reduce((industries: PrimaryIndustry[], currentDivision) => {
    const industryDivision = currentDivision;
    const industryName = ((constants.industry.divisions as any)[currentDivision]).name;
    const primaryIndustry: PrimaryIndustry = {
      division: industryDivision as PrimaryIndustry['division'],
      name: industryName,
    };
    industries.push(primaryIndustry);
    return industries;
  }, []);
}

export const getPrimaryIndustry = (division: string): PrimaryIndustry | null => {
  const industry = getPrimaryIndustries().find(i => i.division === division);
  return industry ? industry : null;
}

export const getSecondaryIndustries = (p: PrimaryIndustry) => {
  const division =  constants.industry.divisions[p.division];
  if (division) {
    return Object.keys(division.subdivisions).reduce((secondaryIndustries: SecondaryIndustry[], currentSubdivisionKey) => {
      const code = currentSubdivisionKey;
      const subdivision = (division.subdivisions as any)[currentSubdivisionKey] as string;
      const secondaryIndustry = {
        code: code,
        name: subdivision,
      };
      secondaryIndustries.push(secondaryIndustry);
      return secondaryIndustries;
    }, []);
  }
  return [];
}

export const getSecondaryIndustry = (division: string, code: string): SecondaryIndustry | null => {
  const primaryIndustry = getPrimaryIndustry(division);
  if (primaryIndustry) {
    const secondaryIndustry = getSecondaryIndustries(primaryIndustry).find (s => s.code === code);
    return secondaryIndustry ? secondaryIndustry : null;
  }
  return null;
}

export interface FloorplanAssetType {
  index: string;
  value: string;
}

export interface AssetCategory {
  index: string;
  value: string;
}

export interface AssetCategoryType {
  index: string;
  value: string;
}

export interface AssetCategoryAndType {
  catIndex: string;
  catValue: string;
  typeIndex: string;
  typeValue: string;
}

export interface RevenueOption {
  label: string,
  value: number
}

export type Insurer  = string;

export type CoverType = { InsuranceProductId: number, ProductName: string };

// export const getFloorplanAssetTypeByIndex = (index: string): FloorplanAssetType | null => {
//   const f = constants.floorplanAssetTypes.find(a => a.index == index);
//   return f ?? null;
// }

// export const getFloorplanAssetTypes = (): FloorplanAssetType[] => {
//   const t: FloorplanAssetType[] = [...constants.floorplanAssetTypes];
//   return t;
// }

export const getAssetCategories = (indexFilter?: string[]): AssetCategory[] => {
  let cat: AssetCategory[] = constants.assetCat.map( cat => cat.cat);
  if (indexFilter && indexFilter.length) {
    cat = cat.filter((c) => indexFilter.includes(c.index));
  }
  return cat;
}

export const getAssetCategoriesAndTypes = (): AssetCategoryAndType[] => {
  const catsAndTypes: AssetCategoryAndType[] = [];
  for (const cat of constants.assetCat) {
    for (const type of cat.type) {
      catsAndTypes.push({
        catIndex: cat.cat.index,
        catValue: cat.cat.value,
        typeIndex: type.index,
        typeValue: type.value,
      });
    }
  }
  return catsAndTypes;
}

export const getAssetCategory = (index: string): AssetCategory | null => {
  let cat: AssetCategory[] = constants.assetCat.map( cat => cat.cat);
  const f =  cat.find(cat => cat.index === index);
  return f ? f : null;
}

export const getAssetCategoryType = (catIndex: string, catTypeIndex: string): AssetCategoryType | null => {
  const cat = getAssetCategory(catIndex);
  if (cat) {
    const catWithTypes = constants.assetCat.find( cat => cat.cat.index === catIndex);
    if (catWithTypes && catWithTypes.type.length) {
      const r = catWithTypes.type.find(type => type.index === catTypeIndex);
      return r ? r : null;
    }
  }
  return null;
}

export const getAssetCategoryTypes = (assetCatIndex: string, truckShowGVMTypes = false): AssetCategoryType[] => {
  const catWithTypes = constants.assetCat.find( cat => cat.cat.index === assetCatIndex);
  if (catWithTypes) {
    if (assetCatIndex === '132' && !truckShowGVMTypes) {
      return catWithTypes.type.filter(type => !['5', '6', '7', '8'].includes(type.index));
    }
    if(assetCatIndex === '133') {
      return catWithTypes.type.filter(type => !['16', '17'].includes(type.index));
    }
    else if (assetCatIndex === '132' && truckShowGVMTypes) {
      return catWithTypes.type.filter(type => !['5', '6', '9'].includes(type.index));
    }
    else if(assetCatIndex === '134') {
      return catWithTypes.type.filter(type => !['11'].includes(type.index));
    }
    return catWithTypes.type;
  }
  return [];
}

export const getCommercialOnlyAssetCategoryTypes = (): AssetCategoryType[] => {
  const catWithTypes = constants.assetCat.find( cat => cat.cat.index === '106');
  if (catWithTypes) {
    const types =  catWithTypes.type.filter(t => t.index !== '1');
    if (types) {
      return types;
    }
  }
  return [];
}

export const getConsumerAssetFinanceOnlyAssetCategoryTypes = (): AssetCategoryType[] => {
  const catWithTypes = constants.assetCat.find( cat => cat.cat.index === '139');
  if (catWithTypes) {
    return catWithTypes.type;
  }
  return [];
}

export const getInsurers = (): Insurer[]  => {
  return constants.insurer;
}

export const getCoverTypes = (): CoverType[] => {
  return constants.coverTypes;
}

export type CustomerEntityType = {
  type: string,
  name: string,
}

export const getCustomerEntityTypes = (): CustomerEntityType[] => {
  return constants.entityType.map(r => ({type: r, name: r}));
}


export const isAssetVehicles = (cat: string) => {
  const catInt = parseInt(cat, 10);
  if ([137, 132, 130, 140].includes(catInt)) {
    return true;
  } else {
    return false;
  }
}

export const isAssetCars = (cat: string) => {
  const catInt = parseInt(cat, 10);
  if (catInt === 137) {
    return true;
  } else {
    return false;
  }
}

export const isAssetConsumer = (cat: string) => {
  const catInt = parseInt(cat, 10);
  if (catInt === 139) {
    return true;
  } else {
    return false;
  }
}

export const isAssetConsumerCars = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetConsumer(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.ConsumerCarAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}

export const isAssetConsumerCaravans = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetConsumer(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.ConsumerCaravansAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}

export const isAssetTypeCars = (assetType: string) => {
  const NewCarsAssetTypeIndex = constants.NewCarsAssetTypeIndex;
  const OldCarsAssetTypeIndex = constants.OldCarsAssetTypeIndex;
  if (assetType == OldCarsAssetTypeIndex || assetType == NewCarsAssetTypeIndex) {
    return true;
  } else {
    return false;
  }
}

export const isAssetNewCars = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetCars(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.NewCarsAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}

export const isAssetOldCars = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetCars(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.OldCarsAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}

export const isAssetCaravans = (cat: string) => {
  const catInt = parseInt(cat, 10);
  if (catInt === 140) {
    return true;
  } else {
    return false;
  }
}

export const isAssetTrucksOrTrailers = (cat: string) => {
  const catInt = parseInt(cat, 10);
  if (catInt === 132) {
    return true;
  } else {
    return false;
  }
}

export const isAssetTypeAnyTruck = (cat: string, type: string) => {
  return (
    isAssetTypeTruck(cat, type) ||
    isAssetTypeLightOrHeavyTruck(cat, type)
  );
}

export const isAssetTypeTruck = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetTrucksOrTrailers(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.TruckAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}


export const isAssetTypeLightOrHeavyTruck = (cat: string, type: string) => {
  const catInt = parseInt(cat, 10);
  const typeInt = parseInt(type, 10);
  if (!isAssetTrucksOrTrailers(String(catInt))) {
    return false;
  }
  if (typeInt === parseInt(constants.LightTruckAssetTypeIndex,10) || typeInt === parseInt(constants.HeavyTruckAssetTypeIndex,10)) {
    return true;
  } else {
    return false;
  }
}


export const getLightTruckAssetType = (): AssetCategoryType => {
  const truckCat = constants.assetCat.find(cat => cat.cat.index === '132');
  const truckTypes = truckCat?.type ?? [];
  return truckTypes.find(type => type.index === constants.LightTruckAssetTypeIndex) as AssetCategoryType;
}

export const getHeavyTruckAssetType = (): AssetCategoryType => {
  const truckCat = constants.assetCat.find(cat => cat.cat.index === '132');
  const truckTypes = truckCat?.type ?? [];
  return truckTypes.find(type => type.index === constants.HeavyTruckAssetTypeIndex) as AssetCategoryType;
}

export const getTruckAssetType = (): AssetCategoryType => {
  const truckCat = constants.assetCat.find(cat => cat.cat.index === '132');
  const truckTypes = truckCat?.type ?? [];
  return truckTypes.find(type => type.index === constants.TruckAssetTypeIndex) as AssetCategoryType;
}

export const getStreetTypes = (): {name: string, type: string}[] => {
  const o =  constants.streetTypes;
  const r = [];
  for(let prop in o) {
    r.push({name: prop, type: (o as any)[prop]});
  }
  return r;
}

export const getStates = (): {name: string, type: string}[] => {
  const r = [];
  const o = constants.stateList;
  for (let prop in o) {
    r.push({type: prop, name: (o as any)[prop]});
  }
  return r;
}

export const getRevenueOptions = (): RevenueOption[] => {
  const obj = constants.revenueOptions;
  const keys = Object.keys(obj);
  const options: RevenueOption[] = [];
  for (const key of keys) {
     const label = key;
     const value = (obj as any)[key];
     options.push({label, value});
  }
  return options;
}

export const getTradeRevenueOptions = (): RevenueOption[] => {
  const obj = constants.tradeRevenueOptions;
  const keys = Object.keys(obj);
  const options: RevenueOption[] = [];
  for (const key of keys) {
    const label = key;
    const value = (obj as any)[key];
    options.push({label, value});
  }
  return options;
}

export const isWeekend = (date: Date): boolean => date.getDay() % 6 == 0;

export const getIdTypes = (): IdTypes[] => {
  return constants.idTypes;
}

export const sampleEntityObject = {
  "LegalName": "TSTS PTY LIMITED",
  "EntityType": "PTY",
  "ABN": "60629790085",
  "ACN": "629790085",
  "TradingName": "",
  "Type": "Primary",
  "PrimaryIndustry": "A",
  "IndustrySector": "011",
  "Revenue": 75000,
  "OperateatCommercialAddressFlag": false,
  "manualEntry": false,
  "BusinessCategory": "SME ($1m+ Revenue | $0.5m to $5m Capital)",
  "PrinciplePlaceofBusiness": {
      "UnformattedAddress": "123 Eagle St Brisbane City QLD 4000",
      "StreetNumber": "123",
      "StreetName": "Eagle",
      "StreetType": "St",
      "Suburb": "Brisbane City",
      "State": "QLD",
      "Postcode": "4000",
      "UnitNumber": "",
      "IsManualInput": false
  },
  "CreditorWatch": {
    "title": "Commercial Credit Report",
    "description": "Custom Report for ABN# 50122406731 ACN# 122406731 - BLUE CHILLI TECHNOLOGY PTY LTD + Credit Report + Scored Insights + PPSR Tri Search + On File ASIC Extract",
    "creditReport": {
        "atoTaxDefault": null,
        "insolvencyNotices": [
            {
                "id": "c29341c0-eac5-11eb-8fed-0242ac11000e",
                "lastmod": "22-07-2021",
                "title": "NOTICE OF APPLICATION FOR WINDING UP ORDER",
                "date": "22-07-2021",
                "reportedBy": "Address: mocked response",
                "ruling": "Corporations Act 2001  Paragraph 465A(1)(c)  Regulation 5.4.01A",
                "publisher": "ASIC published this notice mockedResponse"
            }
        ],
        "paymentDefaults": [],
        "mercantileEnquiries": [],
        "courtJudgements": [],
        "loans": [],
        "creditEnquiries": 201,
        "anzsic": {
            "organisationNumber": "50122406731",
            "divisionCode": "M",
            "divisionDescription": "Professional, Scientific and Technical Services",
            "anzsicCode": "7000",
            "anzsicDescription": "Computer System Design and Related Services",
            "subdivisionCode": "70",
            "subdivisionDescription": "Computer System Design and Related Services",
            "groupCode": "700",
            "groupDescription": "Computer System Design and Related Services"
        }
    },
    "organisationExtract": {
        "additionalDetails": [
            "This extract contains information derived from the Australian",
            "Securities and Investment Commission's (ASIC) database under",
            "section 1274A of the Corporations Act 2001.",
            "Please advise ASIC of any error or omission which you may identify."
        ],
        "billing": {
            "billingChargeCode": "955A",
            "billingType": "C"
        },
        "companyAddresses": [
            {
                "addressStartDate": "14-01-2021",
                "addressEndDate": null,
                "addressType": "Registered Office",
                "address2": "mocked address2",
                "careOf": "KRIGSMAN PARTNERS",
                "city": "mocked city",
                "country": null,
                "detailsType": "Current",
                "documentNumber": "7EBD63525",
                "documentNumberQualifier": null,
                "postcode": "2000",
                "state": "VIC",
                "street": "140 BOURKE STREET"
            },
            {
                "addressStartDate": "13-01-2021",
                "addressEndDate": null,
                "addressType": "Registered Office",
                "address2": "mocked address2",
                "careOf": "KRIGSMAN PARTNERS",
                "city": "mocked city",
                "country": null,
                "detailsType": "Current",
                "documentNumber": null,
                "documentNumberQualifier": null,
                "postcode": "2000",
                "state": null,
                "street": "140 BOURKE ST"
            },
            {
                "addressStartDate": "13-01-2015",
                "addressEndDate": null,
                "addressType": "Principal Place of Business",
                "address2": null,
                "careOf": null,
                "city": "mocked city",
                "country": null,
                "detailsType": "Current",
                "documentNumber": "7E6645053",
                "documentNumberQualifier": null,
                "postcode": "2000",
                "state": "NSW",
                "street": "125 YORK STREET"
            }
        ],
        "companyOfficeHolders": [
            {
                "address2": null,
                "appointmentDate": "01-01-2000",
                "ceasedDate": null,
                "australianBusinessNumber": null,
                "birthLocation": {
                    "countryName": null,
                    "iSO3166CountryCode": null,
                    "line1": null,
                    "line2": null,
                    "line3": null,
                    "locality": "mocked locality",
                    "postcode": null,
                    "state": "TAS",
                    "suburb": null
                },
                "address": {
                    "countryName": null,
                    "iSO3166CountryCode": null,
                    "line1": null,
                    "line2": "mocked line2",
                    "line3": null,
                    "locality": null,
                    "postcode": "2000",
                    "state": "NSW",
                    "suburb": "SURRY HILLS"
                },
                "careOf": null,
                "detailsType": "Current",
                "documentNumber": "7E6642307",
                "familyName": "mocked familyName",
                "givenName1": "mocked givenName1",
                "givenName2": null,
                "givenName3": null,
                "officerBirthDate": "01-01-2000",
                "officerName": null,
                "officerOrganisationNumber": null,
                "organisationNumberHeading": null,
                "roleType": "Director",
                "knownInformation": [
                    {
                        "organisationNumber": "141425532",
                        "detailsType": "Current",
                        "organisationName": "CALEADRA PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [
                                {
                                    "id": "c1d514ce-cf89-11e9-85e1-88e9fe73838a",
                                    "lastmod": "09-04-2014",
                                    "title": "NOTICE OF PROPOSED DEREGISTRATION - ASIC initiated under s601AB(1) or (1A)",
                                    "date": null,
                                    "reportedBy": null,
                                    "ruling": "Corporations Act 2001  Subsection 601AB(3)",
                                    "publisher": "ASIC published this notice mockedResponse"
                                }
                            ],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": true,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "154173154",
                        "detailsType": "Current",
                        "organisationName": "BLUECHILLI CAPITAL PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "158416490",
                        "detailsType": "Ceased/Former",
                        "organisationName": "GIGGEDIN PTY LTD",
                        "organisationStatus": "Under External Administration And/Or Controller Appointed",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "161235258",
                        "detailsType": "Current",
                        "organisationName": "TOKEN ONE PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "161328678",
                        "detailsType": "Ceased/Former",
                        "organisationName": "HOUSL PTY LTD",
                        "organisationStatus": "Deregistered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "161570145",
                        "detailsType": "Current",
                        "organisationName": "DIGITALSORBET PTY LTD",
                        "organisationStatus": "Deregistered",
                        "creditReport": {
                            "insolvencyNotices": [
                                {
                                    "id": "59731e62-cf89-11e9-9bb4-88e9fe73838a",
                                    "lastmod": "03-03-2016",
                                    "title": "NOTICE OF PROPOSED DEREGISTRATION - ASIC initiated under s601AB(1) or (1A)",
                                    "date": null,
                                    "reportedBy": null,
                                    "ruling": "Corporations Act 2001  Subsection 601AB(3)",
                                    "publisher": "ASIC published this notice mockedResponse"
                                }
                            ],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": true,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "162877867",
                        "detailsType": "Ceased/Former",
                        "organisationName": "DIVVY PARKING PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "164998734",
                        "detailsType": "Ceased/Former",
                        "organisationName": "LIQUORUN PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "166066379",
                        "detailsType": "Ceased/Former",
                        "organisationName": "DISTRIBUTED LOGISTICS PTY. LTD.",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "604611556",
                        "detailsType": "Ceased/Former",
                        "organisationName": "GETSWIFT LIMITED",
                        "organisationStatus": "Under External Administration And/Or Controller Appointed",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "605045654",
                        "detailsType": "Ceased/Former",
                        "organisationName": "GET SWIFT LOGISTICS PTY LTD",
                        "organisationStatus": "Under External Administration And/Or Controller Appointed",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "616378600",
                        "detailsType": "Current",
                        "organisationName": "BLUECHILLI CAPITAL INTERNATIONAL PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    },
                    {
                        "organisationNumber": "642308030",
                        "detailsType": "Current",
                        "organisationName": "PHYLLOME PTY LTD",
                        "organisationStatus": "Registered",
                        "creditReport": {
                            "insolvencyNotices": [],
                            "paymentDefaults": [],
                            "mercantileEnquiries": [],
                            "courtJudgements": [],
                            "loans": [],
                            "creditEnquiries": null
                        },
                        "highRisk": false,
                        "criticalAsicDocuments": []
                    }
                ],
                "isBankruptcy": false,
                "bankruptcyLastfetched": 1653607162
            }
        ],
        "contactAddresses": [
            {
                "addressStartDate": "07-01-2013",
                "addressType": "Contact Address for ASIC use only",
                "address2": "mocked address2",
                "careOf": null,
                "city": "mocked city",
                "country": null,
                "detailsType": "Current",
                "documentNumber": null,
                "postcode": "2000",
                "state": "VIC",
                "street": null,
                "addressEndDate": null
            }
        ],
        "financialReports": [],
        "header": {
            "clientId": "CLIENT",
            "clientReference": "CLIENT REF",
            "jurisdiction": "9",
            "messageCreationDate": "14-10-2022",
            "messageCreationTime": "11:39:40",
            "messageTraceNumber": "5189100",
            "messageType": "YHDASCDSCE",
            "version": "1100"
        },
        "organisation": {
            "acnc": null,
            "australianBusinessNumber": "50122406731",
            "governanceType": null,
            "organisationName": "BLUE CHILLI TECHNOLOGY PTY LTD",
            "organisationNumber": "122406731",
            "organisationNumberHeading": "Australian Company Number",
            "organisationState": "NSW",
            "previousStateNumber": null,
            "registrationDate": "27-10-2006",
            "reviewDate": "27-10-2022",
            "historicalChanges": [
                {
                    "dateDeregistered": null,
                    "detailsType": "Current",
                    "disclosingEntity": "No",
                    "documentNumber": "1E2635086",
                    "documentNumberQualifier": null,
                    "organisationClass": "Limited By Shares",
                    "organisationName": "BLUE CHILLI TECHNOLOGY PTY LTD",
                    "organisationNameStartDate": "27-10-2006",
                    "organisationStatus": "Registered",
                    "organisationSubClass": "Proprietary Company",
                    "organisationType": "Australian Proprietary Company",
                    "reasonDeregistered": null,
                    "schemeCategory1": null,
                    "schemeCategory2": null,
                    "schemeCategory3": null,
                    "schemeCategory4": null,
                    "startDate": "27-10-2006",
                    "endDate": null
                }
            ]
        },
        "shareCapitalGroup": [
            {
                "classCode": "ORD",
                "classTitle": "ORDINARY",
                "detailsType": "Current",
                "documentNumber": "7EBE48058",
                "documentNumberQualifier": null,
                "numberOfShares": 1355147,
                "totalAmountDueAndPayable": 0,
                "totalAmountPaid": 11114785
            }
        ],
        "shareInterests": [
            {
                "beneficiallyOwned": false,
                "classCode": "ORD",
                "detailsType": "Current",
                "documentNumber": "7E6359915",
                "documentNumberQualifier": null,
                "fullyPaid": true,
                "members": [
                    {
                        "memberOrganisationNumber": "159418667",
                        "organisationNumberHeading": "Australian Company Number",
                        "memberName": "mocked memberName",
                        "address2": null,
                        "careOf": null,
                        "city": "mocked city",
                        "country": null,
                        "familyName": null,
                        "givenName1": null,
                        "givenName2": null,
                        "jointHolding": false,
                        "postcode": "2000",
                        "state": "SA",
                        "street": "mocked street",
                        "australianBusinessNumber": null
                    }
                ],
                "numberHeld": "97092"
            },
            {
                "beneficiallyOwned": true,
                "classCode": "ORD",
                "detailsType": "Current",
                "documentNumber": "7E6361437",
                "documentNumberQualifier": null,
                "fullyPaid": true,
                "members": [
                    {
                        "memberOrganisationNumber": "004116296",
                        "organisationNumberHeading": "Australian Company Number",
                        "memberName": "mocked memberName",
                        "address2": "mocked address2",
                        "careOf": null,
                        "city": "mocked city",
                        "country": null,
                        "familyName": null,
                        "givenName1": null,
                        "givenName2": null,
                        "jointHolding": false,
                        "postcode": "2000",
                        "state": "VIC",
                        "street": "mocked street",
                        "australianBusinessNumber": "32004116296"
                    }
                ],
                "numberHeld": "302850"
            },
            {
                "beneficiallyOwned": true,
                "classCode": "ORD",
                "detailsType": "Current",
                "documentNumber": "7E6644940",
                "documentNumberQualifier": null,
                "fullyPaid": true,
                "members": [
                    {
                        "memberOrganisationNumber": null,
                        "organisationNumberHeading": null,
                        "memberName": null,
                        "address2": null,
                        "careOf": null,
                        "city": "mocked city",
                        "country": null,
                        "familyName": "mocked familyName",
                        "givenName1": "mocked givenName1",
                        "givenName2": "mocked givenName2",
                        "jointHolding": false,
                        "postcode": "2000",
                        "state": "NSW",
                        "street": "mocked street",
                        "australianBusinessNumber": null
                    }
                ],
                "numberHeld": "101018"
            },
            {
                "beneficiallyOwned": false,
                "classCode": "ORD",
                "detailsType": "Current",
                "documentNumber": "7E6644940",
                "documentNumberQualifier": null,
                "fullyPaid": true,
                "members": [
                    {
                        "memberOrganisationNumber": "168542023",
                        "organisationNumberHeading": "Australian Company Number",
                        "memberName": "mocked memberName",
                        "address2": "mocked address2",
                        "careOf": null,
                        "city": "mocked city",
                        "country": null,
                        "familyName": null,
                        "givenName1": null,
                        "givenName2": null,
                        "jointHolding": false,
                        "postcode": "2000",
                        "state": "QLD",
                        "street": "mocked street",
                        "australianBusinessNumber": null
                    }
                ],
                "numberHeld": "20186"
            }
        ],
        "documentList": [],
        "misc": [],
        "issuedCapital": [],
        "companyDetails": []
    },
    "paymentPredictor": {
        "paymentPredictorStats": [],
        "paymentPredictorHistory": [],
        "industryPaymentPredictor": {
            "atbData": {
                "accountAbnHistory": [],
                "othersAbnHistory": {
                    "tradeHistory": [],
                    "getSingleHighestCreditExtended": 0
                }
            },
            "paymentPredictorPurchased": true,
            "paymentPredictorNoEnoughData": false,
            "othersAbnHistory": {
                "tradeHistory": [],
                "getSingleHighestCreditExtended": 0
            },
            "tableOfStats": {
                "averageBalance": 76140.093582701,
                "averageOverdue": 40604.839839531,
                "highestCreditExposureSingleSupplier": 0,
                "highestCreditExposureCombinedSuppliers": 0,
                "highestOverdueCreditExposure": 0,
                "numberOfTradeLines": 24
            },
            "overdueDays": [
                10,
                6,
                6,
                7,
                7,
                7,
                7,
                7,
                7,
                9,
                6,
                6
            ],
            "averageOverdue": [
                {
                    "name": "Average Days Overdue",
                    "data": [
                        "6",
                        "6",
                        "9",
                        "7",
                        "7",
                        "7",
                        "7",
                        "7",
                        "7",
                        "6",
                        "6",
                        "10"
                    ],
                    "color": "#53ad58"
                }
            ],
            "noGoodData": false,
            "displayStateAlert": false
        }
    },
    "paymentRating": null,
    "industryPaymentRating": {
        "currentPaymentRating": {
            "asOfDate": "2022-12-22",
            "daysOverdue": 9.09,
            "rating": "C",
            "stats": {
                "averageBalance": 76140.093582701,
                "averageOverdue": 40604.839839531,
                "numberOfTradelines": 24,
                "highestCreditExposureSingleSupplier": 0,
                "highestCreditExposureCombinedSupplier": 0,
                "highestCreditExposureOverdue": 0
            }
        },
        "paymentRatingHistory": [
            {
                "asOfDate": "2022-12-15",
                "daysOverdue": 9.09,
                "rating": "C",
                "stats": {
                    "averageBalance": 76140.093582701,
                    "averageOverdue": 40604.839839531,
                    "numberOfTradelines": 24,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-11-15",
                "daysOverdue": 5.94,
                "rating": "B",
                "stats": {
                    "averageBalance": 19238.276409585,
                    "averageOverdue": 8764.9270522257,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-10-15",
                "daysOverdue": 5.99,
                "rating": "B",
                "stats": {
                    "averageBalance": 19207.380795774,
                    "averageOverdue": 8847.47168563,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-09-15",
                "daysOverdue": 6.13,
                "rating": "B",
                "stats": {
                    "averageBalance": 19746.969418537,
                    "averageOverdue": 9541.0273038202,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-08-15",
                "daysOverdue": 6.09,
                "rating": "B",
                "stats": {
                    "averageBalance": 16410.34356508,
                    "averageOverdue": 7199.373687121,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-07-15",
                "daysOverdue": 6.04,
                "rating": "B",
                "stats": {
                    "averageBalance": 18023.674546249,
                    "averageOverdue": 8210.2111931873,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-06-15",
                "daysOverdue": 6.09,
                "rating": "B",
                "stats": {
                    "averageBalance": 17902.223719505,
                    "averageOverdue": 8286.1164296465,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-05-15",
                "daysOverdue": 6.16,
                "rating": "B",
                "stats": {
                    "averageBalance": 17988.574699615,
                    "averageOverdue": 8234.0056231026,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-04-15",
                "daysOverdue": 6.26,
                "rating": "B",
                "stats": {
                    "averageBalance": 17481.500834023,
                    "averageOverdue": 8155.307277235,
                    "numberOfTradelines": 12,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-03-15",
                "daysOverdue": 8.19,
                "rating": "C",
                "stats": {
                    "averageBalance": 14941.087671273,
                    "averageOverdue": 6891.8646821784,
                    "numberOfTradelines": 10,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-02-15",
                "daysOverdue": 5.53,
                "rating": "B",
                "stats": {
                    "averageBalance": 15427.497959697,
                    "averageOverdue": 7217.445180145,
                    "numberOfTradelines": 10,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            },
            {
                "asOfDate": "2022-01-15",
                "daysOverdue": 5.56,
                "rating": "B",
                "stats": {
                    "averageBalance": 15805.149743983,
                    "averageOverdue": 7336.5031355934,
                    "numberOfTradelines": 10,
                    "highestCreditExposureSingleSupplier": 0,
                    "highestCreditExposureCombinedSupplier": 0,
                    "highestCreditExposureOverdue": 0
                }
            }
        ]
    },
    "currentDirectors": [
        {
            "documentNumber": "7E6642307",
            "detailsType": "Current",
            "roleType": "Director",
            "appointmentDate": "01-01-2000",
            "familyName": "mocked familyName",
            "givenName1": "mocked givenName1",
            "officerBirthDate": "01-01-2000",
            "birthState": "mocked birthState",
            "street": "mocked street",
            "city": "mocked city",
            "state": "NSW",
            "postcode": "2000",
            "acns": [
                "141425532",
                "161570145",
                "154173154",
                "158416490",
                "161235258",
                "161328678",
                "162877867",
                "164998734",
                "166066379",
                "604611556",
                "605045654",
                "616378600",
                "642308030"
            ],
            "currentDirectorships": [
                {
                    "company": {
                        "name": "CALEADRA PTY LTD",
                        "acn": "141425532",
                        "abn": null,
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "11-01-2010"
                    },
                    "isHighRisk": true,
                    "acn": "141425532"
                },
                {
                    "company": {
                        "name": "DIGITALSORBET PTY LTD",
                        "acn": "161570145",
                        "abn": "26161570145",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "DRGD",
                        "registeredDate": "07-12-2012"
                    },
                    "isHighRisk": true,
                    "acn": "161570145"
                },
                {
                    "company": {
                        "name": "BLUECHILLI CAPITAL PTY LTD",
                        "acn": "154173154",
                        "abn": null,
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "09-11-2011"
                    },
                    "isHighRisk": false,
                    "acn": "154173154"
                },
                {
                    "company": {
                        "name": "TOKEN ONE PTY LTD",
                        "acn": "161235258",
                        "abn": "32161235258",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "14-11-2012"
                    },
                    "isHighRisk": false,
                    "acn": "161235258"
                },
                {
                    "company": {
                        "name": "BLUECHILLI CAPITAL INTERNATIONAL PTY LTD",
                        "acn": "616378600",
                        "abn": null,
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "09-12-2016"
                    },
                    "isHighRisk": false,
                    "acn": "616378600"
                },
                {
                    "company": {
                        "name": "PHYLLOME PTY LTD",
                        "acn": "642308030",
                        "abn": "43642308030",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "02-07-2020"
                    },
                    "isHighRisk": false,
                    "acn": "642308030"
                }
            ],
            "givenName2": null,
            "birthLocality": "mocked birthLocality",
            "formerDirectorships": [
                {
                    "company": {
                        "name": "GIGGEDIN PTY LTD",
                        "acn": "158416490",
                        "abn": "33158416490",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "EXAD",
                        "registeredDate": "17-05-2012"
                    },
                    "isHighRisk": false,
                    "acn": "158416490"
                },
                {
                    "company": {
                        "name": "HOUSL PTY LTD",
                        "acn": "161328678",
                        "abn": "79161328678",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "DRGD",
                        "registeredDate": "21-11-2012"
                    },
                    "isHighRisk": false,
                    "acn": "161328678"
                },
                {
                    "company": {
                        "name": "DIVVY PARKING PTY LTD",
                        "acn": "162877867",
                        "abn": "80162877867",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "17-03-2013"
                    },
                    "isHighRisk": false,
                    "acn": "162877867"
                },
                {
                    "company": {
                        "name": "LIQUORUN PTY LTD",
                        "acn": "164998734",
                        "abn": "56164998734",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "25-07-2013"
                    },
                    "isHighRisk": false,
                    "acn": "164998734"
                },
                {
                    "company": {
                        "name": "DISTRIBUTED LOGISTICS PTY. LTD.",
                        "acn": "166066379",
                        "abn": "79166066379",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "REGD",
                        "registeredDate": "01-10-2013"
                    },
                    "isHighRisk": false,
                    "acn": "166066379"
                },
                {
                    "company": {
                        "name": "GETSWIFT LIMITED",
                        "acn": "604611556",
                        "abn": "57604611556",
                        "type": "APUB",
                        "class": "LMSH",
                        "subClass": "ULST",
                        "status": "EXAD",
                        "registeredDate": "06-03-2015"
                    },
                    "isHighRisk": false,
                    "acn": "604611556"
                },
                {
                    "company": {
                        "name": "GET SWIFT LOGISTICS PTY LTD",
                        "acn": "605045654",
                        "abn": "42605045654",
                        "type": "APTY",
                        "class": "LMSH",
                        "subClass": "PROP",
                        "status": "EXAD",
                        "registeredDate": "30-03-2015"
                    },
                    "isHighRisk": false,
                    "acn": "605045654"
                }
            ],
            "officerName": null,
            "officerOrganisationNumber": null,
            "givenName3": null,
            "organisationNumberHeading": null,
            "address2": null,
            "australianBusinessNumber": null
        }
    ],
    "companyDetails": [],
    "creditScore": {
        "currentCreditScore": 565,
        "creditScoreHistory": [
            {
                "date": "22-12-2022",
                "score": 565
            },
            {
                "date": "15-12-2022",
                "score": 549
            },
            {
                "date": "15-11-2022",
                "score": 549
            },
            {
                "date": "15-10-2022",
                "score": 549
            },
            {
                "date": "15-09-2022",
                "score": 543
            },
            {
                "date": "15-08-2022",
                "score": 549
            }
        ]
    },
    "creditRating": {
        "currentCreditRating": "C2",
        "creditRatingHistory": [
            {
                "date": "22-12-2022",
                "rating": "C2"
            },
            {
                "date": "15-12-2022",
                "rating": "C2"
            },
            {
                "date": "15-11-2022",
                "rating": "C2"
            },
            {
                "date": "15-10-2022",
                "rating": "C2"
            },
            {
                "date": "15-09-2022",
                "rating": "C3"
            },
            {
                "date": "15-08-2022",
                "rating": "C2"
            },
            {
                "date": "15-07-2022",
                "rating": "C3"
            },
            {
                "date": "15-06-2022",
                "rating": "C3"
            }
        ]
    },
    "pdfRequestId": "7de52832-8195-11ed-8797-0242ac11000d",
    "abrData": {
        "name": "Blue Chilli Technology Pty Ltd",
        "abn": "50122406731",
        "acn": "122406731",
        "registeredDate": "11-01-2010",
        "status": "Active",
        "type": "PRV",
        "typeCode": "PRV",
        "updatedDate": "03-02-2021",
        "description": "Australian Private Company",
        "lastUpdated": "03-02-2021",
        "statusEffectiveFrom": null,
        "gst": [
            {
                "effectiveTo": null,
                "effectiveFrom": "29-06-2010"
            }
        ],
        "addresses": [
            {
                "effectiveTo": null,
                "effectiveFrom": "17-12-2019",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "17-12-2019",
                "effectiveFrom": "25-08-2015",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "25-08-2015",
                "effectiveFrom": "17-09-2014",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "17-09-2014",
                "effectiveFrom": "20-06-2012",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "20-06-2012",
                "effectiveFrom": "02-09-2011",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "02-09-2011",
                "effectiveFrom": "11-03-2010",
                "postcode": "2000",
                "stateCode": "NSW"
            },
            {
                "effectiveTo": "11-03-2010",
                "effectiveFrom": "11-01-2010",
                "postcode": "2000",
                "stateCode": "NSW"
            }
        ],
        "businessNames": [
            {
                "effectiveFrom": "11-01-2010",
                "name": "Blue Chilli Technology Pty Ltd",
                "type": "Main Name"
            },
            {
                "effectiveFrom": "11-03-2010",
                "name": "AUTOCARLOG",
                "type": "Main Trading Name"
            },
            {
                "effectiveFrom": "27-09-2010",
                "name": "ONEFORONE GROUP",
                "type": "Business Name"
            },
            {
                "effectiveFrom": "21-01-2010",
                "name": "AUTOCARLOG.COM",
                "type": "Business Name"
            }
        ],
        "charityTypes": [],
        "charityEndorsements": [],
        "dgrEndorsements": [],
        "historicalChanges": [
            {
                "entityStatus": [],
                "goodsAndServicesTax": [],
                "mainName": [],
                "businessName": [
                    "The Business Name ONEFORONE GROUP was removed"
                ],
                "mainBusinessPhysicalAddress": [],
                "mainTradingName": [],
                "date": "03-02-2021"
            },
            {
                "entityStatus": [],
                "goodsAndServicesTax": [],
                "mainName": [],
                "businessName": [],
                "mainBusinessPhysicalAddress": [
                    "The Main Business Physical Address was changed to NSW 2000 from NSW 2067"
                ],
                "mainTradingName": [],
                "date": "20-06-2012"
            },
            {
                "entityStatus": [],
                "goodsAndServicesTax": [],
                "mainName": [],
                "businessName": [],
                "mainBusinessPhysicalAddress": [
                    "The Main Business Physical Address was changed to NSW 2067 from NSW 2067"
                ],
                "mainTradingName": [
                    "The Main Trading Name was changed to AUTOCARLOG"
                ],
                "date": "11-03-2010"
            },
        ]
    },
    "statusChange": {
        "abrChanges": [
            {
                "entityStatus": "Active (Current status)",
                "date": "2010-01-11"
            }
        ],
        "asicChanges": [],
        "gstChanges": [
            {
                "entityStatus": "Registered For GST (Current status)",
                "date": "2010-06-29"
            }
        ]
    },
    "asicData": {
        "organisation": {
            "australianBusinessNumber": "50122406731",
            "name": "BLUE CHILLI TECHNOLOGY PTY LTD",
            "number": "122406731",
            "numberType": "ACN",
            "type": "Australian Proprietary Company",
            "status": "Registered",
            "address": {
                "countryName": null,
                "iSO3166CountryCode": null,
                "line1": null,
                "line2": null,
                "line3": null,
                "locality": null,
                "postcode": "2000",
                "state": "VIC",
                "suburb": "MELBOURNE"
            },
            "controllingJurisdictionExpanded": "Australian Securities & Investments Commission",
            "controllingJurisdiction": "ASIC",
            "numberHeading": "Australian Company Number",
            "registrationDate": "27-10-2006",
            "deregistrationDate": null,
            "reviewDate": "27-10-2023",
            "satisfiedChargesIndicator": "Fewer than 10 pages in Satisfied Charges Extract",
            "class": "Limited By Shares",
            "subclass": "Proprietary Company",
            "typeExpanded": "Australian Proprietary Company",
            "statusExpanded": "Registered",
            "classExpanded": "Limited By Shares",
            "subclassExpanded": "Proprietary Company",
            "formattedNumber": "122 406 731",
            "acnc": false
        },
        "documents": [
            {
                "documentQualifier": null,
                "additionalDescriptions": [
                    {
                        "additionalDescription": "Notification of Dismissal of Application For Winding Up Order",
                        "subFormCode": "519C"
                    }
                ],
                "dateProcessed": "18-08-2021",
                "dateReceived": "13-08-2021",
                "documentNumber": "031085456",
                "effectiveDate": "13-08-2021",
                "formCode": "519",
                "numberOfPages": "3",
                "documentUnderRequisition": null
            },
            {
                "documentQualifier": null,
                "additionalDescriptions": [
                    {
                        "additionalDescription": "Notification of Application to Wind Up Company Under S. 459p, 462 or 464",
                        "subFormCode": "519G"
                    }
                ],
                "dateProcessed": "05-07-2021",
                "dateReceived": "30-06-2021",
                "documentNumber": "031085235",
                "effectiveDate": "30-06-2021",
                "formCode": "519",
                "numberOfPages": "3",
                "documentUnderRequisition": null
            }, 
            {
                "documentQualifier": null,
                "additionalDescriptions": [
                    {
                        "additionalDescription": "Application For Registration as a Proprietary Company",
                        "subFormCode": "201C"
                    }
                ],
                "dateProcessed": "27-10-2006",
                "dateReceived": "27-10-2006",
                "documentNumber": "1E2635086",
                "effectiveDate": "27-10-2006",
                "formCode": "201",
                "numberOfPages": "3",
                "documentUnderRequisition": null
            }
        ],
        "endOfMessage": {
            "endOfMessage": "YTRENDDSCD",
            "segmentCount": 42
        },
        "organisationFormerName": []
    },
    "ppsr": {
        "searchByAbn": {
            "nevdisVehicles": [],
            "registrations": [],
            "searchNumber": null,
            "searchType": null
        },
        "searchByAcn": {
            "nevdisVehicles": [],
            "registrations": [],
            "searchNumber": null,
            "searchType": null
        },
        "searchByName": {
            "nevdisVehicles": [],
            "registrations": [],
            "searchNumber": null,
            "searchType": null
        }
    }
  }
}

export const sampleIndividualObject = {
  "id": "df2f3d05-57d4-40fc-8936-955367fe1d10",
  "Gender": "",
  "PrivacyConsent": false,
  "PropertyOwnerFlag": false,
  "Title": "Prof",
  "SurName": "vv",
  "GivenName": "rr",
  "MiddleName": "vv",
  "Role": null,
  "SignerRole": "Signer",
  "HomeAddress": {
      "UnformattedAddress": "1 Farrer Pl, Sydney, NSW 2000",
      "UnitNumber": "",
      "Postcode": "2000",
      "State": "NSW",
      "Suburb": "Sydney",
      "StreetName": "Farrer",
      "StreetNumber": "1",
      "StreetType": "Pl",
      "IsManualInput": false
  },
  "DoB": "1970-01-01",
  "Email": "r@com.com",
  "MobileNumber": "0400000000",
  "GuarantorFlag": false,
  "sfContactId": "0039t00000VEwK3AAL",
  "EquifaxConsumer": {
    "soapenv:Envelope": {
        "soapenv:Header": {
            "wsa:MessageID": "240827-38B5B-085B3",
            "wsa:RelatesTo": "",
            "wsa:To": "http://www.w3.org/2005/08/addressing/anonymous",
            "wsa:From": {
                "wsa:Address": "https://ctaau.vedaxml.com/cta/sys2/soap11/vedascore-apply-v2-0"
            },
            "wsa:Action": "http://vedaxml.com/vedascore-apply/EnquiryResponse"
        },
        "soapenv:Body": {
            "vs:response": {
                "vs:product-header": {
                    "vs:enquiry-id": "240827-38B5B-085B3",
                    "vs:datetime-requested": "2024-08-27T15:05:56.870+10:00",
                    "vs:datetime-generated": "2024-08-27T15:05:58.532+10:00",
                    "vs:product-name": "Commercial Credit Assess - Individual",
                    "vs:permission-type": "COMMERCIAL+CONSUMER",
                    "vs:product-data-level": "Comprehensive",
                    "vs:product-version": 2
                },
                "vs:product-data": {
                    "vs:enquiry-report": {
                        "vs:primary-match": {
                            "vs:bureau-reference": 166369682,
                            "vs:individual-name": {
                                "vs:family-name": "HOLMES",
                                "vs:first-given-name": "SHERLOCK"
                            },
                            "vs:gender": "Female",
                            "vs:date-of-birth": "1967-06-25",
                            "vs:drivers-licence": {
                                "vs:number": "K000001"
                            },
                            "vs:is-deceased": false
                        },
                        "vs:addresses": {
                            "vs:address": {
                                "vs:id-references": {
                                    "vs:id-reference": 1
                                },
                                "vs:street-number": 4,
                                "vs:street-name": "YENNA",
                                "vs:street-type": "PL",
                                "vs:suburb": "GLENMORE PARK",
                                "vs:state": "NSW",
                                "vs:postcode": 2745,
                                "vs:country-code": "AUS"
                            }
                        },
                        "vs:consumer-credit-file": {
                            "vs:credit-enquiries": {
                                "vs:credit-enquiry": [
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-type": "Credit Card",
                                        "vs:enquiry-amount": 4500,
                                        "vs:relationship": "Principal's Account (sole)",
                                        "vs:credit-enquirer": {
                                            "vs:name": "AMERICAN EXPRESS"
                                        }
                                    }
                                ]
                            },
                            "vs:accounts": {
                                "vs:account": [
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-id": "65465468468464E",
                                        "vs:original-credit-provider": {
                                            "vs:name": "ESANDA FINANCE",
                                            "vs:classification": ""
                                        },
                                        "vs:latest-credit-provider": {
                                            "vs:name": "ESANDA FINANCE"
                                        },
                                        "vs:account-type": "Real Property Mortgage",
                                        "vs:relationship": "Principal's Account (sole)",
                                        "vs:association-information": {
                                            "vs:is-serious-credit-infringement": false
                                        },
                                        "vs:defaults": {
                                            "vs:default": {
                                                "vs:status": "Outstanding",
                                                "vs:original-default": {
                                                    "vs:date-recorded": "2020-11-05",
                                                    "vs:default-amount": 2600
                                                },
                                                "vs:current-default": {
                                                    "vs:date-recorded": "2020-11-05",
                                                    "vs:default-amount": 2600
                                                }
                                            }
                                        },
                                        "vs:repayment-history": {
                                          "vs:payment": [
                                            {
                                              "vs:period":	"2022-10",
                                              "vs:status":	"C",
                                            },
                                            {
                                              "vs:period":	"2022-11",
                                              "vs:status":	0
                                            },
                                            {
                                              "vs:period":	"2022-12",
                                              "vs:status":	0
                                            },
                                            {
                                              "vs:period":	"2023-01",
                                              "vs:status":	"P"
                                            }
                                          ]
                                        }
                                    },
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-id": "48468486467E7",
                                        "vs:original-credit-provider": {
                                            "vs:name": "VIRGIN MOBILE",
                                            "vs:classification": ""
                                        },
                                        "vs:latest-credit-provider": {
                                            "vs:name": "VIRGIN MOBILE"
                                        },
                                        "vs:account-type": "Telecommunications Services",
                                        "vs:relationship": "Principal's Account (sole)",
                                        "vs:association-information": {
                                            "vs:is-serious-credit-infringement": false
                                        },
                                        "vs:defaults": {
                                            "vs:default": {
                                                "vs:status": "Outstanding",
                                                "vs:original-default": {
                                                    "vs:date-recorded": "2023-05-17",
                                                    "vs:default-amount": 3700
                                                },
                                                "vs:current-default": {
                                                    "vs:date-recorded": "2023-05-17",
                                                    "vs:default-amount": 3700
                                                }
                                            }
                                        },
                                        "vs:repayment-history": {
                                          "vs:payment": [
                                            {
                                              "vs:period":	"2022-10",
                                              "vs:status":	"C",
                                            },
                                            {
                                              "vs:period":	"2022-11",
                                              "vs:status":	0
                                            },
                                            {
                                              "vs:period":	"2022-12",
                                              "vs:status":	1
                                            },
                                            {
                                              "vs:period":	"2023-01",
                                              "vs:status":	2
                                            },
                                            {
                                              "vs:period":	"2023-02",
                                              "vs:status":	3
                                            },
                                            {
                                              "vs:period":	"2023-03",
                                              "vs:status":	0
                                            },
                                            {
                                              "vs:period":	"2023-04",
                                              "vs:status":	"P"
                                            }
                                          ]
                                        }
                                    }
                                ]
                            }
                        },
                        "vs:commercial-credit-file": {
                            "vs:credit-enquiries": {
                                "vs:credit-enquiry": [
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-type": "Search Request",
                                        "vs:enquiry-amount": 0,
                                        "vs:relationship": "Director",
                                        "vs:credit-enquirer": {
                                            "vs:name": "GROW ASSET FINANCE PL",
                                            "vs:classification": "SLND"
                                        }
                                    },
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-type": "Lending Proposal",
                                        "vs:enquiry-amount": 37305,
                                        "vs:relationship": "Principal's Account (sole)",
                                        "vs:credit-enquirer": {
                                            "vs:name": "LATITUDE AUTO FINANCIAL SVCS",
                                            "vs:classification": "RECR"
                                        },
                                        "vs:enquiry-client-reference": "0303058700N"
                                    },
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-type": "Search Request",
                                        "vs:enquiry-amount": 5000,
                                        "vs:relationship": "Director",
                                        "vs:credit-enquirer": {
                                            "vs:name": "TRUEPILLARS PTY LTD",
                                            "vs:classification": "SERV"
                                        }
                                    },
                                    {
                                        "vs:id-references": {
                                            "vs:id-reference": 1
                                        },
                                        "vs:account-type": "Hire Purchase",
                                        "vs:enquiry-amount": 82640,
                                        "vs:relationship": "Principal's Account (sole)",
                                        "vs:credit-enquirer": {
                                            "vs:name": "PEPPER HOME LOANS"
                                        },
                                        "vs:enquiry-client-reference": -20140610008000
                                    }
                                ]
                            }
                        },
                        "vs:public-record-or-consumer-credit-information": {
                          "vs:court-actions": {
                            "vs:court-action": [
                              {
                                "@type": "other-court-action",
                                "vs:id-references": {
                                  "vs:id-reference": "1"
                                },
                                "vs:action-date": "2014-12-02",
                                "vs:creditor": "ELISA FLOYD",
                                "vs:court-action-amount": {
                                  "@currency-code": "AUD",
                                  "#text": "1500"
                                },
                                "vs:plaint-number": "152487454/13/QGLN",
                                "vs:court-type": "District Court",
                                "vs:relationship": {
                                  "@code": "1",
                                  "#text": "Principal's Account (sole)"
                                }
                              },
                              {
                                "@type": "default-judgement",
                                "vs:id-references": {
                                  "vs:id-reference": "1"
                                },
                                "vs:action-date": "2011-12-02",
                                "vs:creditor": "SARAH CLARKSON",
                                "vs:court-action-amount": {
                                  "@currency-code": "AUD",
                                  "#text": "2500"
                                },
                                "vs:plaint-number": "15484511548/12/METN",
                                "vs:court-type": "Local Court",
                                "vs:relationship": {
                                  "@code": "2",
                                  "#text": "Principal's Account (joint)"
                                },
                                "vs:co-borrower": "COM",
                                "vs:court-action-status": {
                                  "@code": "S",
                                  "@date": "2014-10-01",
                                  "#text": "Settled"
                                }
                              }
                            ]
                          },
                          "vs:disqualified-directorships": {
                            "vs:disqualified-directorship": {
                              "vs:id-references": {
                                "vs:id-reference": "1"
                              },
                              "vs:date-disqualified": "2008-02-21",
                              "vs:date-disqualified-until": "2010-02-21"
                            }
                          },
                          "vs:directorships": {
                            "vs:directorship": [
                              {
                                "@type": "C",
                                "vs:id-references": {
                                  "vs:id-reference": "1"
                                },
                                "vs:date-appointed": "1999-01-01",
                                "vs:organisation": {
                                  "vs:bureau-reference": "165968358",
                                  "vs:name": "MELISSA'S SWEAT SHOP",
                                  "vs:type": {
                                    "@code": "P/L",
                                    "#text": "Proprietary Limited"
                                  },
                                  "vs:status": {
                                    "@code": "REGD",
                                    "#text": "Registered"
                                  }
                                }
                              },
                              {
                                "@type": "P",
                                "vs:id-references": {
                                  "vs:id-reference": "1"
                                },
                                "vs:date-appointed": "2005-01-21",
                                "vs:date-ceased": "2007-03-21",
                                "vs:date-last-known-as-director": "2007-03-21",
                                "vs:organisation": {
                                  "vs:bureau-reference": "166368656",
                                  "vs:name": "MULLIGANS FOOD",
                                  "vs:type": {
                                    "@code": "P/L",
                                    "#text": "Proprietary Limited"
                                  },
                                  "vs:organisation-number": "000309786"
                                }
                              }
                            ]
                          },
                          "vs:business-names": {
                            "vs:business-name": {
                              "vs:id-references": {
                                "vs:id-reference": "1"
                              },
                              "vs:start-date": "2001-03-16",
                              "vs:business": {
                                "vs:bureau-reference": "165289307",
                                "vs:name": "MARYS MAX SHOES"
                              }
                            }
                          }
                        }
                    },
                    "vs:summary-data": {
                        "vs:data-block": [
                            {
                                "vs:characteristic": [
                                  {
                                    "vs:variable-name": "CX5000_ac_fhi_12m",
                                    "vs:description": "Existence of Hardship Indicators on one or more accounts last 12 months (Y/N)",
                                    "vs:value": {
                                        "#text": "N",
                                        "@unit": "char"
                                    },
                                    "vs:data-level": {
                                        "#text": "Comprehensive",
                                        "@code": "C"
                                    },
                                    "@id": "CX5000",
                                    "@key-characteristic": "true"
                                  },
                                  {
                                    "vs:variable-name": "CX5010_ac_fhi_0m",
                                    "vs:description": "Existence of Hardship Indicators on one or more accounts current (Y/N)",
                                    "vs:value": {
                                        "#text": "N",
                                        "@unit": "char"
                                    },
                                    "vs:data-level": {
                                        "#text": "Comprehensive",
                                        "@code": "C"
                                    },
                                    "@id": "CX5010",
                                    "@key-characteristic": "false"
                                  },
                                  {
                                    "vs:variable-name": "CX5020_ac_fhi_a_0m",
                                    "vs:description": "Number of accounts currently with temporary arrangement",
                                    "vs:value": {
                                        "#text": 0,
                                        "@unit": "count"
                                    },
                                    "vs:data-level": {
                                        "#text": "Comprehensive",
                                        "@code": "C"
                                    },
                                    "@id": "CX5020",
                                    "@key-characteristic": "false"
                                  },
                                ]
                            },
                            {
                                "vs:characteristic": [
                                  {
                                    "vs:variable-name": "CX0001_ac_op_g_0m",
                                    "vs:description": "Number of open guarantor accounts (excl BT and BF)",
                                    "vs:value": 0,
                                    "vs:data-level": "Partial"
                                },
                                {
                                    "vs:variable-name": "CX0002_rm_ac_op_g_0m",
                                    "vs:description": "Number of open guarantor mortgage accounts",
                                    "vs:value": 0,
                                    "vs:data-level": "Partial"
                                }
                                ]
                            }
                        ]
                    },
                    "vs:score-data": {
                        "vs:score": {
                            "vs:scorecard": {
                                "vs:name": "Commercial Credit Assess - Individual",
                                "vs:version": 2,
                                "vs:type": "Commercial+Consumer",
                                "vs:data-level": "Comprehensive"
                            },
                            "vs:risk-odds": 3.89,
                            "vs:key-contributing-factors": {
                                "vs:contributing-factor": [
                                    {
                                        "vs:name": "Consumer Default Information",
                                        "vs:description": "The existence of consumer default information can be a powerful indicator of risk.",
                                        "vs:score-impactor": "Greatly Increases Risk"
                                    },
                                    {
                                        "vs:name": "Applicant Information",
                                        "vs:description": "Details of an applicant (such as age, employment and address details) can have an impact on risk.",
                                        "vs:score-impactor": "Marginally Decreases Risk"
                                    },
                                    {
                                        "vs:name": "Current Application Information",
                                        "vs:description": "The type and amount of credit being applied for can have an impact on risk.",
                                        "vs:score-impactor": "Marginally Increases Risk"
                                    },
                                    {
                                        "vs:name": "Recent Consumer Credit Application Information",
                                        "vs:description": "Recent credit application activity can have an impact on risk.",
                                        "vs:score-impactor": "Marginally Increases Risk"
                                    }
                                ]
                            },
                            "vs:score-masterscale": 396
                        }
                    },
                    "vs:possible-matches": {
                        "vs:possible-match": {
                            "vs:identity": {
                                "vs:bureau-reference": 217453408,
                                "vs:individual-name": {
                                    "vs:family-name": "HOLMES",
                                    "vs:first-given-name": "SHERLOCK"
                                },
                                "vs:gender": "Female",
                                "vs:date-of-birth": "1983-11-18",
                                "vs:drivers-licence": {
                                    "vs:number": "K695874"
                                }
                            },
                            "vs:addresses": {
                                "vs:address": {
                                    "vs:street-number": 4,
                                    "vs:street-name": "YENNA",
                                    "vs:street-type": "PL",
                                    "vs:suburb": "GLENMORE PARK",
                                    "vs:state": "NSW",
                                    "vs:postcode": 2745,
                                    "vs:country-code": "AUS"
                                }
                            },
                            "vs:employment": {
                                "vs:employer": {
                                    "vs:name": "PEPPER"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  },
  "EquifaxFraudCheck": {
    "metadata": {
        "apiVersion": "1.0.0",
        "documentVersion": "1724735162",
        "status": "SUCCESSFUL",
        "productRequestID": "81fe3275-7b9c-4457-86a6-4cf1cee571e2",
        "correlationId": "cb21d354-e515-44a7-9f84-6798d73c7a35",
        "productCode": "FraudCheckPlus",
        "productName": "FraudCheckPlus",
        "statusDescription": "Applicant information found on the database. See response data for details.",
        "datetimeRequested": "2024-08-27T03:06:01.296+10:00",
        "datetimeGenerated": "2024-08-27T03:06:02.885+10:00",
        "fulfillmentId": "A73C9999"
    },
    "requestHeader": {
        "clientReference": "123-abc12",
        "operatorId": "2026",
        "operatorName": "James Operator",
        "entityName": "John Smith"
    },
    "requestParameters": {
        "applicantChannel": "BROKER",
        "productType": "CF",
        "customerProductName": null,
        "productSKU": "FraudCheckPlus"
    },
    "requestData": {
        "applicant": {
            "individualName": {
                "surname": "Smith",
                "firstName": "John",
                "middleName": "Martin"
            },
            "birthDate": "1984-10-31",
            "addresses": [
                {
                    "addressType": "RESIDENTIAL_CURRENT",
                    "unitNumber": "",
                    "streetNumber": "90",
                    "property": "",
                    "streetName": "Arthur",
                    "streetType": "ST",
                    "suburb": "North Sydney",
                    "state": "NSW",
                    "postcode": "2060",
                    "countryCode": "AU"
                }
            ],
            "phoneNumbers": [
                {
                    "phoneType": "PRIMARY",
                    "isdCountryCode": "+61",
                    "areaCode": "",
                    "phoneNumber": "0427000530"
                }
            ],
            "email": "John.Smith@example.com"
        }
    },
    "responseData": {
        "productData": {
            "fraudScore": -100,
            "caseId": null,
            "applicationAssessment": "HIGH_RISK",
            "applicationMatchedRules": [
                {
                    "ExchangePhoneMatch": "false"
                },
                {
                    "ExchangeEmailMatch": "false"
                },
                {
                    "ExchangeNameDOBMatch": "false"
                },
                {
                    "ExchangeNamePHprimary": "false"
                },
                {
                    "ExchangeNameDOBPostcode": "false"
                },
                {
                    "ExchangeDocumentMatch": "false"
                },
                {
                    "ExchangeAddressMatch": "true"
                },
                {
                    "HotlistAddressMatch": "false"
                },
                {
                    "VelocityPhone": "true"
                },
                {
                    "VelocityEmail": "true"
                },
                {
                    "VelocityNameDOB": "true"
                },
                {
                    "VelocityDocumentId": "false"
                },
                {
                    "VelocityAddress": "true"
                }
            ],
            "fraudCheckReport": {
                "fraudCheckResults": [
                    {
                        "fraudCheckResultSummary": "FRD_EXCH_APP_address_id",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": "2023-05-18T00:00:00.000Z",
                            "applicationDate": "2023-05-18T00:00:00.000Z",
                            "reportingSubscriber": "QA - 003 CREDIT PROVIDER",
                            "matchedClientReferenceId": "e061128e74f04a4db668ca05fbeb4257",
                            "fraudType": "54",
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": "HIGHLY_SUSPICIOUS",
                            "productType": null,
                            "customerProductName": null,
                            "victimFlag": false,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "SURNAMEONE",
                                "firstName": "FRAUDONE"
                            },
                            "birthDate": "1984-08-07",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "streetNumber": "90",
                                    "streetName": "ARTHUR",
                                    "streetType": "ST",
                                    "suburb": "NORTH SYDNEY",
                                    "state": "NSW",
                                    "postcode": "2060",
                                    "countryCode": "AU",
                                    "fullAddress": "90 ARTHUR ST,NORTH SYDNEY,NSW 2060"
                                }
                            ],
                            "documents": [
                                {
                                    "documentType": "DRIVERS_LICENCE",
                                    "documentNumber": "DLS201801",
                                    "countryCode": "AU"
                                }
                            ],
                            "phoneDetails": [],
                            "employment": {
                                "countryCode": "AU"
                            },
                            "email": null
                        }
                    },
                    {
                        "fraudCheckResultSummary": "FRD_EXCH_APP_address_id",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": "2023-05-18T00:00:00.000Z",
                            "applicationDate": "2023-05-18T00:00:00.000Z",
                            "reportingSubscriber": "QA - 003 CREDIT PROVIDER",
                            "matchedClientReferenceId": "99e77cd8e87046d3ba2c1d41fb601fcb",
                            "fraudType": "54",
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": "HIGHLY_SUSPICIOUS",
                            "productType": null,
                            "customerProductName": null,
                            "victimFlag": false,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "ONE",
                                "firstName": "ONE"
                            },
                            "birthDate": "1984-08-07",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "streetNumber": "90",
                                    "streetName": "ARTHUR",
                                    "streetType": "ST",
                                    "suburb": "NORTH SYDNEY",
                                    "state": "NSW",
                                    "postcode": "2060",
                                    "countryCode": "AU",
                                    "fullAddress": "90 ARTHUR ST,NORTH SYDNEY,NSW 2060"
                                }
                            ],
                            "documents": [
                                {
                                    "documentType": "DRIVERS_LICENCE",
                                    "documentNumber": "DLS201801",
                                    "countryCode": "AU"
                                }
                            ],
                            "phoneDetails": [],
                            "employment": {
                                "countryCode": "AU"
                            },
                            "email": null
                        }
                    },
                    {
                        "fraudCheckResultSummary": "CL_OWN_APP_email_address_30D,CL_OWN_APP_address_id_30D,CL_EXCH_APP_email_address_30D,CL_OWN_APP_phone_primary_all_30D,CL_EXCH_APP_address_id_30D,CL_EXCH_APP_full_name_date_of_birth_30D,CL_EXCH_APP_phone_primary_all_30D,CL_OWN_APP_full_name_date_of_birth_30D",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": null,
                            "applicationDate": "2024-08-06T05:32:45.940761863Z",
                            "reportingSubscriber": "Grow Finance Group  Pty Ltd",
                            "matchedClientReferenceId": "123-abc12",
                            "fraudType": null,
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": null,
                            "productType": "CF",
                            "customerProductName": null,
                            "victimFlag": null,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "smith",
                                "firstName": "john",
                                "middleName": "martin"
                            },
                            "birthDate": "1984-10-31",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "unitNumber": "",
                                    "streetNumber": "90",
                                    "property": "",
                                    "streetName": "arthur",
                                    "streetType": "st",
                                    "suburb": "north sydney",
                                    "state": "nsw",
                                    "postcode": "2060",
                                    "countryCode": "au",
                                    "fullAddress": "90 arthur st, north sydney, nsw 2060"
                                }
                            ],
                            "documents": [],
                            "phoneDetails": [
                                {
                                    "phoneType": "PRIMARY",
                                    "isdCountryCode": "+61",
                                    "areaCode": "",
                                    "phoneNumber": "0427000530"
                                }
                            ],
                            "employment": {},
                            "email": "john.smith@example.com"
                        }
                    },
                    {
                        "fraudCheckResultSummary": "CL_OWN_APP_email_address_30D,CL_OWN_APP_address_id_30D,CL_EXCH_APP_email_address_30D,CL_OWN_APP_phone_primary_all_30D,CL_EXCH_APP_address_id_30D,CL_EXCH_APP_full_name_date_of_birth_30D,CL_EXCH_APP_phone_primary_all_30D,CL_OWN_APP_full_name_date_of_birth_30D",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": null,
                            "applicationDate": "2024-08-13T23:34:21.661777967Z",
                            "reportingSubscriber": "Grow Finance Group  Pty Ltd",
                            "matchedClientReferenceId": "123-abc12",
                            "fraudType": null,
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": null,
                            "productType": "CF",
                            "customerProductName": null,
                            "victimFlag": null,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "smith",
                                "firstName": "john",
                                "middleName": "martin"
                            },
                            "birthDate": "1984-10-31",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "unitNumber": "",
                                    "streetNumber": "90",
                                    "property": "",
                                    "streetName": "arthur",
                                    "streetType": "st",
                                    "suburb": "north sydney",
                                    "state": "nsw",
                                    "postcode": "2060",
                                    "countryCode": "au",
                                    "fullAddress": "90 arthur st, north sydney, nsw 2060"
                                }
                            ],
                            "documents": [],
                            "phoneDetails": [
                                {
                                    "phoneType": "PRIMARY",
                                    "isdCountryCode": "+61",
                                    "areaCode": "",
                                    "phoneNumber": "0427000530"
                                }
                            ],
                            "employment": {},
                            "email": "john.smith@example.com"
                        }
                    },
                    {
                        "fraudCheckResultSummary": "CL_OWN_APP_email_address_30D,CL_OWN_APP_address_id_30D,CL_EXCH_APP_email_address_30D,CL_OWN_APP_phone_primary_all_30D,CL_EXCH_APP_address_id_30D,CL_EXCH_APP_full_name_date_of_birth_30D,CL_EXCH_APP_phone_primary_all_30D,CL_OWN_APP_full_name_date_of_birth_30D",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": null,
                            "applicationDate": "2024-08-02T00:54:48.177589579Z",
                            "reportingSubscriber": "Grow Finance Group  Pty Ltd",
                            "matchedClientReferenceId": "123-abc12",
                            "fraudType": null,
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": null,
                            "productType": "CF",
                            "customerProductName": null,
                            "victimFlag": null,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "smith",
                                "firstName": "john",
                                "middleName": "martin"
                            },
                            "birthDate": "1984-10-31",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "unitNumber": "",
                                    "streetNumber": "90",
                                    "property": "",
                                    "streetName": "arthur",
                                    "streetType": "st",
                                    "suburb": "north sydney",
                                    "state": "nsw",
                                    "postcode": "2060",
                                    "countryCode": "au",
                                    "fullAddress": "90 arthur st, north sydney, nsw 2060"
                                }
                            ],
                            "documents": [],
                            "phoneDetails": [
                                {
                                    "phoneType": "PRIMARY",
                                    "isdCountryCode": "+61",
                                    "areaCode": "",
                                    "phoneNumber": "0427000530"
                                }
                            ],
                            "employment": {},
                            "email": "john.smith@example.com"
                        }
                    },
                    {
                        "fraudCheckResultSummary": "CL_OWN_APP_email_address_30D,CL_OWN_APP_address_id_30D,CL_EXCH_APP_email_address_30D,CL_OWN_APP_phone_primary_all_30D,CL_EXCH_APP_address_id_30D,CL_EXCH_APP_full_name_date_of_birth_30D,CL_EXCH_APP_phone_primary_all_30D,CL_OWN_APP_full_name_date_of_birth_30D",
                        "fraudCheckResultDetails": {
                            "fraudOccurredDate": null,
                            "applicationDate": "2024-08-02T00:57:12.972639128Z",
                            "reportingSubscriber": "Grow Finance Group  Pty Ltd",
                            "matchedClientReferenceId": "123-abc12",
                            "fraudType": null,
                            "fraudReasonCode": null,
                            "fraudSubject": "APPLICANT",
                            "matchAssessment": null,
                            "productType": "CF",
                            "customerProductName": null,
                            "victimFlag": null,
                            "lossAmount": null,
                            "savingValue": null,
                            "individualName": {
                                "surname": "smith",
                                "firstName": "john",
                                "middleName": "martin"
                            },
                            "birthDate": "1984-10-31",
                            "addresses": [
                                {
                                    "addressType": "RESIDENTIAL_CURRENT",
                                    "unitNumber": "",
                                    "streetNumber": "90",
                                    "property": "",
                                    "streetName": "arthur",
                                    "streetType": "st",
                                    "suburb": "north sydney",
                                    "state": "nsw",
                                    "postcode": "2060",
                                    "countryCode": "au",
                                    "fullAddress": "90 arthur st, north sydney, nsw 2060"
                                }
                            ],
                            "documents": [],
                            "phoneDetails": [
                                {
                                    "phoneType": "PRIMARY",
                                    "isdCountryCode": "+61",
                                    "areaCode": "",
                                    "phoneNumber": "0427000530"
                                }
                            ],
                            "employment": {},
                            "email": "john.smith@example.com"
                        }
                    }
                ]
            }
        }
    },
    "errors": null
  }
}

export const sampleApplicationObject = {
  "ApplicationId": 7596,
  "BrokerAppId": "G0007596",
  "IP": "14.200.176.154",
  "ApplicantLocation": {
      "lat": "-33.8648322",
      "long": "151.211705"
  },
  "Status": "",
  "CompanyDetails": {
      "recordLastUpdatedDate": "2022-10-19",
      "ABN": {
          "identifierValue": 60629790085,
          "isCurrentIndicator": "Y",
          "replacedFrom": "0001-01-01"
      },
      "entityStatus": {
          "entityStatusCode": "Active",
          "effectiveFrom": "2018-11-05",
          "effectiveTo": "0001-01-01"
      },
      "ASICNumber": 629790085,
      "entityType": {
          "entityTypeCode": "PRV",
          "entityDescription": "Australian Private Company"
      },
      "goodsAndServicesTax": {
          "effectiveFrom": "2018-11-07",
          "effectiveTo": "0001-01-01"
      },
      "mainName": {
          "organisationName": "TSTS PTY LIMITED",
          "effectiveFrom": "2022-10-19"
      },
      "mainBusinessPhysicalAddress": {
          "stateCode": "NSW",
          "postcode": 2570,
          "effectiveFrom": "2018-11-05",
          "effectiveTo": "0001-01-01"
      },
      "organisationName": "TSTS PTY LIMITED",
      "records": [
          {
              "organisationName": "TSTS PTY LIMITED",
              "abn": 60629790085
          }
      ],
      "ABNAgeMonths": 69,
      "GSTAgeMonths": 69
  },
  "AppInfo": {
      "BrokerAppID": "G0007596",
      "IntroducerGroup": "ECLIPXCOMMERCIAL",
      "FinanceType": "Chattel Mortgage",
      "UserEmail": "external@broker.com",
      "UserFirstName": "External",
      "UserLastName": "Broker",
      "BusinessUse": "Yes",
      "SelfServiceability": "Yes",
      "PrivacyConfirmation": "Yes",
      "BrokerSalesforceID": "0018t0000031dE4AAI",
      "BrokerAbn": "80613082698",
      "BrokerEntityName": "GROW FINANCE LIMITED",
      "AssetCategory": "137",
      "AssetType": "1",
      "MetApplicant": "Yes",
      "CustomerId": "0019t00000ItJe0AAF",
      "ContactId": "0039t00000VEw0fAAD",
      "SalesforceId": "0069t00000EstAkAAJ",
      "StageName": "Submitted to Credit"
  },
  "Contacts": {
      "Title": "Dr",
      "GivenName": "ads",
      "SurName": "ad",
      "Email": "ads@asd.asdqwe",
      "Telephone": "78965214",
      "Mobile": "0478965214",
      "AreaCode": "78",
      "isManual": false
  },
  "CommercialEntities": [
    sampleEntityObject
  ],
  "Individuals": [
    sampleIndividualObject
  ],
  "Reference": [],
  "AssetSpec": {
      "make": "Abarth",
      "family": 124,
      "year": 2019,
      "vehicle": "348 Series 1 Spider Roadster 2dr Man 6sp 1.4T",
      "description": "Abarth 124 2019 348 Series 1 Spider Roadster 2dr Man 6sp 1.4T",
      "OtherCar": false,
      "assetPurpose": {
          "type": "replacing-existing-business-asset",
          "name": "Replacing Existing Business Asset"
      },
      "assetPurposeDescription": null,
      "assetExistingFinanceCommitment": {
          "type": "Yes",
          "name": "Yes"
      },
      "makecode": "ABAR",
      "familycode": 124,
      "vehicletype": "PS",
      "month": 0,
      "rbc": "AUVABAR2019AEAA",
      "rbid": 1175503,
      "bodystyle": "Roadster",
      "importflag": "L",
      "avgwhs": 27750,
      "avgretail": 32900,
      "goodwhs": 30650,
      "goodretail": 36300,
      "newprice": 41990,
      "LVR": 27.55,
      "descriptionSf": "Abarth 124 2019 348 Series 1 Spider Roadster 2dr Man 6sp 1.4T"
  },
  "Expense": {
      "CarLoans": 0,
      "CreditCardLimit": 0,
      "Education": 0,
      "Electricity": 0,
      "Groceries": 0,
      "HomeLoans": 0,
      "Insurance": 0,
      "OtherLoans": 0,
      "OtherUtilities": 0,
      "PersonalLoans": 0,
      "ShareofExpense": 0
  },
  "PricingDetails": {
      "LoanTerm": 24,
      "InvoiceAmount": 0,
      "Deposit": 0,
      "Brokerage": "NaN",
      "LoanAmount": 0,
      "PaymentPeriod": "Monthly",
      "DocFee": 495,
      "DocFeeFinanced": "Yes",
      "BrokerOriginationFee": 0,
      "PropertyOwner": "Yes",
      "AdverseOnFile": "No",
      "EquifaxScoreAbove600": "Yes",
      "Rate": 20,
      "BalloonPaymentPercentage": 0,
      "BalloonPayment": 0,
      "Repayment": 0,
      "BankStatementSubmitted": "No",
      "PrivateSale": "No",
      "AssetCondition": "New",
      "AmountFinance": 10495,
      "TransactionType": "Dealer Sale",
      "CreditRateAdjustment": 0,
      "LastUpdatedByUser": null,
      "BrokerageAmount": 0,
      "FacilityEstablishmentFee": 0,
      "FacilityEstablishmentFeePercent": 0,
      "PaymentType": "Advance",
      "SpecialConditions": ""
  },
  "UserId": 3747,
  "QuoteId": null,
  "CompanyId": 2516,
  "CreateTime": "2024-08-27T02:31:11.383Z",
  "Documents": [],
  "ApplicationNotes": null,
  "InteflowStatus": {
      "Id": "0069t00000EstAkAAJ",
      "Attempts_Compliance_Review__c": 0,
      "Credit_Submissions__c": 0,
      "Attempts_documentation__c": 0,
      "Attempts_QA__c": 0,
      "X25_GST_on_brokerage__c": 0,
      "ABN_Number__c": "60629790085",
      "AccountId": "0019t00000ItJe0AAF",
      "ACN_Number__c": "629790085",
      "Address__c": "123 Eagle St Brisbane City QLD 4000",
      "AML_Completed__c": false,
      "SC_AML_Conditions_Satisfied__c": false,
      "Amount": 10000,
      "Amount_150k__c": false,
      "Amount_250k__c": false,
      "Amount_Financed__c": 10495,
      "Amount_Financed_temp__c": 10495,
      "Asset__c": "Cars & Light Commercial",
      "Asset_Category__c": "137",
      "Asset_Type__c": "137-1",
      "Asset_Year__c": "2019",
      "SC_Broker_Accredited__c": true,
      "Broker_Origination_Fee_excl_GST__c": 0,
      "Broker_Origination_Fee__c": 0,
      "Brokerage__c": 0,
      "Brokerage_ex_Origination__c": 0,
      "Brokerage_Percentage__c": 4,
      "Budget_Confirmed__c": false,
      "Calculated_Brokerage__c": 0,
      "Calculated_Doc_Fee__c": 495,
      "Calculated_Repayment__c": 525.39,
      "Calculated_Repayment_Temp__c": 525.39,
      "CloseDate": "2024-10-27",
      "Company_Veda__c": 0,
      "Contract_Documents_correct__c": false,
      "CreatedById": "0057F000007aoYdQAI",
      "SC_Credit_Conditions_Met__c": false,
      "Credit_Approval_Sent__c": false,
      "total_hrs_in_Credit_Approved__c": 0,
      "Credit_Decision_Flag__c": "INPROGRESS",
      "ec1_CreditSubmittedDate__c": "2024-08-27",
      "ec1_Credit_Submitted_DateTime__c": "2024-08-27T02:32:12.000Z",
      "SC_Customers_Bank_Details_Loaded_in_Sal__c": false,
      "Date_Today__c": "2024-08-28",
      "total_hrs_in_Deal_Preparation__c": 0,
      "Deposit_where_applicable__c": 0,
      "Deposit_paid_to__c": "N/A",
      "Direct_Debit_Fee__c": 0,
      "Direct_Debit_Fee_O__c": 0,
      "Discovery_Completed__c": false,
      "Doc_Fee_ex_GST__c": 450,
      "Doc_Fee_Financed__c": true,
      "total_hrs_in_Docs_Out__c": 0,
      "total_hrs_in_Documentation__c": 0,
      "Documentation_Fee__c": 495,
      "DS_Approval_Expiry__c": "2024-12-26",
      "DS_Available_to_Client__c": 9505,
      "DS_BL_Loan_Amount__c": 10495,
      "DS_Monthly_Facility_Fee__c": 16.25,
      "DS_Rate__c": 16.9,
      "DS_Rental_Payment_Total__c": 577.93,
      "DS_Rental_Payments__c": 525.39,
      "DS_Rental_Payments_Plus_10__c": 52.54,
      "DSCR__c": 0,
      "ExpectedRevenue": 6000,
      "FacilityEstablishmentFee__c": 0,
      "Facility_Establishment_Fee__c": 0,
      "ProductType__c": "Chattel Mortgage",
      "Financed_Doc_Fee__c": 495,
      "ForecastCategoryName": "Best Case",
      "ec1_Funder__c": "Grow Asset Finance Pty Ltd",
      "Goods_Description__c": "Abarth 124 2019 348 Series 1 Spider Roadster 2dr Man 6sp 1.4T",
      "Instalment_Amount__c": 525.39,
      "Inteflow_Reference_Id__c": "0069t00000EstAkAAJ",
      "Interest_Payment__c": 2114.36,
      "ec1_Introducer__c": "0018t0000031dE4AAI",
      "BrokerContact__c": "0038t000002Ye92AAC",
      "Broker_Contact_Email__c": "henryd@growfinance.com.au",
      "Introducer_Type__c": "Disclosed",
      "Invoice_Price__c": 0,
      "Is_Multipli__c": false,
      "Is_Rental_Agreement__c": false,
      "LID__Is_Influenced__c": false,
      "LastModifiedById": "0057F000007aoYdQAI",
      "Other_recovery_amounts__c": 0,
      "Loan_Amount__c": 10000,
      "LVR__c": 27.55,
      "Max_Director_Equifax__c": 0,
      "total_hrs_in_More_Information__c": 0,
      "Net_Rate__c": 0,
      "Number_of_Installments__c": 24,
      "Name": "TSTS PTY LIMITED",
      "OwnerId": "0057F000007aoYdQAI",
      "RecordTypeId": "0127F000000yiLjQAI",
      "Payment_Type__c": "Advance",
      "Period__c": 12,
      "PortalLink__c": "https://portal.dynamoney.com/s/application/7596/documents",
      "Portal_User_Email__c": "external@broker.com",
      "Portal_User_First_Name__c": "External",
      "Portal_User_Last_Name__c": "Broker",
      "Pricing_Structure_correct__c": false,
      "PriorStage__c": "Submitted to Credit",
      "IsPrivate": false,
      "Private_Sale__c": "No",
      "Probability": 60,
      "PropertyOwnership__c": "Yes",
      "total_hrs_in_QA__c": 0,
      "SC_QA_Call_Complete__c": false,
      "Rate__c": 20,
      "RCTI_Introducer__c": false,
      "Record_Type_Name_Text__c": "Asset Finance",
      "Is_Referral__c": false,
      "Repayment__c": 0,
      "Repayment_Frequency__c": "Monthly",
      "Residual_Value__c": 0,
      "ROI_Analysis_Completed__c": false,
      "Sale_And_Rent_Back__c": "No",
      "Salesforce_Details_correct__c": false,
      "total_hrs_in_Settlement__c": 0,
      "total_hrs_in_Settlement_Pending__c": 0,
      "Settlements_GracePeriod__c": 0,
      "StageName": "Submitted to Credit",
      "timestamp_Submitted_To_Credit_entered__c": "2024-08-27T02:32:12.000Z",
      "total_hrs_in_Submitted_To_Credit__c": 0,
      "SC_Supplier_Accredited__c": false,
      "SC_Supplier_Deposit_Slip_Received__c": false,
      "ec1_Term__c": 24,
      "Term_Charges_interest__c": 2114.36,
      "Total_Amount_Payable__c": 12609.36,
      "Dialpad__TotalNumberOfTimesDialed__c": 0,
      "Type": "Existing Business",
      "type_name_text__c": "Existing Business",
      "New_or_Used__c": "New",
      "total_hrs_in_Waiting_For_Bank_Statements__c": 0,
      "Weighted_Average__c": 2000
  },
  "AcceptQuotation": true,
  "IsEditLock": false,
  "EditLockByUserId": null,
  "ApplicationType": "AssetFinance",
  "DocumentResult": null,
  "indexSalesForceId": "0069t00000EstAkAAJ",
  "indexAppName": "TSTS PTY LIMITED",
  "Policies": null,
  "AuthorisedSignatory": null,
  "IndividualGivenName": "rr",
  "IndividualSurName": "vv",
  "AppInfoSalesforceID": "0069t00000EstAkAAJ",
  "AppInfoBrokerSalesforceID": "0018t0000031dE4AAI",
  "AppInfoStageName": "Submitted to Credit",
  "CommercialEntityLegalName": "TSTS PTY LIMITED",
  "GrowRiskRatingData": {
      "growRiskRating": 0,
      "abnAge": 0,
      "gstAge": 0,
      "adverse": "",
      "propertyOwnership": "",
      "exceptionToPolicy": "",
      "companyEquifaxScore": 0,
      "lowestIndividualEquifaxScore": 0,
      "highestIndividualEquifaxScore": 0,
      "lossReasonCategory": "",
      "lossReasonDetails": "",
      "creditDecisionFlag": "INPROGRESS",
      "closedLostDatetime": "2024-10-27",
      "additionalSecurity": "",
      "creditorWatchScore": 0
  },
  "DocumentTypes": [],
  "Source": "portal",
  "OppStatus": {
      "Additional_Security__c": ""
  },
  "AdditionalBrokerCorrespondent": null,
  "Background": null,
  "InteflowStatusStageName": "Submitted to Credit",
  "InteflowStatusEc1CreditApprovalDateTime": null,
  "InteflowStatusLossReason": null,
  "InteflowStatusCloseDate": "2024-10-27",
  "PreviousBusiness": null,
  "LvrCalculatorValue": null,
  "DscrCalculatorValue": null,
  "isDeleted": false,
  "DirectSalesUserFirstName": null,
  "DirectSalesUserLastName": null,
  "DirectSalesUserEmail": null,
  "ConsumerDscrCalculatorValue": null,
  "BrokerContact": {
      "FirstName": "Henry",
      "LastName": "Du",
      "AccountId": "0018t0000031dE4AAI",
      "Email": "henryd@growfinance.com.au",
      "MobilePhone": "61452520606",
      "Id": "0038t000002Ye92AAC"
  },
  "SystemCreditStatus": "Declined",
  "CreditOfficerStatusUpdatedBy": null,
  "CreditOfficerStatus": null,
  "entityName": "TSTS PTY LIMITED",
  "CompanyName": "GROW FINANCE LIMITED",
  "BrokerName": "GROW FINANCE LIMITED",
  "BrokerSalesforceId": "0018t0000031dE4AAI",
  "BrokerABN": "80613082698",
  "BrokerACN": "123333333",
  "BankStatements": {
    "id": 28,
    "applicationId": 7545,
    "lastUpdatedTime": "2024-08-13T23:33:12.087Z",
    "updatedBy": 971,
    "industry": {
        "name": "Professional Services",
        "ebitda": [
            {
                "name": "Architectural Services in Australia",
                "percentage": 25
            },
            {
                "name": "Surveying and Mapping Services in Australia",
                "percentage": 25
            },
            {
                "name": "Engineering Consulting in Australia",
                "percentage": 20
            },
            {
                "name": "Legal Services in Australia",
                "percentage": 30
            },
            {
                "name": "Accounting Services in Australia",
                "percentage": 30
            }
        ]
    },
    "industryEbitda": {
        "name": "Architectural Services in Australia",
        "percentage": 25
    },
    "adjustmentPercent": 5,
    "marginAdoptedPercent": 30,
    "monthlySalesAverage3Months": 2650.8,
    "monthlySalesAverage6Months": 2144.64,
    "monthlySalesAverage12Months": 2653.95,
    "averageMonthlySalesAdopted": 1824.89,
    "salesAdjustment": 5000,
    "averageMonthlySalesAdoptedAfterAdjustment": 6824.89,
    "cashAvailableForDebtServiceMonthly": 2047.47,
    "existingDebtCommitmentsMonthly": 163.28,
    "atoPaymentPlanMonthly": 0,
    "additionalCommitments": 0,
    "proposedCommitmentsMonthly": 530.22,
    "totalFinancingCommitmentsMonthly": 693.5,
    "debtServiceCoverRatio": 2.95,
    "selectedAccounts": [
        1,
        2,
        3
    ],
    "loanPaymentTransactions": [
        {
            "date": "2024-06-27",
            "transaction": "Money from Crownbet",
            "debit": 0,
            "credit": 327.9,
            "party": "BetEasy",
            "category": "Gambling",
            "otherInfo": "",
            "bankId": 2,
            "id": 354
        }
    ],
    "incomeTransactions": [
        {
            "date": "2024-06-26",
            "transaction": "Wage from delivery service job",
            "debit": 0,
            "credit": 332.15,
            "party": "Wages",
            "category": "Wages",
            "otherInfo": "",
            "bankId": 1,
            "id": 37
        }
    ],
    "atoPaymentTransactions": [],
    "notes": ""
  }
}