<div class="corporate-loans-credit-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="Credit Decisioning"><div *ngTemplateOutlet="creditDecisioningTab"></div></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #creditDecisioningTab>
  <credit-overview
    [application]="application"
    [runCreditFlowFn]="runCreditFlowFn"
    [getCreditWebServicesFn]="getCreditWebServicesFn"
    [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
    [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
    [updateCreditStatusFn]="updateCreditStatusFn"
    [getCreditAuditLogFn]="getCreditAuditLogFn"
    [getApplicationByIdFn]="getApplicationByIdFn"
    [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
    [deleteWebServicesFn]="deleteWebServicesFn"
    [requestMoreInformationFn]="requestMoreInformationFn"
    [getUserFn]="getUserFn"
    [getApplicationOwnerFn]="getApplicationOwnerFn"
    [apiUrl]="apiUrl"
    (viewReport)="onViewReport($event)"
    (clickApplicationEvent)="onClick($event)"
    (refreshCreditTabEvent)="refreshCreditTab($event)"
  ></credit-overview>
</ng-template>
