import {IndividualWithResult} from '../domain/digital-id-service.domain';
import {AssetSupplier, PpsrAsset, SaveSupplierData} from '../domain/ppsr-service.domain';
import {
  Application, ApplicationSource, ApplicationTypes,
  BrokerOfUser,
  SaveApplicationData,
  SimplifiedApplication, UpdateApplicationData
} from '../domain/application-service.domain';
import {Address2ComponentValue} from '../domain/component/address2-component.domain';
import {parseJSON, stringifyJSON} from '../utils/json';
import {CommercialEntity, Individual, SignerRoleTypes} from '../domain/application-service.domain';
import {
  constants,
  getAssetCategory as getAssetCategoryFromConst,
  getAssetCategoryType as getAssetCategoryTypeFromConst,
  getPrimaryIndustry as getPrimaryIndustryFromConst,
  getSecondaryIndustry as getSecondaryIndustryFromConst
} from '../const';

import { booleanToYesNo } from './utils';
import moment from 'moment';
import {PaymentPeriodType} from "../domain/types.domain";
import { VerimotoInspectionTypeSelection } from '../domain/verimoto.domain';
import { AggregatorSearchComponentValue } from '../domain/component/aggregator-search-component.domain';
import { FacilityEstablishmentType } from '../domain/component/facility-establishment-component.domain';
import * as _ from "lodash";

const STAGE_NAMES_UNDER_REVIEW = ['submitted to credit', 'waiting for bank statements', 'more information'];
const STAGE_NAMES_IN_SETTLEMENT = ['docs out', 'settlement pending', 'qa', 'settlement'];
const STAGE_NAMES_CLOSED_WON = ['closed won'];

export const isApplicationInSettlement = (app: Pick<SimplifiedApplication, 'StageName'>): boolean => {
  return STAGE_NAMES_IN_SETTLEMENT.includes(_.toLower(_.trim(app.StageName ?? '')));
}
export const isApplicationUnderReview = (app: Pick<SimplifiedApplication, 'StageName'>): boolean => {
  return STAGE_NAMES_UNDER_REVIEW.includes(_.toLower(_.trim(app.StageName ?? '')));
}
export const isApplicationClosedWon = (app: Pick<SimplifiedApplication, 'StageName'>): boolean => {
  return STAGE_NAMES_CLOSED_WON.includes(_.toLower(_.trim(app.StageName ?? '')));
}

export const toDraftSimplifiedApplication = (applicationId: number, app: UpdateApplicationData): SimplifiedApplication => {
  const s: SimplifiedApplication = {
    ApplicationId: applicationId,
    Source: app.Source ?? 'portal',
    BrokerAppId: app?.AppInfo?.BrokerAppID ?? '',
    ApplicationType: app.ApplicationType,
    Status: app?.Status ?? 'Draft',
    CreateTime: moment().toISOString(),
    CommercialEntityLegalName: app?.CommercialEntities?.[0]?.LegalName ?? null, //
    IndividualGivenName: app?.Individuals?.[0]?.GivenName ?? null, //
    IndividualSurName: app.Individuals?.[0]?.SurName ?? null, //
    SalesforceId: app?.AppInfo?.SalesforceId ?? null,
    BrokerSalesforceID: app?.AppInfo?.BrokerSalesforceID ?? null,
    StageName: app?.AppInfo?.StageName ?? '',
    CompanyName: app?.CommercialEntities?.[0]?.LegalName ?? '',
    BrokerName: `${app?.BrokerContact?.FirstName ?? ''} ${app?.BrokerContact?.LastName ?? ''}`.trim(),
    PricingDetails: {
      LoanAmount: app.PricingDetails?.LoanAmount ?? 0,
    },
    sfOwnerEmail: app?.BrokerContact?.Email ?? null,
    creditOfficerEmail: app?.BrokerContact?.Email ?? null
  };
  return s;
}

export const isPrimaryApplicantWithABN = (application: Application): boolean => {
  const primary = primaryCommercialEntity(application);
  if (primary) {
    const isTrust = primary?.EntityType === 'TRST';
    const isSLTR = primary?.EntityType === 'SLTR';
    const isPTNR = primary?.EntityType === 'PTNR';
    return (isPTNR || isSLTR || isTrust)
  }
  return false
}

export const isCompanyOrEntityOrg = (application: Application): boolean => {
  const primary = primaryCommercialEntity(application);
  if (primary) {
    const isPty = primary.EntityType === 'PTY';
    const isTrust = primary.EntityType === 'TRST';
    const isSLTR = primary.EntityType === 'SLTR';
    const isPTNR = primary.EntityType === 'PTNR';
    if (application.CommercialEntities.length > 1) {
      for (let i = 0; i < application.CommercialEntities.length; i++) {
        // if (application.CommercialEntities[i].Type === 'Trustee' && application.CommercialEntities[i].EntityType === 'PTY') {
        //   this.hasTrustee = true
        // }
      }
    }
    // if (!isPty && !this.hasTrustee) {
    //   this.showIndividuals = true
    // } else {
    //   this.showIndividuals = false
    // }
    let multiCommercialEntity: boolean = false
    for (let i = 0; i < application.CommercialEntities.length; i++) {
      if (application.CommercialEntities[i].Type === 'Trustee')
        multiCommercialEntity = true
    }
    return (isPty || multiCommercialEntity);
  }
  return false;
}

export const getLoanPurpose = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'BusinessLoans':
      case 'BusinessOverdraft':
        return application.AppInfo.LoanPurpose;
    }
  }
  return null;
}

export const getProductType = (application: Application): string | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'AssetFinance':
        return application.AppInfo.productType;
    }
  }
  return null;
}

export const getSwitchToEasyDoc = (application: Application): boolean | null => {
  if (application) {
    switch (application.ApplicationType) {
      case 'AssetFinance':
        return application.AppInfo.isSwitchToEasyDoc;
    }
  }
  return null;
}

export const getCompanyName = (application: Application) => {
  const commercialEntity =  primaryCommercialEntity(application);
  return commercialEntity?.LegalName ?? '';
}


export const getBrokerContact = (application: Application) => {
  const brokerContact = application.BrokerContact
  if(brokerContact) {
    return   brokerContact?.FirstName +' '+  brokerContact?.LastName
  }
  return '';
}




export const getPreviousBusinessName = (application: Application) => {
  if (application && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') {
    return application.PreviousBusiness?.name ?? '';
  }
  return '';
}

export const getPreviousBusinessABN = (application: Application) => {
  if (application && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') {
    return application.PreviousBusiness?.abn ?? '';
  }
  return '';
}

export const getPreviousBusinessACN = (application: Application) => {
  if (application && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') {
    return application.PreviousBusiness?.acn ?? '';
  }
  return '';
}

export const getPreviousBusiness = (application: Application) => {
  if (application && application.ApplicationType !== 'InsurancePremium' && application.ApplicationType !== 'Consumer') {
    return application.PreviousBusiness;
  }
  return null;
}

export const getApplicationName = (application: Application) => {
  const individual = applicantIndividual(application);
  const givenName = individual?.GivenName;
  const surName = individual?.SurName;
  if (givenName || surName) {
    return `${givenName??''} ${surName??''}`.trim();
  }
  return '';
}


export const primaryCommercialEntity = (application: Application): CommercialEntity | null => {
  if (application && application.CommercialEntities && application.CommercialEntities.length) {
    const _primaryCommercialEntity = (application.CommercialEntities).find((comm: CommercialEntity) => (comm).Type === 'Primary');
    return _primaryCommercialEntity ? _primaryCommercialEntity : null;
  }
  return null;
}

export const applicantIndividual = (application: Application): Individual | null => {
  if (application && application.Individuals && application.Individuals.length) {
    const _applicationIndividual = (application.Individuals).find((ind: Individual) => ind.Role === 'Applicant');
    return _applicationIndividual ?? null;
  }
  return null;
}

export const getApplicantDob = (application: Application): string | null => {
  const individual = applicantIndividual(application);
  if (individual) {
    return individual.DoB;  // YYYY-MM-DD
  }
  return null;
}

export const getApplicationCustomerName = (application: Application) => {
  if (application.ApplicationType === 'Consumer') {
     return getApplicationName(application);
  } else {
    return getCompanyName(application);
  }
}

export const getOrganisationType = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  const entityType =  commercialEntity?.EntityType;
  switch(entityType) {
    case 'TRST':
      return 'Trust';
    case 'SLTR':
      return 'Sole Trader';
    case 'PTNR':
      return 'Partner';
    case 'P/L':
      return 'Company';
    case 'PTY':
      return 'Company';
    default:
      return '';
  }
}

export const getOrganisationTypeForTitle = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  const entityType =  commercialEntity?.EntityType;
  switch(entityType) {
    case 'TRST':
      return 'TRUSTEE';
    case 'SLTR':
      return 'SOLE TRADERS';
    case 'PTNR':
      return 'PARTNERS';
    case 'P/L':
      return 'COMPANY';
    case 'PTY':
      return 'DIRECTORS';
    default:
      return '';
  }
}

export const getBrokerApplicationId = (application: Application) => {
  return application.BrokerAppId;
}


export const getBrokerFlowId = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application?.AppInfo?.BrokerflowDocumentID;
  }
  return '';
}

export const getBrokerSalesforceId = (application: Application) => {
  return application.BrokerSalesforceId;
}

export const getAppSalesforceId =(application: Application) => {
  return application.AppInfoSalesforceID;
}

export const getBrokerName = (application: Application) => {
  return application.BrokerName;
}

export const getAppInfoBrokerEntityName = (application: Application) => {
  return application.AppInfo.BrokerEntityName;
}

export const getOpportunityOwnerEmail = (application: Application) => {
  return application.sfOwnerEmail ?? '';
}

export const getInterestRate = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails.Rate;
  }
  return '';
}

export const getTotalAmount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'InsurancePremium':
      return application.PricingDetails.TotalAmount;
  }
  return '';
}

export const getDocFee = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
    case 'Commercial':
    case 'InsurancePremium':
      return application.PricingDetails.DocFee;
  }
  return '';
}

export const getRepayment = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'Commercial':
    case 'InsurancePremium':
      return application.PricingDetails.Repayment;
  }
  return '';
}

export const getPaymentPeriod = (application: Application): PaymentPeriodType | '' => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'Commercial':
    case 'InsurancePremium':
      return application.PricingDetails.PaymentPeriod ?? '';
  }
  return '';
}

export const getAmountFinanced = (application: Application) => {
  // todo: ?????
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.AmountFinance;
  }
  return '';
}

export const getAbn = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  return commercialEntity?.ABN ?? '';
}

export const getAcn = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  return commercialEntity?.ACN ?? '';
}

export const getFinanceType = (application: Application) => {
  return application?.AppInfo?.FinanceType;
}

export const getApplicationAssetCategory = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory;
      const cat = getAssetCategoryFromConst(catIndex);
      return cat ? cat.value : '';
    }
  }
  return '';
}

export const getAssetTypePPSR = (application: PpsrAsset) => {
  const catIndex = application.SettlementAssetDetails?.assetCategory;
  if (catIndex) {
    const catTypeIndex = application.SettlementAssetDetails?.assetType;
    if (catTypeIndex) {
      const catType = getAssetCategoryTypeFromConst(catIndex, catTypeIndex);
      return catType ? catType.value : '';
    }
  }
  return '';
}

export const getAssetCategoryObj = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory;
      const cat = getAssetCategoryFromConst(catIndex);
      return cat;
    }
  }
  return null;
}

export const getAssetCategoryIndex = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory;
      return catIndex;
    }
  }
  return '';
}

export const getAssetType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory ?? null;
      if (catIndex) {
        const catTypeIndex = application.AppInfo.AssetType;
        if (catTypeIndex) {
          const catType = getAssetCategoryTypeFromConst(catIndex, catTypeIndex);
          return catType ? catType.value : '';
        }
      }
    }
  }
  return '';
}

export const getAssetTypeObj = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory ?? null;
      if (catIndex) {
        const catTypeIndex = application.AppInfo.AssetType;
        if (catTypeIndex) {
          const catType = getAssetCategoryTypeFromConst(catIndex, catTypeIndex);
          return catType;
        }
      }
    }
  }
  return null;
}

export const getAssetTypeIndex = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      const catIndex = application.AppInfo.AssetCategory ?? null;
      if (catIndex) {
        const catTypeIndex = application.AppInfo.AssetType;
        if (catTypeIndex) {
          return catTypeIndex;
        }
      }
    }
  }
  return '';
}

export const getAssetCategoryAndTypeIndex = (application: Application) => {
  const assetCategoryIndex = getAssetCategoryIndex(application);
  const assetTypeIndex = getAssetTypeIndex(application);
  return `${assetCategoryIndex}-${assetTypeIndex}`;
}

export const getAssetSpec = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
    case 'Commercial':
      return application.AssetSpec;
  }
  return null;
}

export const getAssetMake = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.make;
  }
  return '';
}

export const getAssetYear = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.year;
  }
  return '';
}

export const getAssetFamily = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.family;
  }
  return '';
}
export const getAssetPurpose = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
      return application.AssetSpec.assetPurpose?.name;
  }
  return '';
}

export const getAssetPurposeDescription = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
      return application.AssetSpec.assetPurposeDescription;
  }
  return '';
}

export const getAssetExistingFinanceCommitment = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
      return application.AssetSpec.assetExistingFinanceCommitment?.name ?? '';
  }
  return '';
}

export const getAssetLVR = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.LVR;
  }
  return '';
}
export const getAssetVehicle = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.vehicle;
  }
  return '';
}
export const getAssetAvgRetail = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.avgretail;
  }
  return 0;
}
export const getAssetGoodRetail = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.goodretail;
  }
  return 0;
}
export const getAssetNewPrice = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.newPrice;
  }
  return 0;
}
export const getAssetDescription = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.AssetSpec.description;
  }
  return '';
}

export const getSupplierName = (data: PpsrAsset) => {
  return data?.SettlementAssetDetails?.supplier ? (data?.SettlementAssetDetails?.supplier as AssetSupplier)?.SupplierName : `${data?.PrivateSellerDetails?.business ? data?.PrivateSellerDetails?.business.organisationName : data?.PrivateSellerDetails?.name + ' ' + data?.PrivateSellerDetails?.lastName}`
}

export const getAssetCondition = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.PricingDetails.AssetCondition;
  }
  return '';
}

export const getInvoiceAmount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.PricingDetails.InvoiceAmount;
  }
  return 0;
}

export const getLoanTerms = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'InsurancePremium':
      return application.PricingDetails.LoanTerm;

  }
  return 0;
}

export const getRbaRate = (application: Application) => {
  switch (application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.RbaRate;

  }
  return 0;
}


export const getPrivateSales = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.PricingDetails.PrivateSale;
  }
  return '';
}

export const getRateDiscount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'Consumer':
      return application.PricingDetails.RateDiscount;
  }
  return 0;
}

export const getTransactionType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      if (application.PricingDetails.TransactionType) {
        return application.PricingDetails.TransactionType;
      } else {
        if (application.PricingDetails.PrivateSale) {
          return 'Private Sale';
        } else {
          return 'Dealer Sale';
        }
      }

  }
  return '';
}

export const getEquifaxScoreAboveThresold = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.EquifaxScoreAbove600;
  }
  return '';
}

export const getLtv = (application: Application) => {
  switch(application.ApplicationType) {
    case 'CorporateLoans':
      return application.PricingDetails.ltv;
  }
  return undefined;
}

export const getSecurityType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'CorporateLoans':
      return application.PricingDetails.securityType;
  }
  return undefined;
}

export const getAdverseOnFile = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails.AdverseOnFile;
  }
  return '';
}

export const getPropertyOwner = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails.PropertyOwner;
  }
  return '';
}

export const getHybrid = (application: Application) => {
  switch(application.ApplicationType) {
    case 'Consumer':
      return application.PricingDetails.Hybrid ?? "No";
  }
  return '';
}

export const getPreviousLoan = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails.PreviousLoan;
  }
  return '';
}

export const getBrokerage = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'CorporateLoans':
      return Number(application.PricingDetails.Brokerage ? application.PricingDetails.Brokerage : 0);
  }
  return 0;
}

export const getBrokerageAmount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
    case 'InsurancePremium':
      return Number(application.PricingDetails.BrokerageAmount?application.PricingDetails.BrokerageAmount : 0 );
  }
  return 0;
}

export const getMargin = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return Number(application.PricingDetails.Margin ? application.PricingDetails.Margin : 0 );
  }
  return 0;
}

// export const getBrokerOriginationFeeSf = (application: Application) => {
//   switch(application.ApplicationType) {
//     case 'AssetFinance':
//     case 'BusinessLoans':
//     case 'BusinessOverdraft':
//     case 'Consumer':
//       return application.PricingDetails?.BrokerOriginationFeeSf;
//   }
//   return 0;
// }

export const applicationToPaymentType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.PaymentType;
    case 'BusinessLoans':
      return application.PricingDetails.PaymentType;
  }
  return null;
}

export const getCreditRateAdjustment = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails?.CreditRateAdjustment ?? 0;
  }
  return 0;
}

export const getOversCommission = (application: Application) => {
  switch(application.ApplicationType) {
    case 'Consumer':
      return application.PricingDetails?.OversCommission ?? 0;
  }
  return 0;
}

// export const getDocFeeSf = (application: Application) => {
//   switch(application.ApplicationType) {
//     case 'AssetFinance':
//     case 'BusinessLoans':
//     case 'BusinessOverdraft':
//     case 'Consumer':
//       return application.PricingDetails?.DocFeeSf;
//   }
//   return 0;
// }

export const getFacilityEstablishmentFeePercent = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails?.FacilityEstablishmentFeePercent;
  }
  return 0;
}

export const getFacilityEstablishmentFee = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails?.FacilityEstablishmentFee;
  }
  return 0;
}

// export const getFacilityEstablishmentFeePercentSf = (application: Application) => {
//   switch(application.ApplicationType) {
//     case 'BusinessOverdraft':
//       return application.PricingDetails?.FacilityEstablishmentFeePercentSf;
//   }
//   return 0;
// }

// export const getFacilityEstablishmentFeeSf = (application: Application) => {
//   switch(application.ApplicationType) {
//     case 'BusinessOverdraft':
//       return application.PricingDetails?.FacilityEstablishmentFeeSf;
//   }
//   return 0;
// }

// export const getRateSf = (application: Application) => {
//   switch(application.ApplicationType) {
//     case 'AssetFinance':
//     case 'BusinessLoans':
//     case 'BusinessOverdraft':
//     case 'Consumer':
//       return application.PricingDetails?.RateSf;
//   }
//   return 0;
// }

export const getPaymentType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
    case 'Consumer':
      return application.PricingDetails.PaymentType ?? "Advance";
    case 'BusinessLoans':
      return application.PricingDetails.PaymentType ?? "Arrears";
  }
  return "";
}

export const getLVR = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer': {
      return application.AssetSpec.LVR ?? 0;
    }
  }
  return '';
}
export const getDirectorScoreRate = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.DirectorScoreRate ?? '';
  }
  return '';
}

export const getDocFeeFinanced = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.DocFeeFinanced;
  }
  return '';
}

export const getBrokerOriginationFee = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.BrokerOriginationFee;
  }
  return 0;
}

export const getBalloonPayment = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.BalloonPayment;
  }
  return 0;
}

export const getRepaymentType = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'Consumer':
      return application.PricingDetails.RepaymentType;
  }
  return '';
}

export const getApr = (application: Application) => {
  switch(application.ApplicationType) {
    case 'Consumer':
      return application.PricingDetails.APR;
  }
  return 0;
}

export const getBalloonPaymentPercentage = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.BalloonPaymentPercentage;
  }
  return 0;
}


export const getRepaymentFrequency = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      return application.PricingDetails.PaymentPeriod;
  }
  return '';
}

export const getRepaymentFrequencyUnit = (application: Application): string => {
  let repaymentFrequency = null;
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
      repaymentFrequency = application.PricingDetails.PaymentPeriod;
  }
  switch(repaymentFrequency) {
    case 'Weekly':
      return 'Weeks';
    case 'Fortnightly':
      return 'Fortnight';
    case 'Monthly':
      return 'Months';
    default:
      return repaymentFrequency ?? '';
  }
}

export const getDeposit = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'Consumer':
      return application.PricingDetails.Deposit;
  }
  return '';
}

export const getLoanAmount = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'CorporateLoans':
      return application.PricingDetails.LoanAmount;
  }
  return 0;
}

export const getFacilityFee = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.FacilityEstablishmentFee;
  }
  return 0;
}

export const getFacilityFeePercent = (application: Application) => {
  switch(application.ApplicationType) {
    case 'BusinessOverdraft':
    case 'CorporateLoans':
      return application.PricingDetails.FacilityEstablishmentFeePercent;
  }
  return 0;
}

export const getRevenue = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  return commercialEntity?.Revenue ?? '';
}

export const getOperateInCommercialPremises = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  return booleanToYesNo(commercialEntity?.OperateatCommercialAddressFlag) ?? '';
}

export const getLimitRequest = (application: Application) => {
  if (application.ApplicationType === 'TradeFinance' ||
    // application.ApplicationType === 'FloorplanFinance' ||
    application.ApplicationType === 'CorporateLoans') {
    return application.PricingDetails?.LimitRequest;
  }
  return '';
}

export const getApplicationPrimaryIndustry = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  const division =  commercialEntity?.PrimaryIndustry ?? null;
  if (division) {
    const cat = getPrimaryIndustryFromConst(division);
    return cat ? cat.name : '';
  }
  return '';
}

export const getIndustrySector = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  const division =  commercialEntity?.PrimaryIndustry ?? null;
  if (division) {
    const code = commercialEntity?.IndustrySector ?? null;
    if (code) {
      const industrySector = getSecondaryIndustryFromConst(division, code);
      return industrySector ? industrySector.name : '';
    }
  }
  return '';
}

export const getPrimaryBusinessAddress = (application: Application) => {
  const commercialEntity = primaryCommercialEntity(application);
  return commercialEntity?.PrinciplePlaceofBusiness?.UnformattedAddress ?? '';
}

export const getPrimaryBusinessAddressObject = (application: Application): Address2ComponentValue => {
  const commercialEntity = primaryCommercialEntity(application);
  const address = commercialEntity?.PrinciplePlaceofBusiness ?? null;
  if (!address) {
    return null;
  } else {
    return {
      address: address?.UnformattedAddress ?? "",
      StreetNumber: address?.StreetNumber ?? "",
      StreetName: address?.StreetName ?? "",
      StreetType: address?.StreetType ?? "",
      Suburb: address?.Suburb ?? "",
      State: address?.State ?? "",
      UnitNumber: address?.UnitNumber ?? "",
      Postcode: address?.Postcode ?? "",
    }
  }
}

export const getBusinessLandline = (application: Application) => {
  return application.Contacts?.Mobile ?? '';
}

export const getApplicationNotes = (application: Application) => {
  return application.ApplicationNotes ?? '';
}

export const toGrantors = (application: Application) => {
  const ABN = application.CommercialEntities[0].ABN
  const ACN = application.CommercialEntities[0].ACN
  const grantors = [];
  if (ABN !== '' && ABN !== undefined) {
    let gdata: any = {}
    gdata.OrganisationNumber = ABN
    gdata.OrganisationNumberType = 'ABN'
    grantors.push(gdata)
  }
  if (ACN !== '' && ACN !== undefined) {
    let gdata: any = {}
    gdata.OrganisationNumber = ACN
    gdata.OrganisationNumberType = 'ACN'
    grantors.push(gdata)
  }
  for (const individuals of application.Individuals) {
    // const formGroup = abstractControl as UntypedFormGroup;
    grantors.push({
      FamilyName: individuals.GivenName,
      GivenNames: individuals.SurName,
      DateOfBirth: moment(individuals.DoB).format('YYYY-MM-DDT00:00:00'),
    });
  }
  return grantors;
}

export const getExpense = (application: Application) => {
  return {
    carLoans: application.Expense?.CarLoans ?? 0,
    creditCardLimit: application.Expense?.CreditCardLimit ?? 0,
    education: application.Expense?.Education ?? 0,
    electricity: application.Expense?.Electricity ?? 0,
    groceries: application.Expense?.Groceries ?? 0,
    homeLoans: application.Expense?.HomeLoans ?? 0,
    insurance: application.Expense?.Insurance ?? 0,
    otherLoans: application.Expense?.OtherLoans ?? 0,
    otherUtilities: application.Expense?.OtherUtilities ?? 0,
    personalLoans: application.Expense?.PersonalLoans ?? 0,
    shareOfExpense: application.Expense?.ShareofExpense ?? 0,
  }
}

export const getAdditionalSecurity = (application: Application) => {
  return application?.OppStatus?.Additional_Security__c ?? "";
}

export const getSpecialConditions = (application: Application) => {
  switch(application.ApplicationType) {
    case 'AssetFinance':
    case 'BusinessLoans':
    case 'BusinessOverdraft':
    case 'Consumer':
    case 'CorporateLoans':
      return application.PricingDetails?.SpecialConditions ?? "";
  }
  return "";
}

export const getSignersRole = (individual: IndividualWithResult): SignerRoleTypes => {
  if (individual.SignerRole === 'Others' && individual.additionalRoles && individual.additionalRoles?.length) {
    return 'CorporateGuarantor';
  } else {
    return individual.SignerRole;
  }
}

export const stringifyApplicationData = <T extends SaveApplicationData>(data: T): any => {
  const dbData = {...data};
  // dbData.IP = stringifyJSON(data.IP) as any;
  dbData.ApplicantLocation = stringifyJSON(data.ApplicantLocation) as any;
  dbData.AppInfo = stringifyJSON(data.AppInfo) as any;
  dbData.AssetSpec = stringifyJSON(data.AssetSpec) as any;
  dbData.CommercialEntities = stringifyJSON(data.CommercialEntities) as any;
  dbData.Individuals = stringifyJSON(data.Individuals) as any;
  dbData.Reference = stringifyJSON(data.Reference) as any;
  dbData.PricingDetails = stringifyJSON(data.PricingDetails) as any;
  dbData.Contacts = stringifyJSON(data.Contacts) as any;
  dbData.CompanyDetails = stringifyJSON(data.CompanyDetails) as any;
  dbData.Policies = stringifyJSON(data.Policies) as any;
  dbData.AuthorisedSignatory = stringifyJSON(data.AuthorisedSignatory) as any;
  dbData.Expense = stringifyJSON(data.Expense) as any;
  dbData.DocumentTypes = stringifyJSON(data.DocumentTypes) as any;
  return dbData;
}

export const getVerimotoAssetGuid = (assetCategory: string, assetType: string): string | null => {
  switch (assetCategory) {
    case '137':
      return constants.verimotoAssets.find(obj => obj.name === 'Motor Vehicle')?.guid ?? null;
    case '132':
      return constants.verimotoAssets.find(obj => obj.name === 'Lorry/ Truck')?.guid ?? null;
    case '130':
      return constants.verimotoAssets.find(obj => obj.name === 'Plant and Equipment - Road')?.guid ?? null;
    case '140':
      return constants.verimotoAssets.find(obj => obj.name === 'Campervan/ Motorhome')?.guid ?? null;
    case '139':
      if (assetType === '1') {
        return constants.verimotoAssets.find(obj => obj.name === 'Motor Vehicle')?.guid ?? null;
      } else if (assetType === '2') {
        return constants.verimotoAssets.find(obj => obj.name === 'Campervan/ Motorhome')?.guid ?? null;
      } else if (assetType === '3') {
        return constants.verimotoAssets.find(obj => obj.name === 'Motor Vehicle')?.guid ?? null;
      } else {
        return null;
      }
    default:
      return null;
  }
}

export const getInspectionTypeGuid = (application: Application, assetGuid: string, verimotoAssetInspectionTypes: VerimotoInspectionTypeSelection[]): string | null => {
  const transactionType = getTransactionType(application);
  const assetGuidObject = constants.verimotoAssets.find(obj => obj.guid === assetGuid);
  const inspectionTypes = verimotoAssetInspectionTypes.filter(obj => obj.assetName === assetGuidObject?.name);
  if (transactionType === 'Private Sale') {
    return inspectionTypes.find(obj => obj.inspectionType === 'New Lending')?.guid ?? null;
  } else {
    return inspectionTypes.find(obj => obj.inspectionType === 'Personal Loan')?.guid ?? null;
  }
}

export const getFacilityEstablishmentFeeSelection = (maxFacilityEstablishmentFee: number): {
  type: FacilityEstablishmentType,
  name: string;
}[] => {
  const result = [];
  for (let i = 0; i <= maxFacilityEstablishmentFee * 10; i++) {
    const value = (i / 10).toFixed(1); // Generating type as a string with 1 decimal
    const name = `${parseFloat(value).toFixed(2)}%`; // Generating name as a percentage with 2 decimal points
    result.push({ type: value as FacilityEstablishmentType, name });
  }
  return result;
}

// direct sale
export const directSaleBrokerValue = (production: boolean): AggregatorSearchComponentValue => {
  return production ? {
    abn: "80613082698",
    salesforceId: "001RE000003GAJWYA4",
    entityName: "Dynamoney (Direct)",
  } : {
    abn: "80613082698",
    salesforceId: "0018t0000031dE4AAI",
    entityName: "GROW FINANCE LIMITED",
  };
}

export const directSaleBrokerOfUser = (production: boolean): BrokerOfUser => {
  return production ? {
    EntityName: "Dynamoney (Direct)",
    SalesforceId: "001RE000003GAJWYA4",
    Relationship: 'disclosed',
    ABN: "80613082698",
    ACN: "613082698",
  } : {
    EntityName: "GROW FINANCE LIMITED",
    SalesforceId: "0018t0000031dE4AAI",
    Relationship: 'disclosed',
    ABN: "80613082698",
    ACN: "613082698",
  }
}
