import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, } from '../../service/auth.service';
import {
  CreateOverdraftAccountFn,
  CustomerUser,
  GetAccountDetailsFromSfFn,
  GetOpportunitiesForCustomerFn,
  DigitalIdGetApplicationIndividualsFn,
  CreateOverdraftUserFn,
  CreateOverdraftAccountDialogResult,
  getPismoProgramsFn,
  GetPismoProgramDueDate,
  PostOverdraftAccountFeeFn,
  SyncBankDetailsToSfFn,
  PismoCardDetailsWithAccountId,
  PismoChangeCardStatusFn,
  PismoUpdateCustomerFn,
  PismoUpdateAccountFn,
  PismoAccountAndOpportunityResponse,
  PismoUpdateAccountLimitFn,
  GetPismoAccountStatusReasonsFn,
  PismoUpdateAccountStatusFn,
  GetAllPismoAccountStatusFn,
  CreateAccountInterestRateFn,
  CurrencyInputValue,
  UpdateUserKycStatusFn,
  GetCustomerPismoAccountMappingsFn,
  GetPismoCustomerForAccountFn,
  GetPismoCardsForCustomerFn,
  SendForgotPasswordFn,
  GetRbaRateFn,
  PismoRollbackAccountStatusFn,
  PostManualTransactionFn,
  PismoAccountMappingAndApplication,
  GetPismoAccountAndOpportunityDetailsFn,
  ValidEmailCheckFn,
  GetUserByEmailFn,
  UpdatePismoAccountPaymentLimitFn,
  GetPismoTransactionTypeByIdFn,
  GetPismoTransactionFlowFn,
  PismoGetTransactionTypesFn,
  PismoGetCardsForAccountFn,
  GetCustomerFn,
  GetBillerNameFn,
  GetInstitutionNameFn,
  PismoCheckEmailExistsFn,
  PismoGetBuiltInTransactionTypesFn,
  PismoReissueCardFn,
  PismoGetCardReissueReasonsFn,
  PismoUpdateRequireMonthlyFixedInstallmentFlagFn,
  PismoGetPaidFacilityFeeFn,
  PismoGetPendingInterestFn,
  PismoSendPayoutNotificationFn,
  PismoGeneratePayoutFn,
  GetPendingPaymentsFn,
  User,
  GetTokenInfoFn,
  PismoGetAccountInterestRateFn,
  GetRateCardDetailsFn,
  DisableOverdraftUserFn, isInternalUser, isAdminOrOperationsOrAnalyst, OverdraftAccountLimitIncreaseFn, ManualDirectDebitFn,
  EnableOverdraftUserFn,
  UpdateMonthlyFacilityFeeFn,
  GetPismoAccountTimelineFn,
  UpdateAmortisedRepaymentFn,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ApplicationDialogService,
  OverdraftModule,
  createAbnInputMask,
  createAcnInputMask,
  setupUntilDestroy, OverdraftAccountDetailsComponentEvent, getUser,
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subscription, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { createAsyncState, createAsyncStore, loadingFor } from '@ngneat/loadoff';
import { ApplicationService, } from '../../service/application.service';
import { BusinessSearchFn, } from '@portal-workspace/grow-ui-library';
import { navigationUrlForCustomers, navigationUrlForPismoDisplayTransactions } from '../../service/navigation-urls';
import {
  Location,
  NgTemplateOutlet,
  NgStyle,
  AsyncPipe,
  DecimalPipe,
  JsonPipe,
  DatePipe,
} from '@angular/common';
import moment from 'moment';
import { Datepicker2Component } from '@portal-workspace/grow-ui-library';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { SlideToggleComponent } from '@portal-workspace/grow-ui-library';
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { CustomerTypeComponent } from '@portal-workspace/grow-ui-library';
import { MatCardModule } from '@angular/material/card';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { OverdraftCustomerService } from '../../service/overdraft-customer.service';
import { DigitalIdService } from '../../service/digital-id.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NameComponent, DatepickerComponent, EmailComponent, MobileComponent, MessageBoxComponent, } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';

import { LooseCurrencyPipe } from '@portal-workspace/grow-ui-library';
import { RegistrationService } from '../../service/registration.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../../environments/environment';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { isSalesAM } from '@portal-workspace/grow-shared-library';
import { OvedraftCustomerDetailsComponent } from 'libs/grow-ui-library/src/lib/components/overdraft-customer-components/overdraft-customer-details.component';
import { OverdraftAccountUsersComponent } from 'libs/grow-ui-library/src/lib/components/overdraft-customer-components/overdraft-account-users.component';
import { EditableComponent, EditableSaveDirective, EditModeDirective, ViewModeDirective } from "@ngneat/edit-in-place";

export type PismoAccountAndOpporunityResponseWithFormControlPaymentLimit = PismoAccountAndOpportunityResponse & { formControlPaymentLimit: FormControl<CurrencyInputValue> };

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './customer-details.page.html',
  styleUrls: ['./customer-details.page.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    MatDividerModule,
    LooseCurrencyPipe,
    MatTooltipModule,
    MatTableModule,
    DecimalPipe,
    NgTemplateOutlet,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    FormsModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    CustomerTypeComponent,
    NgStyle,
    ExtendedModule,
    MatFormFieldModule,
    MatInputModule,
    InputMaskModule,
    FlexModule,
    MatButtonModule,
    SlideToggleComponent,
    CurrencyInputComponent,
    MatCheckboxModule,
    Datepicker2Component,
    AsyncPipe,
    NameComponent,
    DatepickerComponent,
    EmailComponent,
    MobileComponent,
    JsonPipe,
    MatExpansionModule,
    MatMenuModule,
    MatSortModule,
    DatePipe,
    TagBoxComponent,
    EditableComponent,
    EditableSaveDirective,
    EditModeDirective,
    ViewModeDirective,
    OvedraftCustomerDetailsComponent,
    OverdraftModule
  ]
})
export class CustomerDetailsPage implements OnInit {

  // allowProfileEdit: boolean = false;
  @ViewChild(OverdraftAccountUsersComponent) overdraftAccountUsersComponent!: OverdraftAccountUsersComponent;

  customerId: number | null = null;

  store = createAsyncStore();

  // showAcn : boolean = false
  subscriptions: Subscription[] = [];

  createAcnInputMask = createAcnInputMask();
  createAbnInputMask = createAbnInputMask();
  moment = moment;
  environment = environment;

  businessSearchFn: BusinessSearchFn;
  getOpportunitiesForCustomerFn!: GetOpportunitiesForCustomerFn;
  getPismoProgramsFn!: getPismoProgramsFn;
  getCustomerFn!: GetCustomerFn;
  getRbaRateFn!: GetRbaRateFn;

  disableOverdraftUserFn!: DisableOverdraftUserFn;
  enableOverdraftUserFn!: EnableOverdraftUserFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  getPismoDueDateFn!: GetPismoProgramDueDate;
  createOverdraftAccountFn!: CreateOverdraftAccountFn;
  getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  createOverdraftUserFn!: CreateOverdraftUserFn;
  postOverdraftAccountFeeFn!: PostOverdraftAccountFeeFn;
  syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  createAccountInterestRateFn!: CreateAccountInterestRateFn;
  updateUserKycStatusFn!: UpdateUserKycStatusFn;
  getCustomerPismoAccountMappingsFn!: GetCustomerPismoAccountMappingsFn;
  getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn;
  getPismoCardsForCustomerFn!: GetPismoCardsForCustomerFn;
  sendForgotPasswordFn!: SendForgotPasswordFn;
  getPismoAccountAndOpportunityDetailsFn!: GetPismoAccountAndOpportunityDetailsFn;
  validEmailCheckFn!: ValidEmailCheckFn;
  getUserByEmailFn!: GetUserByEmailFn;
  updatePismoAccountPaymentLimitFn!: UpdatePismoAccountPaymentLimitFn;
  getPismoAccountTimelineFn!: GetPismoAccountTimelineFn;

  errorTitle = 'Error Occurred!'
  errorMessage = 'Please try again.'

  // for overdraft account
  existingPismoAccountMappings: PismoAccountMappingAndApplication[] = [];
  changePismoCardStatusFn!: PismoChangeCardStatusFn;
  // existingPismoCustomerMappings: UserWithPismoMapping[] = [];
  // application: Application | null = null;
  pismoAccountNumbers: number[] = [];
  pismoAccountAndOpportunityDetails: PismoAccountAndOpporunityResponseWithFormControlPaymentLimit[] = [];
  // pismoTransactionDetails!: PismoTransactionsIncludingPending;
  pismoCustomerCardDetails: PismoCardDetailsWithAccountId[] = [];
  updatePismoCustomerFn !: PismoUpdateCustomerFn;
  updatePismoAccountFn !: PismoUpdateAccountFn;
  updatePismoAccountLimitFn !: PismoUpdateAccountLimitFn;
  getPismoAccountStatusReasonsFn !: GetPismoAccountStatusReasonsFn;
  pismoUpdateAccountStatusFn !: PismoUpdateAccountStatusFn;
  pismoRollbackAccountStatusFn !: PismoRollbackAccountStatusFn;
  getAllPismoAccountStatusFn !: GetAllPismoAccountStatusFn;
  // pismoCustomerCardDetails: PismoCardDetailsWithAccountId[] = [];
  // pismoGetTransactionTypes!: PismoGetTransactionTypesResponse
  // pismoGetCardsForAccount!: PismoCardDetails[]
  // pismoGetTransactionTypeById!: GetPismoTransactionTypeByIdFn;
  // pismoPostManualTransaction!: PostManualTransactionFn
  // pismoGetTransactionFlow!: GetPismoTransactionFlowFn;
  // pismoGetTransactionTypes!: PismoGetTransactionTypesResponse
  // pismoGetCardsForAccount!: PismoCardDetails[]
  pismoUpdateCustomerFn!: PismoUpdateCustomerFn;
  getPismoTransactionTypeByIdFn!: GetPismoTransactionTypeByIdFn;
  postManualTransactionFn!: PostManualTransactionFn
  getPismoTransactionFlowFn!: GetPismoTransactionFlowFn;
  getPismoTransactionTypesFn!: PismoGetTransactionTypesFn;
  getPismoCardsForAccountFn!: PismoGetCardsForAccountFn;
  getBillerNameFn: GetBillerNameFn;
  getInstitutionNameFn: GetInstitutionNameFn;
  pismoCheckEmailExistsFn!: PismoCheckEmailExistsFn;
  pismoGetBuiltInTransactionTypesFn!: PismoGetBuiltInTransactionTypesFn;
  pismoGetCardReissueReasonsFn!: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn!: PismoReissueCardFn;
  getTokenInfoFn!: GetTokenInfoFn;
  pismoUpdateRequireMonthlyFixedInstallmentFlagFn!: PismoUpdateRequireMonthlyFixedInstallmentFlagFn;
  pismoGetPaidFacilityFeeFn!: PismoGetPaidFacilityFeeFn;
  pismoGetPendingInterestFn!: PismoGetPendingInterestFn;
  pismoSendPayoutNotificationFn!: PismoSendPayoutNotificationFn;
  pismoGeneratePayoutFn!: PismoGeneratePayoutFn;
  getPendingPaymentsFn!: GetPendingPaymentsFn;
  pismoGetAccountInterestRateFn!: PismoGetAccountInterestRateFn;
  getDefaultRatecardDetailsFn!: GetRateCardDetailsFn;
  overdraftAccountLimitIncreaseFn!: OverdraftAccountLimitIncreaseFn;
  manualDirectDebitFn!: ManualDirectDebitFn;
  updateMonthlyFacilityFeeFn!: UpdateMonthlyFacilityFeeFn;
  updateAmortisedRepaymentFn!: UpdateAmortisedRepaymentFn;

  retry() {
    this.reload();
  }
  loader = loadingFor('loadingProfile', 'loadingPassword', 'loadingMisc', 'savingFloorplan', 'loadingOverdraft', 'paymentLimitUpdated');
  customer: CustomerUser | null = null;
  rbaRate: number | null = null;
  showNewOverdraftButton = false;

  // ========================
  // === details
  // ========================
  // formGroup1: FormGroup<{
  //   customerType: FormControl<CustomerTypeValue>,
  //   entityName: FormControl<string | null>,
  //   entityType: FormControl<string | null>,
  //   abn: FormControl<AbnComponentValue | string>,
  //   acn: FormControl<string | null>,
  //   address: FormControl<string | null>,
  // }>;

  // formControlCustomerType: FormControl<CustomerTypeValue>;
  // formControlEntityName: FormControl<string | null>;
  // formControlEntityType: FormControl<string | null>;
  // formControlAbn: FormControl<AbnComponentValue>;
  // formControlAcn: FormControl<AcnComponentValue>;
  // formControlAddress: FormControl<string | null>;

  // ================================
  // === misc
  // ================================
  formGroup5: FormGroup<{
    notificationNotes: FormControl<string | null>
  }>;
  formControlNotificationNotes: FormControl<string | null>;


  SalesforceId: string | undefined;
  constructor(private activatedRoute: ActivatedRoute,
    private portalHotToast: PortalHotToastService,
    private authService: AuthService,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private overdraftCustomerService: OverdraftCustomerService,
    private digitalIdService: DigitalIdService,
    private registrationService: RegistrationService,
    private _location: Location) {

    this.businessSearchFn = this.applicationService.businessSearchFn;
    this.getCustomerFn = this.authService.getCustomerFn;
    this.getRbaRateFn = this.applicationService.getRbaRateFn;
    this.getOpportunitiesForCustomerFn = this.overdraftCustomerService.getOpportunitiesForCustomerFn;
    this.getPismoProgramsFn = this.overdraftCustomerService.getPismoPrograms;
    this.getPismoDueDateFn = this.overdraftCustomerService.getPismoProgramDueDateFn;
    this.disableOverdraftUserFn = this.overdraftCustomerService.disableOverdraftUserFn;
    this.enableOverdraftUserFn = this.overdraftCustomerService.enableOverdraftUser;
    this.getAccountDetailsFromSfFn = this.overdraftCustomerService.getAccountDetailsFromSfFn;
    this.createOverdraftAccountFn = this.overdraftCustomerService.createOverdraftAccountFn;
    this.getApplicationIndividualsFn = this.digitalIdService.getApplicationIndividualsFn;
    this.createOverdraftUserFn = this.overdraftCustomerService.createOverdraftUserFn;
    this.postOverdraftAccountFeeFn = this.overdraftCustomerService.postOverdraftAccountFeeFn;
    this.syncBankDetailsToSfFn = this.applicationService.syncBankDetailsToSfFn;
    this.changePismoCardStatusFn = this.overdraftCustomerService.pismoChangeCardStatusFn;
    this.updatePismoCustomerFn = this.overdraftCustomerService.updatePismoCustomerFn;
    this.updatePismoAccountFn = this.overdraftCustomerService.updatePismoAccountFn;
    this.updatePismoAccountLimitFn = this.overdraftCustomerService.updatePismoAccountLimitFn;
    this.getPismoAccountStatusReasonsFn = this.overdraftCustomerService.getPismoAccountStatusReasonsFn;
    this.pismoUpdateAccountStatusFn = this.overdraftCustomerService.updatePismoAccountStatusFn;
    this.getAllPismoAccountStatusFn = this.overdraftCustomerService.getAllPismoAccountStatusFn;
    this.pismoUpdateCustomerFn = this.overdraftCustomerService.updatePismoCustomerFn;
    this.createAccountInterestRateFn = this.overdraftCustomerService.createAccountInterestRateFn;
    this.pismoRollbackAccountStatusFn = this.overdraftCustomerService.rollbackPismoAccountStatusFn;
    this.updateUserKycStatusFn = this.overdraftCustomerService.updateUserKycStatusFn;
    this.getCustomerPismoAccountMappingsFn = this.overdraftCustomerService.getCustomerPismoAccountMappingsFn;
    this.getPismoCustomerForAccountFn = this.overdraftCustomerService.getPismoCustomerForAccountFn;
    this.getPismoCardsForCustomerFn = this.overdraftCustomerService.getPismoCardsForCustomerFn;
    this.sendForgotPasswordFn = this.registrationService.sendForgotPassword.bind(this.registrationService);
    this.getPismoAccountAndOpportunityDetailsFn = this.overdraftCustomerService.getPismoAccountAndOpportunityDetailsFn;
    this.validEmailCheckFn = this.overdraftCustomerService.pismoValidEmailCheckFn;
    this.getUserByEmailFn = this.authService.getUserByEmailFn;
    this.updatePismoAccountPaymentLimitFn = this.overdraftCustomerService.updatePismoAccountPaymentLimitFn;
    this.getPismoTransactionTypeByIdFn = this.overdraftCustomerService.getPismoTransactionTypeByIdFn;
    this.postManualTransactionFn = this.overdraftCustomerService.postManualTransactionFn;
    this.getPismoTransactionFlowFn = this.overdraftCustomerService.getPismoTransactionFlowFn;
    this.getPismoCardsForAccountFn = this.overdraftCustomerService.getPismoCardsForAccountFn;
    this.getPismoTransactionTypesFn = this.overdraftCustomerService.getPismoTransactionTypesFn;
    this.getBillerNameFn = this.applicationService.getBillerNameFn;
    this.getInstitutionNameFn = this.applicationService.getInstitutionNameFn;
    this.pismoCheckEmailExistsFn = this.overdraftCustomerService.pismoCheckEmailExists;
    this.pismoGetBuiltInTransactionTypesFn = this.overdraftCustomerService.pismoListBuiltInTransactionTypesFn;
    this.pismoGetCardReissueReasonsFn = this.overdraftCustomerService.pismoGetCardReissueReasonsFn;
    this.pismoReissueCardFn = this.overdraftCustomerService.pismoReissueCardFn;
    this.pismoUpdateRequireMonthlyFixedInstallmentFlagFn = this.overdraftCustomerService.pismoUpdateRequireMonthlyFixedInstallmentFlagFn;
    this.pismoGetPaidFacilityFeeFn = this.overdraftCustomerService.pismoGetPaidFacilityFeeFn;
    this.pismoGetPendingInterestFn = this.overdraftCustomerService.pismoGetPendingInterestFn;
    this.pismoSendPayoutNotificationFn = this.overdraftCustomerService.pismoSendPayoutNotificationFn;
    this.pismoGeneratePayoutFn = this.overdraftCustomerService.pismoGeneratePayoutFn;
    this.getPendingPaymentsFn = this.overdraftCustomerService.getPendingPaymentsFn;
    this.getTokenInfoFn = this.overdraftCustomerService.getTokenInfoFn;
    this.pismoGetAccountInterestRateFn = this.overdraftCustomerService.pismoGetAccountInterestRateFn;
    this.getDefaultRatecardDetailsFn = this.applicationService.getDefaultRatecardDetailsFn;
    this.overdraftAccountLimitIncreaseFn = this.overdraftCustomerService.overdraftAccountLimitIncreaseFn;
    this.manualDirectDebitFn = this.overdraftCustomerService.manualDirectDebitFn;
    this.updateMonthlyFacilityFeeFn = this.overdraftCustomerService.updateMonthlyFacilityFeeFn;
    this.getPismoAccountTimelineFn = this.overdraftCustomerService.getPismoAccountTimelineFn;
    this.updateAmortisedRepaymentFn = this.overdraftCustomerService.updateAmortisedRepaymentFn;
    // this.getPismoAccountDetailsFn = this.overdraftCustomerService.getPismoAccountDetailsFn;

    // ============================
    // === Details: form group #1
    // ============================
    // this.formControlCustomerType = formBuilder.control(null);
    // this.formControlEntityName = formBuilder.control(null);
    // this.formControlEntityType = formBuilder.control(null);
    // this.formControlAbn = formBuilder.control(null);
    // this.formControlAcn = formBuilder.control(null);
    // this.formControlAddress = formBuilder.control(null);
    // this.formGroup1 = formBuilder.group({
    //   customerType: this.formControlCustomerType,
    //   entityName: this.formControlEntityName,
    //   entityType: this.formControlEntityType,
    //   abn: this.formControlAbn,
    //   acn: this.formControlAcn,
    //   address: this.formControlAddress,
    // });

    // ==================================
    // === misc: form group #5
    // ==================================
    this.formControlNotificationNotes = formBuilder.control(null);
    this.formGroup5 = formBuilder.group({
      notificationNotes: this.formControlNotificationNotes,
    });
  }



  // when paymentLimit (edit in place) is entered / saved
  saveOverdraftPaymentLimit(event: Event, pismoAccountAndOpportunityDetail: PismoAccountAndOpporunityResponseWithFormControlPaymentLimit) {
    console.log('**** saveOverdraftPaymentLimit', event, pismoAccountAndOpportunityDetail);
    const newPaymentLimit = pismoAccountAndOpportunityDetail.formControlPaymentLimit.value;
    if (newPaymentLimit) {
      // todo: call API to update account pismo paymentLimit
      const accountId = pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id;
      this.overdraftCustomerService.updatePismoAccountPaymentLimitFn(accountId, newPaymentLimit)
        .pipe(
          this.loader.paymentLimitUpdated.track(),
          this.portalHotToast.snackBarObservable(`Payment limit updated`),
          tap(r => {
            pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.paymentLimit = newPaymentLimit;
          })
        ).subscribe();
    }
    // this.onLoadPismoAccounts(); // NOTE: too aggressive to reload the whole page
  }

  loggedInUser: User | null = getUser();
  isSalesAM = isSalesAM(this.loggedInUser);
  isCurrentUserAdminOrOperationsOrAnalyst = isAdminOrOperationsOrAnalyst(this.loggedInUser);

  ngOnInit() {
    setupUntilDestroy(this);

    // if(this.allowProfileEdit==false){
    //   this.formControlCustomerType.disable()
    //   this.formControlEntityName.disable()
    //   this.formControlEntityType.disable()
    //   this.formControlAbn.disable()
    //   this.formControlAcn.disable()
    //   this.formControlAddress.disable()
    // }
    this.customer = (this.activatedRoute.snapshot.data as any).customerUser;
    this.customerId = this.activatedRoute.snapshot.params.customerId;
    console.log('===================this.customer: ', this.customer);

    if (this.customer) {
      this.reload();
    }
    else {
      // this.showError = true
      this.toastService.publishErrorNotification({
        type: 'error',
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      });
      this.store.value$ = of(createAsyncState({
        error: new Error('missing customer')
      }));
      return;
    }

    // const sub = this.formControlCustomerType.valueChanges.pipe(
    //   tap((r: CustomerTypeValue)  => {
    //     switch(r) {
    //       case 'consumer':
    //         (<FormGroup>this.formGroup1).removeControl('entityType');
    //         (<FormGroup>this.formGroup1).removeControl('entityName');
    //         (<FormGroup>this.formGroup1).removeControl('abn');
    //         (<FormGroup>this.formGroup1).removeControl('acn');
    //         this.formGroup1.updateValueAndValidity();
    //         this.showAcn = false;
    //         break;
    //       case 'commercial':
    //         this.formGroup1.addControl('entityType', this.formControlEntityType);
    //         this.formGroup1.addControl('entityName', this.formControlEntityName);
    //         this.formGroup1.addControl('abn', this.formControlAbn);
    //         this.formGroup1.addControl('acn', this.formControlAcn);
    //         this.formGroup1.updateValueAndValidity();
    //         this.showAcn = true;
    //         break;
    //     }
    //   })
    // ).subscribe();
    // this.subscriptions.push(sub);
  }

  loadOverdraftInfo() {
    if (this.customer) {
      this.subscriptions.push(
        this.getCustomerPismoAccountMappingsFn(this.customer.CustomerId)
          .pipe(
            this.toastService.spinnerObservable(),
            switchMap(mappings => {
              console.log('pismo account mappings=============', mappings);
              this.existingPismoAccountMappings = mappings;
              if (this.existingPismoAccountMappings.length) {
                this.pismoAccountNumbers = (this.existingPismoAccountMappings ?? []).map(m => m.pismoAccountNumber);

                this.onLoadPismoAccounts();
                if (this.overdraftAccountUsersComponent) {
                  this.overdraftAccountUsersComponent.reload();
                }
              } else {
                this.showNewOverdraftButton = true;
              }
              return of(null);
            }),
          ).subscribe()
      )
    }
  }

  reload() {
    this.store = createAsyncStore();
    this.loadOverdraftInfo();
    if (this.customerId) {
      this.subscriptions.push(combineLatest([
        this.authService.getCustomer(this.customerId),
        this.applicationService.getRbaRate(),
      ]).pipe(
        this.store.track(),
        map(([customerPayload, rbaPayload]) => {
          this.customer = customerPayload.payload;
          this.rbaRate = rbaPayload.payload;
          this.update();
        })).subscribe());
    }
    if (!this.customerId) {
      this.toastService.publishErrorNotification({
        type: 'error',
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      });
      this.store.value$ = of(createAsyncState({
        error: new Error(`invalid customer id`),
      }));
    }
  }

  update(): void {
    console.log('*** customer', this.customer);

    if (this.customer) {
      // this.formControlEntityType.setValue(this.customer.EntityType ?? null);
      // this.formControlEntityName.setValue(this.customer.EntityName ?? null);
      // this.formControlAbn.setValue(this.customer.ABN ?? null);
      // this.formControlAcn.setValue(this.customer.ACN ?? null);
      // this.formControlCustomerType.setValue(this.customer.CustomerType ?? null);
      // this.SalesforceId=this.customer.SalesforceId
      // this.formControlAddress.setValue(this.customer.Address?.RawAddress ?? null);
      //this.formControlNotificationNotes.setValue(this.customer.NotificationNotes);
    }
  }

  async cancel($event: Event) {
    await this.router.navigate(navigationUrlForCustomers());
  }
  async onClickBack() {
    this._location.back()
  }

  onCreateOverdraftAccount() {
    this.subscriptions.push(
      this.dialogService.openCreateOverdraftAccountDialog({
        // customer: this.customer,
        customerId: this.customerId!,
        overdraftCustomerSfRecordId: environment.OverdraftCustomerRecordTypeId,
        overdraftSfOpportunityStage: 'Settlement',
        existingPismoAccountMappings: this.existingPismoAccountMappings,
        getOpportunitiesForCustomerFn: this.getOpportunitiesForCustomerFn,
        getPismoProgramsFn: this.getPismoProgramsFn,
        getPismoProgramDueDate: this.getPismoDueDateFn,
        getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
        createOverdraftAccountFn: this.createOverdraftAccountFn,
        syncBankDetailsToSfFn: this.syncBankDetailsToSfFn,
        getRbaRateFn: this.getRbaRateFn,
        getCustomerFn: this.getCustomerFn,
        getBillerNameFn: this.getBillerNameFn,
        getInstitutionNameFn: this.getInstitutionNameFn,
        // getPismoAccountDetailsFn: this.getPismoAccountDetailsFn,
        pismoCheckEmailExistsFn: this.pismoCheckEmailExistsFn,
        getApplicationBySalesforceIdFn: this.applicationService.getApplicationBySalesforceIdFn,
        getApplicationIndividualsFn: this.getApplicationIndividualsFn,
      }).afterClosed().subscribe((result: CreateOverdraftAccountDialogResult | undefined) => {
        if (result) {
          this.loadOverdraftInfo();
          // this.onCreateNewOverdraftUser(); NOTE: loadOverdraftInfo() seems to be already doing this.
        }
      })
    )
  }

  // NOTE: not used??
  // onCreateNewOverdraftUser(pismoAccountId: number) {
  //   const pismoAccountMapping = this.existingPismoAccountMappings.find(acc => acc.pismoAccountNumber);
  //   if (pismoAccountMapping && pismoAccountMapping.application) {
  //     this.subscriptions.push(
  //       this.dialogService.openFirstOverdraftUserDialog({
  //         application: pismoAccountMapping.application,
  //         customer: this.customer as CustomerUser,
  //         validEmailCheckFn: this.authService.validEmailCheckFn,
  //         getUserByEmailFn: this.authService.getUserByEmailFn,
  //         getApplicationIndividualsFn: this.getApplicationIndividualsFn,
  //         createOverdraftUserFn: this.createOverdraftUserFn,
  //         pismoAccountNumbers: this.pismoAccountNumbers
  //       }).afterClosed().subscribe((result: FirstOverdraftUserDialogResult | undefined) => {
  //         if (result) {
  //           this.loadOverdraftInfo();
  //         }
  //       })
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: `Error`,
  //       subMessage: `Pismo account ${pismoAccountId} do not have an application attached`,
  //     });
  //   }
  // }

  onLoadPismoAccounts() {
    const pismoAccountNumbers = this.existingPismoAccountMappings.map(m => m.pismoAccountNumber);
    this.subscriptions.push(
      this.overdraftCustomerService.getPismoAccountAndOpportunityDetailsFn(pismoAccountNumbers)
        .pipe(
          this.loader.loadingOverdraft.track(),
          this.toastService.spinnerObservable(),
        )
        .subscribe(
          (response: PismoAccountAndOpportunityResponse[]) => {
            console.log('=====pismo account details: ', response)
            this.pismoAccountAndOpportunityDetails = (response ?? []).map(pismoAccount => {
              const paymentLimit = pismoAccount.pismoAccountDetails.custom_fields.paymentLimit ?? 0;
              const formControlPaymentLimit = this.formBuilder.control(paymentLimit, [Validators.required]);
              return {
                ...pismoAccount,
                formControlPaymentLimit,
              }
            });

            this.showNewOverdraftButton = !this.pismoAccountAndOpportunityDetails.filter(data => data.pismoAccountDetails.status === 'NORMAL').length;
          })
    )
  }

  // NOTE: not used??
  // onLoadPismoAccountMappings() {
  //   if (this.customer) {
  //     this.subscriptions.push(
  //       this.overdraftCustomerService.getCustomerPismoAccountMappings(this.customer.CustomerId)
  //       .pipe(
  //         this.loader.loadingOverdraft.track(),
  //         // this.toastService.spinnerObservable()
  //       ).subscribe(
  //         mappings => {
  //           console.log('pismo account mappings=============', mappings);
  //           this.existingPismoAccountMappings = mappings;
  //         }
  //       )
  //     )
  //   }
  // }

  onChangeTab(event: MatTabChangeEvent) {
    // NOTE: done during loadOverdraftInfo()
    // if ( event.tab.textLabel === 'OVERDRAFT' && this.pismoAccountNumbers.length) {
    //   this.onLoadPismoAccounts();
    // }
    // if( event.tab.textLabel === 'OVERDRAFT ACCOUNT USERS'){
    //   this.overdraftAccountUsersComponent.reload();
    // }
  }

  // NOTE: not used??
  // async displayPismoAccountTransaction(pismoAccountId: number) {
  //   //display transactions
  //   await this.router.navigateByUrl(navigationUrlForPismoDisplayTransactions(this.customer?.CustomerId!, pismoAccountId));
  // }

  // NOTE: not used??
  // activateOrDeactivatePismoCard(pismoAccountNumber: number, pismoCustomerNumber: number) {
  //   this.subscriptions.push(
  //     this.overdraftCustomerService.getPismoCardsForCustomerFn(pismoAccountNumber, pismoCustomerNumber)
  //       .pipe(
  //         this.toastService.spinnerObservable()
  //       )
  //       .subscribe(
  //         (response: PismoCardDetailsWithAccountId[]) => {
  //           console.log('=====pismo customer card details: ', response)
  //           this.pismoCustomerCardDetails = response;
  //           if (this.pismoCustomerCardDetails.length > 0 ) {
  //             this.dialogService.openPismoActivateOrDeactivateCardDialog({
  //               item: this.pismoCustomerCardDetails,
  //               pismoChangeCardStatus: this.pismoChangeCardStatus,
  //              })
  //               .afterClosed()
  //               .pipe(
  //                 tap(r => {
  //                 })
  //               ).subscribe();
  //           }
  //           else {
  //             this.dialogService.openAlertDialog({
  //               message: 'Error',
  //               subMessage: 'No active cards found',
  //             });
  //           }
  //         }
  //       )
  //   )
  // }

  // NOTE: not used??
  // editPismoCustomer(userData: UserWithPismoMapping) {
  //   this.dialogService.openPismoEditCustomerDialog({
  //     item: userData,
  //     pismoUpdateCustomer: this.pismoUpdateCustomer
  //   })
  //     .afterClosed()
  //     .pipe( tap(r=> {
  //       if(r)
  //         this.reload();
  //     })
  //   ).subscribe()
  // }

  // NOTE: not used??
  // editPismoAccount(accountDetails: PismoAccountAndOpportunityResponse) {
  //   this.dialogService.openPismoEditAccountDialog({
  //     accountDetails: accountDetails.pismoAccountDetails,
  //     pismoUpdateAccountFn: this.pismoUpdateAccountLimit,
  //     pismoGetAllStatusFn: this.pismoGetAllAccountStatus,
  //     pismoGetAccountStatusReasonsFn: this.pismoGetAccountStatusReasons,
  //     pismoUpdateAccountStatusFn: this.pismoUpdateAccountStatus,
  //     pismoRollbackAccountStatusFn: this.pismoRollbackAccountStatus
  //   }).afterClosed()
  //   .pipe( tap(r=> {
  //     if(r)
  //       this.onLoadPismoAccounts();
  //   })).subscribe()
  // }


  // NOTE: not used??
  // postPismoManualTransaction(pismoAccountId: number) {
  //   this.subscriptions.push(
  //     this.dialogService.openPismoPostManualTransactionDialog({
  //       getPismoTransactionTypeByIdFn: this.pismoGetTransactionTypeById,
  //       postManualTransactionFn: this.pismoPostManualTransaction,
  //       pismoAccountId: pismoAccountId,
  //       getPismoTransactionFlowFn: this.pismoGetTransactionFlow,
  //       pismoGetCardsForAccountFn: this.pismoGetCardsForAccountFn,
  //       pismoGetTransactionTypesFn: this.pismoGetTransactionTypesFn,
  //     }).afterClosed().subscribe()
  //   )
  // }

  resetPassword(email: string) {
    this.subscriptions.push(
      this.registrationService.sendForgotPassword({
        Email: email,
      }).pipe(
        this.toastService.spinnerObservable(),
        tap(r => {
          if (r.status) {
            this.dialogService.successDialog({
              message: 'Success',
              subMessage: `An email has been sent to ${email} to reset password.`
            })
          } else {
            this.dialogService.openAlertDialog({
              message: 'Error',
              subMessage: r.message,
            });
          }
        })
      ).subscribe()
    );
  }

  async onPismoAccountDetailsEvent($event: OverdraftAccountDetailsComponentEvent) {
    switch ($event.type) {
      case 'display-transaction': {
        await this.router.navigate(navigationUrlForPismoDisplayTransactions($event.customerId, $event.pismoAccountNumber));
        break;
      }
      case 'account-user-created': {
        if (this.overdraftAccountUsersComponent) {
          this.overdraftAccountUsersComponent.reload();
        }
        break;
      }
      case 'retry-account-fees':
      case 'flag-change': {
        this.loadOverdraftInfo();
        break;
      }
    }
  }

 


  // NOTE: not used??
  // postPismoManualTransaction(pismoAccountId: number) {
  //   this.subscriptions.push(
  //     this.dialogService.openPismoPostManualTransactionDialog({
  //       pismogetTransactionTypeById: this.pismoGetTransactionTypeById,
  //       pismoPostManualTransaction: this.pismoPostManualTransaction,
  //       pismoAccountId: pismoAccountId,
  //       pismoGetTransactionFlow: this.pismoGetTransactionFlow,
  //       pismoGetCardsForAccountFn: this.pismoGetCardsForAccountFn,
  //       pismoGetTransactionTypesFn: this.pismoGetTransactionTypesFn,
  //     }).afterClosed().subscribe()
  //   )
  // }

  // NOTE: not used??
  // editPismoAccount(pismoAccountId: number) {
  //   this.dialogService.openPismoEditAccountDialog({})
  //     .afterClosed()
  //     .pipe(
  //       // todo: after close action
  //     ).subscribe();
  // }

  // NOTE: not used??
  // getPismoAccountMapping(pismoAccountNumber: number) {
  //   return this.existingPismoAccountMappings.find(mapping => mapping.pismoAccountNumber === pismoAccountNumber);
  // }

  // NOTE: not used???
  // retryFacilityEstablishmentFee(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (this.customer && pismoAccountMapping) {
  //     this.subscriptions.push(
  //       this.postOverdraftAccountFeeFn({
  //         fee: 'facility establishment fee',
  //         pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields },
  //         pismoAccountMapping: pismoAccountMapping,
  //         customer: this.customer,
  //       }).pipe(
  //         this.toastService.spinnerObservable(),
  //         tap(r => {
  //           if(r.status) {
  //             this.dialogService.successDialog({
  //               message: 'Success',
  //               subMessage: `Post facility establishment fee success`
  //             }).afterClosed().subscribe(() => {
  //               this.onLoadPismoAccountMappings();
  //             })
  //           } else {
  //             this.dialogService.openAlertDialog({
  //               message: 'Error',
  //               subMessage: r.message,
  //             });
  //           }
  //         })
  //       ).subscribe()
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch customer info or pismo account mapping',
  //     });
  //   }
  // }

  // NOTE: not used??
  // retryBrokerage(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (this.customer && pismoAccountMapping) {
  //     this.subscriptions.push(
  //       this.postOverdraftAccountFeeFn({
  //         fee: 'brokerage',
  //         pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
  //         pismoAccountMapping: pismoAccountMapping,
  //         customer: this.customer,
  //       }).pipe(
  //         this.toastService.spinnerObservable(),
  //         tap(r => {
  //           if(r.status) {
  //             this.dialogService.successDialog({
  //               message: 'Success',
  //               subMessage: `Post brokerage success`
  //             }).afterClosed().subscribe(() => {
  //               this.onLoadPismoAccountMappings();
  //             })
  //           } else {
  //             this.dialogService.openAlertDialog({
  //               message: 'Error',
  //               subMessage: r.message,
  //             });
  //           }
  //         })
  //       ).subscribe()
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch customer info or pismo account mapping',
  //     });
  //   }
  // }

  // NOTE: not used??
  // retryDocumentationFee(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (this.customer && pismoAccountMapping) {
  //     this.subscriptions.push(
  //       this.postOverdraftAccountFeeFn({
  //         fee: 'documentation fee',
  //         pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
  //         pismoAccountMapping: pismoAccountMapping,
  //         customer: this.customer,
  //       }).pipe(
  //         this.toastService.spinnerObservable(),
  //         tap(r => {
  //           if(r.status) {
  //             this.dialogService.successDialog({
  //               message: 'Success',
  //               subMessage: `Post documentation fee success`
  //             }).afterClosed().subscribe(() => {
  //               this.onLoadPismoAccountMappings();
  //             })
  //           } else {
  //             this.dialogService.openAlertDialog({
  //               message: 'Error',
  //               subMessage: r.message,
  //             });
  //           }
  //         })
  //       ).subscribe()
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch customer info or pismo account mapping',
  //     });
  //   }
  // }

  // NOTE: not used??
  // retryDocumentationFeeReversal(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (this.customer && pismoAccountMapping) {
  //     this.subscriptions.push(
  //       this.postOverdraftAccountFeeFn({
  //         fee: 'documentation fee reversal',
  //         pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
  //         pismoAccountMapping: pismoAccountMapping,
  //         customer: this.customer,
  //       }).pipe(
  //         this.toastService.spinnerObservable(),
  //         tap(r => {
  //           if(r.status) {
  //             this.dialogService.successDialog({
  //               message: 'Success',
  //               subMessage: `Post documentation fee reversal success`
  //             }).afterClosed().subscribe(() => {
  //               this.onLoadPismoAccountMappings();
  //             })
  //           } else {
  //             this.dialogService.openAlertDialog({
  //               message: 'Error',
  //               subMessage: r.message,
  //             });
  //           }
  //         })
  //       ).subscribe()
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch customer info or pismo account mapping',
  //     });
  //   }
  // }

  // NOTE: not used???
  // retryAccountInterestRate(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (pismoAccountMapping) {
  //     this.subscriptions.push(
  //       this.createAccountInterestRateFn(pismoAccountDetail.account_id)
  //         .pipe(this.toastService.spinnerObservable())
  //         .subscribe(() => {
  //           this.onLoadPismoAccountMappings();
  //         })
  //     )
  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch pismo account mapping',
  //     });
  //   }
  // }

  // NOTE: not used???
  // retryTransferredBalance(pismoAccountDetail: PismoGetAccountResponse) {
  //   const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
  //   if (pismoAccountMapping) {
  //     this.dialogService.openTransferredBalanceDialog({}).afterClosed().subscribe(
  //       (data: TransferredBalanceDialogResult | undefined) => {
  //         if (this.customer && data) {
  //           this.postOverdraftAccountFeeFn({
  //             fee: 'transferred balance',
  //             pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
  //             pismoAccountMapping: pismoAccountMapping,
  //             customer: this.customer,
  //             accountBalanceType: data.balanceType,
  //             accountBalance: data.balanceAmount,
  //           }).pipe(
  //             this.toastService.spinnerObservable(),
  //             tap(r => {
  //               if(r.status) {
  //                 this.dialogService.successDialog({
  //                   message: 'Success',
  //                   subMessage: `Post account balance success`
  //                 }).afterClosed().subscribe(() => {
  //                   this.onLoadPismoAccountMappings();
  //                 })
  //               } else {
  //                 this.dialogService.openAlertDialog({
  //                   message: 'Error',
  //                   subMessage: r.message,
  //                 });
  //               }
  //             })
  //           ).subscribe()
  //         }
  //     })

  //   } else {
  //     this.dialogService.openAlertDialog({
  //       message: 'Error',
  //       subMessage: 'Failed to fetch pismo account mapping',
  //     });
  //   }
  // }

  //   protected readonly getPismoAccountAndOpportunityDetails = getPismoAccountAndOpportunityDetails;
}
